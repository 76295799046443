//same as in PlayerProduct.ts
import fontMapPerProductName from './GenericFontMapByProduct.json'
import { GetSizeName } from './GetSizeName';

export const PlayerTextFontSizeStep:(wizardData:any,editParams:any,playerProductData:any,wizardId:string)=>{name:string, detail:string}|null = (_wizardData:any,_editParams:any,playerProductData:any,wizardId) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/player-text-font-size.js#L596
  var wiz = wizardId;
  switch (wizardId) {
      case "VerticalText":
      case "PreSpacedLetters":
      case "ZippWeedText":
          wiz = "PreSpacedText";
  }
  const fontMap = fontMapPerProductName[wiz as keyof typeof fontMapPerProductName]

  var fontDetail = "Font: " + fontMap[playerProductData.FontCode as keyof typeof fontMap];
  var letterDetail = "";
  var numberDetail = "";
  var maxLengthDetail = "";

    if (playerProductData.FontLetterSize) {
        letterDetail = "Letter: " + GetSizeName(playerProductData.FontLetterSize) + "<br />";
    }

    if (playerProductData.FontNumberSize) {
        numberDetail = "Number: " + GetSizeName(playerProductData.FontNumberSize) + "<br />";
    }

  if (!!playerProductData.MaximumLength || wizardId == "PreSpacedNumbers" || wizardId == "SimStitchPSNumbers") {
      if (playerProductData.MaximumLength) {
          maxLengthDetail = "Maximum Length: " + playerProductData.MaximumLength + "<br />";
      }
  }

  return {
    "name": "Font & Size",
    "detail": letterDetail + numberDetail + maxLengthDetail + fontDetail
  };
}