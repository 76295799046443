export const StyleStep:(data:any)=>{name:string, detail:string}|null = (data:any) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/style.js#L133
  //we will try to simplify things
  if (data.StyleValue) {
      var bg_text = "";
      switch (data.Layer) {
          case "foreground":
              bg_text = "Foreground";
              break;
          case "background":
              bg_text = "Background";
              break;
          case "foreground_and_background":
              bg_text = "Foreground & Background";
              break;
          case "background1":
              bg_text = "Background";
              break;
          case "background2":
              bg_text = "3<sup>rd</sup> Color Background";
              break;
          case "2layer":
              bg_text = "2-Layer";
              break;
          case "3layer":
              bg_text = "3-Layer";
              break;
          default:
              return null;
      }

      return { "name": "Style", "detail": data.StyleValue + "<br>" + bg_text };
  }
  return null
}
