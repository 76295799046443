import React, { useState, useEffect } from 'react';

interface CircularProgressBarProps {
  progress: number; // Progress value between 0 and 100
  size?: string; // Optional size of the circle
  strokeWidth?: number; // Optional stroke width of the circle
  circleColor?: string; // Optional color of the unfilled circle
  progressColor?: string; // Optional color of the progress circle
}

function ArrowDown() {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      width="20px"
      strokeWidth={2}
      stroke="#fff">
      <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
    </svg>
  )
}

function Check() {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      width="20px"
      strokeWidth={2}
      stroke='#F36E29'>
      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
    </svg>
  )
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  progress,
  size = '40',
  strokeWidth = 3.5,
  circleColor = '#fff',
  progressColor = '#F36E29'
}) => {
  const [showCheck, setShowCheck] = useState(false);
  const radius = parseInt(size) / 2 - strokeWidth;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  useEffect(() => {
    if (progress === 100) {
      setShowCheck(true);
      const timer = setTimeout(() => {
        setShowCheck(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [progress]);

  return (
    <div style={{ width: size, height: size, position: 'relative' }}>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        style={{ transform: 'rotate(-90deg)' }}
      >
        <circle
          cx={parseInt(size) / 2}
          cy={parseInt(size) / 2}
          r={radius}
          fill="none"
          stroke={circleColor}
          strokeWidth={strokeWidth}
        />
        <circle
          cx={parseInt(size) / 2}
          cy={parseInt(size) / 2}
          r={radius}
          fill="none"
          stroke={progressColor}
          strokeWidth={strokeWidth + 0.5}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
      </svg>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        {showCheck ? <Check /> : <ArrowDown />}
      </div>
    </div>
  );
};

export default CircularProgressBar;