import React from 'react'
import { DEFAULT_IMAGE } from '../../../config'
import { generateImageSrcFromArtwork } from '../../../utils/general'
import { ArtworkItemType } from '../../../types/api-types'
import { getImageObject } from '../../../utils/general'
// Styles
import styles from './../art-details.module.css'
import { UcmLineItem } from '../../ucm-production/ucm-line-item'

interface Props {
  lineItems: UcmLineItem[]
  docNo: string
  lineNo: string
  onSelectItem: (item: UcmLineItem) => void
  onPrintQuantityChnage: (item: UcmLineItem, quantity: number) => void
}

function SelectedIcon({ checked }: { checked: boolean }) {
  return (
    <svg
      height="35px"
      width="35px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{ fill: checked ? "#F36E29" : "", opacity: checked ? 1 : 0.75 }}
      className="">
      <path
        strokeWidth="4"
        fillRule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
    </svg>
  );
}

const Item = ({ isSelected }: { isSelected: boolean }) => {
  return (
    <div className={styles.selectIcon}>
      <SelectedIcon checked={isSelected} />
    </div>
  );
}

const UCMaxItems: React.FC<Props> = ({ lineItems, onSelectItem, onPrintQuantityChnage }) => {
  const [imageLoaded, setImageLoaded] = React.useState<Record<string, boolean>>({});

  const handleItemClick = (item: UcmLineItem) => {
    onSelectItem(item);
  }

  return (
    <>
      <div className={styles.gridContainer}>
        {lineItems.map((item, index) => {
          const imageURL = getImageObject(item, DEFAULT_IMAGE);
          const imgSrc = Array.isArray(imageURL) ? imageURL[0] : DEFAULT_IMAGE;
          const isSelected = item.selected;

          return (
            <div key={index} className={styles.gridItem}>
              <div
                style={{
                  width: (imageLoaded[item.docId] || imgSrc === DEFAULT_IMAGE) ? "auto" : "260px",
                }}
                className={
                  [styles.thumb,
                  (imageLoaded[item.docId] || imgSrc === DEFAULT_IMAGE) ? styles.thumbUcMaxBgd : styles.skeletonImg,
                  isSelected ? styles.reduceImgWidth : ""
                  ].join(' ')}>
                <img
                  src={generateImageSrcFromArtwork(imgSrc as ArtworkItemType, DEFAULT_IMAGE)}
                  alt={`productImage_${index}`}
                  onLoad={() => setImageLoaded(currentImageLoaded => ({ ...currentImageLoaded, [item.docId]: true }))}
                />
                <div className={styles.selectIcon} onClick={() => handleItemClick(item)}>
                  {imageLoaded[item.docId] &&
                    <Item
                      isSelected={isSelected}
                    />
                  }
                </div>
              </div>
              <div className={styles.flex1}>
                <div className={styles.itemDetails}>
                  <div className={styles.docIdRow}>
                    <span><b>Design {item.docId}</b></span>
                    <span>:</span>
                    {
                      //asierad: for the time being ignore processed flag
                    }
                    <span>{lineItems[index].productionArtwork?.some(x => x.DTFProcessed && false) ? "processed" : "unprocessed"}</span>
                  </div>
                  <div className={styles.quantityWrapper}>
                    <span><b>Quantity:</b></span>
                    <span className={styles.nonPrintable}>
                      <input
                        type="text"
                        value={item.printQuantity}
                        size={4}
                        onChange={(e) => onPrintQuantityChnage(item, parseInt(e.target.value))} /> / </span>
                    <span>{item.quantity}</span>
                  </div>
                  <div className={styles.sizeWrapper}>
                    <span><b>Size:</b></span>
                    <span>{item.size?.width}w x {item.size?.height}h
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  );
};

export default UCMaxItems;