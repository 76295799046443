export const CmeTails:(wizardData:any)=>{name:string, detail:string} = (wizardData:any) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cme/tails.js#L40

  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1099
  var details:string[] = [];
  var tail = wizardData.tail == "yes";

  if (tail) {
      details.push("Tail: " + wizardData.tailname);
      switch (wizardData.tailtype) {
          case "Plain":
              break;
          case "NameDrop":
              details.push("Name Drop Available")
              break;
          case "CutWord":
              details.push("Cut In Word: " + wizardData.tailcutinword);
              break;
      }
  }
  else details.push("No");
  return {'name': "Tail options", 'detail': details.join("<br />")}

}
