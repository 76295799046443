import { useEffect, useState } from 'react';
import { DeleteIcon, EditIcon } from './imageSelector';
import { getBackendApiUrl } from "../../../config";
import Spinner from './spinner';
import CommentBox from '../../../components/commentBox/commentBox';
import { addAuthorizationHeader } from '../../../api/network';
import { DesignDoc, WizardDocOneTimeFee } from '../../../types/api-types';
import { InternalArtStates } from '../../../types/art-status';
import { splitCamelCase } from '../../../components/cardDetails/cardValues';
import { getDesignFees } from '../../../api/backend';

// Styles
import styles from './oneTimeCharges.module.css';

// Defining the prop types
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  document?: DesignDoc;
  userId: string;
  onOneTimeChargeSuccess: (comment: string) => void;
  isCustomerView: boolean;
}

const OneTimeChargeModal: React.FC<ModalProps> = ({ isOpen, onClose, document, userId, onOneTimeChargeSuccess, isCustomerView }) => {
  const documentId = document?.id;
  const isApproved = document?.statusID == InternalArtStates.APPROVED;
  const [processing, setProcessing] = useState<boolean>(false);
  const [oneTimeFees, setOneTimeFees] = useState<WizardDocOneTimeFee[]>([]);
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<number | ''>('');
  const [editId, setEditId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [comment, setComment] = useState<string>('');
  const [wasPurchased, setPurchased] = useState<boolean>(false);
  const [feeType, setFeeType] = useState<string>("");

  useEffect(() => {
    handleFetchFees();
  }, [documentId]);

  const headers = addAuthorizationHeader({
    'Content-Type': 'application/json'
  });

  const handleCommentChange = (value: string) => {
    setComment(value);
  }

  // Design Art Charge request - GET
  const handleFetchFees = async () => {
    if (documentId) {
      setProcessing(true);
      try {
        const fees = await getDesignFees(documentId);
        setOneTimeFees(fees);
      } catch (err) {
        setError("Failed to fetch Art Charges. Please try again.");
      } finally {
        setProcessing(false);
      }
    }
  };

  // Design Purchases request - GET
  useEffect(() => {
    if (userId && documentId) {
      setProcessing(true);
      fetch(`${getBackendApiUrl()}/design-purchases/${documentId}`, {
        method: 'GET',
        headers
      }).then(async (purchasesResp) => {
        if (!purchasesResp.ok) throw new Error("Error getting purchase data " + await purchasesResp.text())
        const purchasesRespJson: { purchased: boolean } = await purchasesResp.json()
        setPurchased(purchasesRespJson.purchased);
        setProcessing(false);
      }).catch(err => {
        console.log("Failed to fetch Design Purchases " + err);
        setError("Failed to fetch Design Purchases. Please try again.");
        setProcessing(false);
      });
    }
  }, [])

  // Design Purchases - POST (PURCHASED)
  const setAsPurchased = () => {
    setProcessing(true);
    const timeStamp = new Date().toISOString();
    fetch(`${getBackendApiUrl()}/design-purchases`, {
      method: 'POST',
      headers: addAuthorizationHeader({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        designId: documentId,
        timeStamp: timeStamp,
      }),
    }).then(async data => {
      if (!data.ok)
        throw new Error("" + await data.text());
      else
        setPurchased(true);

      // Get fees
      handleFetchFees();
      setProcessing(false);
    })
      .catch(err => {
        console.log("Failed to set design as purchased " + (err as Error).message);
        setError("Failed to set design as purchased. Please try again.");
        setProcessing(false);
      });
  };

  // Design Purchases - DELETE (NOT PURCHASED)
  const setAsNotPurchased = () => {
    setProcessing(true);
    fetch(`${getBackendApiUrl()}/design-purchases/${documentId}`, {
      method: 'DELETE',
      headers: addAuthorizationHeader({})
    })
      .then(async data => {
        if (!data.ok)
          throw new Error("" + await data.text());
        else
          setPurchased(false);

        setProcessing(false);
      })
      .catch(err => {
        console.log("Failed to set design as not purchased " + (err as Error).message);
        setError("Failed to set design as not purchased. Please try again.");
        setProcessing(false);
      });
  };

  // Design Art Charge - DELETE
  const deleteOneTimeFee = (id: number) => {
    setProcessing(true);
    fetch(`${getBackendApiUrl()}/DesignOneTimeFees/${id}`, {
      method: 'DELETE',
      headers: addAuthorizationHeader({})
    }).then(async data => {
      if (!data.ok) throw new Error("" + await data.text());
      setOneTimeFees(prevFees => prevFees.filter(fee => fee.id !== id));
      setProcessing(false);

      if (comment) {
        onOneTimeChargeSuccess(comment);
        setComment('');
      }
    })
      .catch(err => {
        console.log("Failed to delete Art Charge " + (err as Error).message);
        setError("Failed to delete Art Charge. Please try again.");
        setProcessing(false);
      });
  };

  // Design Art Charge - Create or update (POST - PUT)
  const saveOneTimeFee = (e: React.FormEvent) => {
    e.preventDefault();
    setProcessing(true);
    setError(null);

    const feeItem = oneTimeFees?.find(f => f.id === editId);

    const payload = {
      docId: documentId as string,
      description,
      price: Number(price),
      feeType,
      paid: feeItem?.paid
    };

    const url = editId === null
      ? `${getBackendApiUrl()}/DesignOneTimeFee`
      : `${getBackendApiUrl()}/DesignOneTimeFees/${editId}`;

    const method = editId === null ? 'POST' : 'PUT';
    designOneTimeFeeFetch(url, method, payload, editId);
  };

  const designOneTimeFeeFetch = (url: string, method: string, payload: any, editId: number | null) => {
    fetch(url, {
      method,
      headers,
      body: JSON.stringify(payload)
    })
      .then(async data => {
        const jsonData = await data.json().catch(() => null);
        if (!data.ok) {
          return Promise.reject(jsonData || 'Failed to fetch');
        }
        return jsonData;
      })
      .then(jsonData => {
        if (editId === null) {
          setOneTimeFees([...oneTimeFees, { ...jsonData, ...payload }]);
        } else {
          setOneTimeFees(oneTimeFees.map(fee => fee.id === editId ? { ...jsonData, ...payload, id: editId } : fee));
        }

        //Reset values
        resetForm();

        if (comment) {
          onOneTimeChargeSuccess(comment);
          setComment('');
        }
      })
      .catch(err => {
        console.error("Failed to save Art Charge", err);
        setError(editId === null ? "Failed to add Art Charge. Please try again." : "Failed to update Art Charge. Please try again.");
        setProcessing(false);
      });
  }

  const startEdit = (fee: WizardDocOneTimeFee) => {
    setDescription(fee.description);
    setPrice(fee.price);
    setEditId(fee.id);
    setFeeType(fee.feeType);
  };

  const handleInputChange = (field: string, value: string | number) => {
    if (field === 'description') setDescription(value as string);
    if (field === 'price') setPrice(value === '' ? '' : Number(value));
    if (value === '' && field === 'description' && !price) setEditId(null);
    if (value === '' && field === 'price' && !description) setEditId(null);
  };

  const resetForm = () => {
    setDescription('');
    setPrice('');
    setFeeType('');
    setEditId(null);
    setError(null);
    setProcessing(false);
  };

  function CloseIcon() {
    return (
      <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span className="sr-only">Close menu</span>
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    );
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked)
      setAsPurchased();
    else
      setAsNotPurchased();
  };

  // Design Art Charg - PAID
  const handleTogglePaid = (event: React.ChangeEvent<HTMLInputElement>, fee: WizardDocOneTimeFee) => {
    event.preventDefault();
    setProcessing(true);
    setError(null);

    const payload = {
      docId: documentId as string,
      description: fee.description,
      price: Number(fee.price),
      feeType: fee.feeType,
      paid: event.target.checked
    };

    const url = `${getBackendApiUrl()}/DesignOneTimeFees/${fee.id}`;
    designOneTimeFeeFetch(url, 'PUT', payload, fee.id);
  };

  const isFormValid = description.trim() !== '' && price !== '' && feeType !== '';

  return isOpen ? (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          <div className={styles.header}>
            <span>Art Charges</span>
            <div className={styles.closeIcon} onClick={() => { resetForm(); onClose(); }}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.modalContent}>
            {error && <div className={styles.errorMessage}>{error}</div>}
            <div>
              <label className={styles.otCheckbox}>
                <input
                  type="checkbox"
                  checked={wasPurchased}
                  onChange={handleCheckboxChange}
                />
                <span className={styles.checkmark}></span>
                Design Is Purchased
              </label>
            </div>
            {(oneTimeFees && oneTimeFees.length > 0) &&
              <div className={styles.listContainer}>
                <div className={styles.otHeader}>
                  <span>Paid</span>
                  <span>Description</span>
                  <span>Type</span>
                </div>
                {oneTimeFees.map(fee => (
                  <div key={fee.id} className={styles.chargeItem}>
                    <label className={styles.otCheckbox}>
                      <input
                        type="checkbox"
                        checked={fee?.paid}
                        onChange={(e) => handleTogglePaid(e, fee)}
                      />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span className={styles.itemDescription}>
                      <b>{fee?.description}</b> ${fee.price?.toFixed(2)}
                    </span>
                    <span>{splitCamelCase(fee?.feeType)}</span>
                    <div className={styles.actionBtn}>
                      <button onClick={() => startEdit(fee)}><EditIcon /></button>
                      <button onClick={() => deleteOneTimeFee(fee.id)}><DeleteIcon /></button>
                    </div>
                  </div>
                ))}
              </div>
            }
            <form onSubmit={saveOneTimeFee}>
              <div className={styles.inputsContainer}>
                <div className={styles.inputWrapper}>
                  <label htmlFor="description">Description:</label>
                  <input type='text'
                    value={description}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    required />
                </div>
                <div className={styles.inputWrapper}>
                  <label htmlFor="price">Price:</label>
                  <input type='number'
                    value={price}
                    onChange={(e) => handleInputChange('price', e.target.value === '' ? '' : Number(e.target.value))}
                    required />
                </div>
                <div className={styles.inputWrapper}>
                  <label htmlFor="feeType">Fee Type:</label>
                  <select
                    className={styles.feeTypeSelector}
                    value={feeType}
                    onChange={(e) => setFeeType(e.target.value as "ArtFee" | "ProductFee")}>
                    <option value={""}>
                      Select Fee Type
                    </option>
                    <option value={"ProductFee"}>
                      Product Fee
                    </option>
                    <option value={"ArtFee"}>
                      Art Fee
                    </option>
                  </select>
                </div>
              </div>

              {/* Comment Section */}
              {!isApproved &&
                <CommentBox
                  onCommentChange={handleCommentChange}
                  isCustomerView={isCustomerView}
                  showTitle={true} />
              }
              <div className={styles.confirmBtns}>
                <button
                  type="submit"
                  className={[styles.addButton, (processing || !isFormValid) ? styles.disabledBtn : ''].join(' ')}
                  disabled={processing || !isFormValid}
                >
                  {processing ? <Spinner spinning={processing} color="#F36E29" size={{ width: "22px", height: "22px" }} /> : (editId !== null ? 'Update Charge' : 'Add Charge')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default OneTimeChargeModal;