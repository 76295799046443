import React, { useRef } from 'react';

// Styles
import styles from './imagePreview.module.css';

interface ImagePreviewProps {
  isOpen: boolean;
  imageSrc: string;
  onClose: () => void;
}

function CloseIcon() {
  return (
    <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
      <span className="sr-only">Close menu</span>
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  );
}

function PrintIcon() {
  return (
    <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-500 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
      <span className="sr-only">Print Image</span>
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.75"
          d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
      </svg>
    </button>
  );
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ isOpen, imageSrc, onClose }) => {
  const imageRef = useRef<HTMLImageElement>(null);

  const handlePrint = () => {
    if (!imageRef.current) return;

    const printWindow = window.open('', '_blank');
    if (printWindow) {
      const imageHtml = `<img src="${imageSrc}" 
          style="
          max-width: 100%; 
          max-height: 100vh; 
          object-fit: contain;"
          onload="window.print(); window.close();" />`;

      printWindow.document.write(`
        <html>
          <head>
            <title>Print Image</title>
            <style>
              @page { 
                size: landscape;
                margin: 0; 
              }
              body {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                background-position: 0 0, 8px 8px;
                background-color: rgba(175, 175, 175, 0.65) !important;
                background-image: linear-gradient(45deg, rgba(113, 113, 113, 0.5) 25%, transparent 25%, transparent 75%, rgba(113, 113, 113, 0.5) 75%, rgba(113, 113, 113, 0.5)), linear-gradient(45deg, rgba(113, 113, 113, 0.5) 25%, transparent 25%, transparent 75%, rgba(113, 113, 113, 0.5) 75%, rgba(113, 113, 113, 0.5));
                background-repeat: repeat;
                background-size: 16px 16px;
              }
            </style>
          </head>
          <body>
            ${imageHtml}
          </body>
        </html>
      `);
      printWindow.document.close();
    }
  };

  return isOpen ? (
    <div className={styles.modalOverlay}>
      <div className={styles.modalHeader}>
        <button className={styles.printBtn} onClick={handlePrint} aria-label="Print">
          <PrintIcon />
        </button>
        <button className={styles.closeBtn} onClick={onClose} aria-label="Close">
          <CloseIcon />
        </button>
      </div>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-4xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          <div className={styles.modalContent}>
            <div className={styles.optionsWrapper}>
              <img
                ref={imageRef}
                src={imageSrc}
                alt="imagePreview"
                className={styles.imagePreview}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ImagePreview;
