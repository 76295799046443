import QRCode from 'react-qr-code'
import { UcmHeader } from './ucm-header'

// Styles
import styles from '../design-detail/art-details.module.css'

export function UCMHeader(props: {
  header: UcmHeader
}) {
  const { docNo, lineNo, woEncodedMessage, warehouseShipmentNo,
    whEncodeMessage, customerId, customerName, itemNo, shipDate,
    shippingAgent, shippingAgentService, totalQuantities, runTime, salesOrderNo, salesOrderLineNo, lineItemsCount, lineItemsShowing } = props.header

  return <>
    <div className={styles.qrMultiRow}>
      <div className={styles.qrInfo}>
        {(docNo && lineNo) &&
          <QRCode
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            size={256}
            value={woEncodedMessage}
            viewBox={`0 0 256 256`}
          />
        }
        <div className={styles.header}>
          <span>Work Order</span>
          <span><b>{docNo && lineNo ? `${docNo} ${lineNo}` : "--"}</b></span>
        </div>
      </div>
      <div className={styles.qrInfo}>
        {warehouseShipmentNo &&
          <QRCode
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            size={256}
            value={whEncodeMessage}
            viewBox={`0 0 256 256`}
          />
        }
        <div className={styles.header}>
          <span>WH Shipment Number</span>
          <span><b>{warehouseShipmentNo || "--"}</b></span>
        </div>
      </div>
    </div>
    <div className={styles.infoWrapper}>

      {/* First Row */}
      <div className={styles.infoRow}>
        <div className={styles.itemInfo}>
          <span className={styles.infoTitle}>WO Number</span>
          <span className={styles.infoValue}>{docNo ?? "--"}</span>
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.infoTitle}>Cust. No</span>
          <span className={styles.infoValue}>{customerId}</span>
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.infoTitle}>Cust. Name</span>
          <span className={styles.infoValue}>{customerName}</span>
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.infoTitle}>Ship</span>
          <span className={styles.infoValue}>{shippingAgent}/{shippingAgentService}</span>
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.infoTitle}>Sales Order</span>
          <span className={styles.infoValue}>{salesOrderNo} {salesOrderLineNo}</span>
        </div>
      </div>

      {/* Second Row */}
      <div className={styles.infoRow}>
        <div className={styles.itemInfo}>
          <span className={styles.infoTitle}>Ship Date</span>
          <span className={styles.infoValue}>{shipDate}</span>
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.infoTitle}>Item No</span>
          <span className={styles.infoValue}>{itemNo}</span>
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.infoTitle}>Quantity</span>
          <span className={styles.infoValue}>{totalQuantities}</span>
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.infoTitle}>UCMax Items / Total Items</span>
          <span className={styles.infoValue}>{lineItemsShowing} / {lineItemsCount}</span>
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.infoTitle}>Run time</span>
          <span className={styles.infoValue}>{runTime ? runTime.toFixed(2) : ''} minutes</span>
        </div>
      </div>
    </div>
  </>
}