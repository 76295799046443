
export const GetSizeName = function (size: string) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/data/post-process.js#L1041
  var sizeMap = {
    "0.625": "5/8\"",
    "0.75": "3/4\"",
    "1.125": "1 1/8\"",
    "1.25": "1 1/4\"",
    "1.5": "1 1/2\"",
    "2.5": "2 1/2\"",
    "2.75": "2 3/4\"",
    "3.5": "3 1/2\"",
    "6.75": "6 3/4\""
  };

  if (sizeMap[size as keyof typeof sizeMap]) {
    return sizeMap[size as keyof typeof sizeMap];
  } else {
    return size + "\"";
  }
};
