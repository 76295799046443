import { useEffect, useState } from "react";
import { IProdOrderLineItem } from "../../types/iprod-order";
import { IWizardData } from "../../types/iwizard-data";
import { generateImageSrcFromArtwork, getQueryParamValue } from "../../utils/general";
import QRCode from "react-qr-code";
import { useSnapshot } from "valtio";
import appModel from "../../models/app-model";
import { queryUCMItems } from "../../models/ucm-queries";
import { getImageObject } from "../../utils/general";
import { DEFAULT_IMAGE } from "../../config";
import { ArtworkItemType, ArtworkType, ProductionArtworkType } from "../../types/api-types";
import { cardConfigs, cardEasyViewConfigs, cardLNConfigs, mediaMap } from "../../utils/artDetailsUtils";
import PrintArtDetails from "./comps/printArtDetails";

// Styles
import styles from './art-details.module.css'; // index.tsx

// Print template (single item view)
interface PrintTemplateProps {
  wizardDocData: IWizardData
  woQR: { orderNo: string | null, lineNo: string | null }
  productionData?: IProdOrderLineItem[];
  productionArtwork: ProductionArtworkType | null;
  productionArtworkPreview: ArtworkType | null;
  originalArtwork: ArtworkType | null;
}

interface Dimension {
  label: string;
  value: number | string;
}

interface ArtWork {
  productionArtwork?: ProductionArtworkType,
  productionArtworkPreview?: ArtworkType,
  originalArtwork?: ArtworkType
}

const PrintTemplate: React.FC<PrintTemplateProps> = ({
  wizardDocData,
  woQR,
  productionData,
  productionArtwork,
  productionArtworkPreview,
  originalArtwork }) => {
  const appSnap = useSnapshot(appModel);
  const isLNTransfer = appSnap.activeDesignDoc?.docType === "LNTransfer";
  const isEasyView = appSnap.activeDesignDoc?.wizardID === 'EasyView';
  const { lineNo, orderNo } = woQR;
  const woEncodedMessage = `%P%${orderNo} ${lineNo}`;
  const docNo = getQueryParamValue('DocNo');
  const { aspectRatio, artName, fontHeight, bannerHeight, bannerWidth, artWidth, artHeight, quantities, origFileName } = wizardDocData;
  const mediaText = wizardDocData.media ? mediaMap[wizardDocData.media as keyof typeof mediaMap] ?? "" : undefined
  const extendedWizardDocData = typeof mediaText == "string" ? { mediaText, ...wizardDocData } : wizardDocData
  const [workOrderData,] = useState<IProdOrderLineItem[]>(productionData || []);
  const quantity = workOrderData?.reduce((total, prodOrder) => total + prodOrder.quantity, 0);
  const warehouseShipmentNo = workOrderData?.at(0)?.whseshipno;
  const customerId = workOrderData?.at(0)?.customerNumber ?? "--";
  const customerName = workOrderData?.at(0)?.customerName ?? "--";
  const shippingAgent = workOrderData?.filter(x => !!x.shippingAgentServiceCode).at(0)?.shippingAgentName ?? "--";
  const shippingAgentService = workOrderData?.filter(x => !!x.shippingAgentServiceCode).at(0)?.shippingAgentServiceDescription ?? "--";
  const salesOrderNo = appSnap.prodLineItems[0]?.salesorderno_vel ?? "--";
  const whEncodeMessage = `%WS%${warehouseShipmentNo}`; //%WS%ShipmentNumber
  const [prodOrdersData, setProdOrdersData] = useState<{ itemNo?: string }[]>([]);
  const artworks: ArtWork = {
    productionArtwork: productionArtwork || undefined,
    productionArtworkPreview: productionArtworkPreview || undefined,
    originalArtwork: originalArtwork || undefined,
  };
  const imageURL = getImageObject(artworks, DEFAULT_IMAGE);
  const imgSrc = Array.isArray(imageURL) ? imageURL[0] : DEFAULT_IMAGE;
  const configs = isLNTransfer ? cardLNConfigs : (isEasyView ? cardEasyViewConfigs : cardConfigs);
  const designId = appSnap.activeDesignDoc?.id;

  // Get ItemNo
  useEffect(() => {
    queryUCMItems(workOrderData)
      .then((newProdOrdersData) => {
        setProdOrdersData(newProdOrdersData)
      })
      .catch((err) => {
        console.error('Error fetching queryUCMItems:', err);
      })
  }, []);

  // Product Details keys
  const artDetailsConfig = configs.find(config => config.title === 'ART DETAILS');
  const artDetailsProperties = artDetailsConfig ? artDetailsConfig.properties.filter(property => property.key && typeof property.label === 'string') : [];

  // Product Details keys
  const productDetailsConfig = configs.find(config => config.title === 'PRODUCT DETAILS');
  const productDetailsProperties = productDetailsConfig ? productDetailsConfig.properties.filter(property => property.key && typeof property.label === 'string') : [];

  // Material Selection keys
  const materialDetailsConfigs = configs.find(config => config.title === 'MATERIAL SELECTION DETAILS');
  const materialSelectionProperties = materialDetailsConfigs ? materialDetailsConfigs.properties.filter(property => property.key && typeof property.label === 'string') : [];

  // Validate Height/Width types
  const dimensions: Dimension[] = [];
  if (fontHeight) {
    dimensions.push({ label: 'Font Height', value: fontHeight });
  } else if (bannerHeight && bannerWidth) {
    dimensions.push({ label: 'Banner Width', value: bannerWidth });
    dimensions.push({ label: 'Banner Height', value: bannerHeight });
  } else if (artWidth && artHeight) {
    dimensions.push({ label: 'Art Width', value: artWidth });
    dimensions.push({ label: 'Art Height', value: artHeight });
  }

  return (
    <>
      <div className={styles.row}>
        <div className={styles.column}>
          <span><b>Customer No.</b></span>
          <span><b>{customerId}</b></span>
        </div>
        <div className={styles.column}>
          <span><b>Customer Name</b></span>
          <span><b>{customerName}</b></span>
        </div>
        <div className={styles.column}>
          <span><b>Sales Order No.</b></span>
          <span><b>{salesOrderNo}</b></span>
        </div>
      </div>

      {/* QR Codes */}
      <div className={styles.qrRow}>
        {/* Work Order QR Code */}
        <div className={styles.qrWrapper}>
          {(orderNo && lineNo) &&
            <>
              <QRCode
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                size={256}
                value={woEncodedMessage}
                viewBox={`0 0 256 256`}
              />
              <div className={styles.header}>
                <span><b>Work Order Number</b></span>
                <span><b>{docNo && lineNo ? `${docNo} ${lineNo}` : "--"}</b></span>
              </div>
            </>
          }
        </div>

        {/* Warehouse Shipment QR Code */}
        <div className={styles.qrWrapper}>
          {warehouseShipmentNo &&
            <><QRCode
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              size={256}
              value={whEncodeMessage}
              viewBox={`0 0 256 256`}
            />
              <div className={styles.header}>
                <span><b>WH Shipment Barcode</b></span>
                <span><b>{warehouseShipmentNo ?? "--"}</b></span>
              </div>
            </>
          }
        </div>
      </div>

      <div className={styles.printContainer}>
        <div className={styles.leftColumn}>
          <div className={styles.imageSection}>
            <img src={generateImageSrcFromArtwork(imgSrc as ArtworkItemType, DEFAULT_IMAGE)} alt="Artwork" />
          </div>

          {/* Art Details  */}
          {(isEasyView || isLNTransfer) ?
            <PrintArtDetails title="ART DETAILS" properties={artDetailsProperties} wizardDocData={extendedWizardDocData} />
            :
            <>
              <h3 className={styles.title}>ART DETAILS</h3>
              <div className={styles.artDetails}>
                <span className={styles.name}>Artwork Name:</span>
                <span className={styles.value}>{artName}</span>
              </div>
              <div className={styles.artDetails}>
                <span className={styles.name}>Design ID:</span>
                <span className={styles.value}>{designId}</span>
              </div>
              <div className={styles.artDetails}>
                <span className={styles.name}>Aspect Ratio:</span>
                <span className={styles.value}>{aspectRatio ? Number(aspectRatio)?.toFixed(2) : "--"}</span>
              </div>
              <>
                {dimensions.map((dimension, index) => (
                  <div key={index} className={styles.artDetails}>
                    <span className={styles.name}>{dimension.label}</span>
                    <span className={styles.value}>{dimension.value}</span>
                  </div>
                ))}
              </>
              <div className={styles.artDetails}>
                <span className={styles.name}>File Name:</span>
                <span className={styles.value}>{origFileName}</span>
              </div>
              <div className={styles.artDetails}>
                <span className={styles.name}>Quantities:</span>
                {quantities ? (
                  <span className={styles.value}>
                    {Object.entries(quantities).map(([_, value]) => `${value}`).join(', ')}
                  </span>
                ) : <span className={styles.value}>--</span>}
              </div>
            </>
          }
        </div>

        <div className={styles.rightColumn}>
          <div className={styles.row}>
            <div className={styles.column}>
              <span className={styles.columnName}>Item No.:</span>
              <span>{prodOrdersData[0]?.itemNo ?? "--"}</span>
            </div>

            <div className={styles.column}>
              <span className={styles.columnName}>Ship Date:</span>
              <span>{appSnap.prodLineItems[0]?.duedate ?? "--"}</span>
            </div>

            <div className={styles.column}>
              <span className={styles.columnName}>Ship:</span>
              <span>{shippingAgent}/{shippingAgentService}</span>
            </div>
            {
              isLNTransfer ?
                <>
                  <div className={styles.column}>
                    <span className={styles.columnName}>Quantity of kits:</span>
                    <span>{appSnap.prodLineItems[0]?.quantity ?? "--"}</span>
                  </div>
                  <div className={styles.column}>
                    <span className={styles.columnName}>Items per kit:</span>
                    <span>{appSnap.activeDesignDoc?.wizardData.quantity ?? "--"}</span>
                  </div>
                </>
                :
                <div className={styles.column}>
                  <span className={styles.columnName}>Quantity:</span>
                  <span>{quantity ?? "--"}</span>
                </div>
            }
          </div>

          {/* Product and Material Selection Details  */}
          <div className={styles.productDetailsContainer}>
            <PrintArtDetails title="PRODUCT DETAILS" properties={productDetailsProperties} wizardDocData={extendedWizardDocData} />
            <PrintArtDetails title="MATERIAL SELECTION DETAILS" properties={materialSelectionProperties} wizardDocData={extendedWizardDocData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintTemplate;