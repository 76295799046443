import styles from './skeletons.module.css';

export const SkeletonMainBar = () => {
  return (
    <div className={[styles.mainBarSkeleton, styles.headerSkeleton].join(' ')} />
  );
};

export const SkeletonImageContainer = () => {
  return (
    <div className={[styles.animation, styles.imageWrapperSkeleton].join(' ')} />
  );
};

export const SkeletonCommunicationContainer = () => {
  return (
    <div className={[styles.animation].join(' ')} />
  );
};

export const SkeletonArtCenterContainer = () => {
  return (
    <div className={[styles.artCenterSkeleton, styles.animation].join(' ')} />
  );
};

export const SkeletonCard = () => {
  return (
    <div className={[styles.cardDetailsSkeleton, styles.animation].join(' ')} />
  );
};

export const SkeletonButton = () => {
  return (
    <button className={[styles.buttonSkeleton, styles.animation].join(' ')} />
  );
}

export const SkeletonCommunication = () => {
  return (
    <div className={[styles.communicationSkeleton, styles.animation].join(' ')} />
  );
}