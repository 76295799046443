import React, { useEffect, useState } from 'react';
import { DeleteIcon } from './imageSelector';
import { getBackendApiUrl } from "../../../config";

// Styles
import styles from './notesModal.module.css';
import './status.module.css';
import { WizardDocNote } from '../../../types/api-types';
import { addAuthorizationHeader } from '../../../api/network';

// Defining the prop types
interface NotesModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentId?: string;
}

const NotesModal: React.FC<NotesModalProps> = ({ isOpen, onClose, documentId }) => {
  const [addNoteMode, setAddNoteMode] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)
  const [newNoteData, setNewNoteData] = useState<any>("")
  const [notes, setNotes] = useState<WizardDocNote[]>([])

  const headers = {
    'Content-Type': 'application/json'
  };

  useEffect(() => {
    setProcessing(true);
    fetch(`${getBackendApiUrl()}/DesignNotes/${documentId}`, {
      method: 'GET',
      headers: addAuthorizationHeader(headers),
    }).then(async data => data.ok ? data.json() : Promise.reject(await data.text()))
      .then(jsonData => {
        setNotes(jsonData);
        setProcessing(false);
      })
      .catch(err => {
        alert("Failed to fetch design notes " + err);
      });
  }, []);

  const removeNote = (id: number) => {
    setProcessing(true);
    fetch(`${getBackendApiUrl()}/DesignNotes/${id}`, {
      method: 'DELETE',
      headers: addAuthorizationHeader({})
    }).then(async data => {
      if (!data.ok) throw new Error("" + await data.text());
      const newNotes = notes.filter(x => x.id !== id);
      setNotes(newNotes);
      setProcessing(false);
    })
      .catch(err => {
        alert("Failed to delete design notes " + (err as Error).message);
      });
  };

  const addNote = (note: string) => {
    setProcessing(true);
    const payload = {
      docId: documentId as string,
      note
    };
    fetch(`${getBackendApiUrl()}/DesignNotes`, {
      method: 'POST',
      headers: addAuthorizationHeader(headers),
      body: JSON.stringify(payload)
    }).then(async data => data.ok ? data.json() : Promise.reject(await data.text()))
      .then(jsonData => {
        const updatedNotes = [...notes, { id: jsonData.id, docId: documentId as string, note }];
        setNotes(updatedNotes);
        setProcessing(false);
      })
      .catch(err => {
        alert("Failed to add design notes");
        console.error(err);
      });
  };

  function CloseIcon() {
    return (
      <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span className="sr-only">Close menu</span>
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    );
  }

  const modal = addNoteMode ? (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          <div className={[styles.header, styles.addNoteHeader].join(' ')}>
            <span>New Note</span>
            <div className={styles.closeIcon} onClick={() => setAddNoteMode(false)}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.addModalContent}>
            <textarea
              className={styles.commentArea}
              cols={85} rows={10} onChange={(ev) => { setNewNoteData(ev.currentTarget.value) }} value={newNoteData}></textarea>
          </div>
          <div className={`${styles.footerButtons} p-6 border-t border-solid border-blueGray-200 rounded-b`}>
            <button className={[styles.button, styles.cancelBtn].join(' ')} onClick={() => { setAddNoteMode(false); }} disabled={processing}>Close</button>
            <button className={styles.acceptBtn} onClick={() => { addNote(newNoteData); setAddNoteMode(false); }} disabled={processing || newNoteData.length < 1}>Add</button>
          </div>
        </div>
      </div>
    </div>
  ) :
    (
      <div className={styles.modalOverlay}>
        <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
          <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
            <div className={styles.header}>
              <span>Notes</span>
              <div className={styles.closeIcon} onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
            <div className={[styles.notesContent, notes?.length === 0 ? styles.alignCenter : ""].join(' ')}>
              {notes?.length > 0 ? notes?.map((note: any) => {
                return <div key={note.id} className={styles.notesBox}>
                  <span>{note.note}</span>
                  <span className={styles.deleteIcon} onClick={() => processing ? void 0 : removeNote(note.id)}>
                    <DeleteIcon />
                  </span>
                </div>
              }) :
                <div className={styles.noNotes}>
                  <span>No notes shared.</span>
                </div>
              }
            </div>
            <div className={`${styles.footerButtons} p-6 border-t border-solid border-blueGray-200 rounded-b`}>
              <button className={[styles.button, styles.cancelBtn].join(' ')} onClick={() => { onClose(); }} disabled={processing}>Close</button>
              <button className={styles.acceptBtn} onClick={() => { setNewNoteData(""); setAddNoteMode(true); }} disabled={processing}>New Note</button>
            </div>
          </div>
        </div>
      </div>
    )

  return isOpen ? modal : null;
};

export default NotesModal;