import { useEffect, useState } from "react";
import SearchBox from "./search-box";
import { useMedia } from "../../utils/match-media";
import { getDWSUrl } from "../../config";
import { ArtDashboardFilter } from "../../pages/art-dashboard/art-filter";

// Styles
import styles from './header.module.css';
import { getCustomerToken } from "../../api/network";

interface HeaderProps {
  customerId: string;
  onMenuToggle: (isOpen: boolean) => void;
}

function BarsIcon() {
  return (
    <svg
      className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" stroke="#fff" strokeWidth={2} />
    </svg>
  )
}

function Close() {
  return (
    <svg
      className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" stroke="#fff" />
    </svg>
  )
}

export const Header: React.FC<HeaderProps> = ({ customerId, onMenuToggle }) => {
  const buttonClasses = `${styles.orangeBtn} flex flex-row gap-1 text-white hover:bg-blue-500`;
  const adaptToMobile = useMedia("(max-width: 810px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [reduceFilterSize, setReduceFilterSize] = useState<boolean>(false);

  useEffect(() => {
    if (!adaptToMobile && isMenuOpen) {
      hideMenu();
    }
  }, [adaptToMobile]);

  const hideMenu = () => {
    setIsMenuOpen(false);
    onMenuToggle(false);
  }

  const handleReduceFilterSizeChange = (newValue: boolean) => {
    setReduceFilterSize(newValue);
  };

  const launchDWS = (customerId: string) => {
    const DWS_URL = getDWSUrl();
    const dwsWizard = "https://" + DWS_URL;

    const params = new URLSearchParams(window.location.search);
    params.append('source', 'dashboard');
    const cookieValues: string[] = [];

    params.forEach((value, key) => {
      cookieValues.push(`__dws_${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    });

    localStorage.setItem('dws-cookie', cookieValues.join('; '));
    window.location.replace(`${dwsWizard}?customerId=${customerId}&source=dashboard&customerToken=${getCustomerToken()}`);
  };

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen)
    onMenuToggle(!isMenuOpen);
  }

  return (
    <>
      <div className={styles.nav}>
        <div className={[styles.mainBar].join(' ')}>
          <div>
            {
              !adaptToMobile ?
                <ArtDashboardFilter
                  reduceFilterSize={reduceFilterSize}
                  onSelectedStatus={() => hideMenu()} />
                :
                <div onClick={handleClick} className={styles.barsIcon}>
                  {!isMenuOpen ? <BarsIcon /> : <Close />}
                </div>
            }
          </div>
          <div className={styles.rightContent}>
            <SearchBox onReduceFilterSizeChange={handleReduceFilterSizeChange} />
            <a href="#" className={buttonClasses} onClick={() => { launchDWS(customerId) }}>
              Upload
            </a>
          </div>
        </div>
      </div>
      <div className={[styles.sideBar, isMenuOpen ? styles.open : styles.close].join(' ')}>
        <ArtDashboardFilter
          menuToggle={isMenuOpen}
          reduceFilterSize={reduceFilterSize}
          onSelectedStatus={() => hideMenu()} />
      </div>
    </>
  );
};

export default Header;


