import React, { useEffect } from 'react';
import { splitCamelCase } from '../../../components/cardDetails/cardValues';
import { getValue, renderValue } from '../../../components/cardDetails/valueRender';
import { DesignDoc } from '../../../types/api-types';
import mapDataToCards, { mediaMap } from '../../../utils/artDetailsUtils';

// Styles
import styles from './artDetails.modal.module.css';

// Defining the prop types
interface ArtDetailsModalsProps {
  isOpen: boolean;
  onClose: () => void;
  document?: DesignDoc;
}
const server = 'https://www.stahls.com/stahls/13/artworkupload/mapjobdatadetails?mapName=cart'

type Label = { val: string, grp: number, lbl: string }
type LabelMap = { [key: string]: Label }

async function mapWizardData(wizardData: any) {
  const result = await fetch(server, {
    method: 'POST',
    body: JSON.stringify(wizardData)
  })

  const mapLabels = (await result.json()) as LabelMap

  const groupedLabels = new Map<number, Label[]>()
  for (const [, label] of Object.entries(mapLabels)) {
    const grpId = label.grp
    if (!groupedLabels.has(grpId)) {
      groupedLabels.set(grpId, [label])
    } else {
      groupedLabels.get(grpId)?.push(label)
    }
  }

  return groupedLabels

}

const globalDiv = document.createElement('div');
function htmlDecode(content: string) {
  globalDiv.innerHTML = content;
  const val = globalDiv.childNodes.length === 0 ? "" : globalDiv.childNodes[0].nodeValue;
  return val ?? ""
}

const ArtDetailsModal: React.FC<ArtDetailsModalsProps> = ({ isOpen, onClose, document }) => {
  let wizardDocData = document?.wizardData;
  const isEasyView = document?.wizardID === "EasyView";
  const isLNTransfer = document?.docType === "LNTransfer";
  wizardDocData = Array.isArray(wizardDocData) ? wizardDocData[0] : wizardDocData;
  wizardDocData = isEasyView ? { ...wizardDocData, ...wizardDocData?.extendedData } : wizardDocData;
  const wizardProps = Object.assign(
    wizardDocData.media ? { mediaText: mediaMap[wizardDocData.media as keyof typeof mediaMap] ?? "" } : {},
    wizardDocData
  );
  const mappedCards = mapDataToCards(isLNTransfer, isEasyView);
  const mappedKeys = new Set(mappedCards.flatMap(cardConfig => cardConfig.properties.map(property => property.key)));
  const unmappedKeys = Array.from(
    new Set(
      Object.keys(wizardProps)
        .filter(key => (key !== 'extendedData' && key !== "quote") && !mappedKeys.has(key))
        .concat(
          isEasyView && wizardDocData.extendedData
            ? Object.keys(wizardDocData.extendedData).filter(key => !mappedKeys.has(key))
            : []
        )
    )
  );

  const [labelMap, setLableMap] = React.useState<Map<number, Label[]> | null>(null)

  useEffect(() => {
    mapWizardData(wizardDocData)
      .then(labelMap => {
        setLableMap(labelMap)
      })
  }, [])

  function CloseIcon() {
    return (
      <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span className="sr-only">Close menu</span>
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    );
  }

  return isOpen ? (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          <div className={styles.header}>
            <span>Art Details</span>
            <div className={styles.closeIcon} onClick={onClose}>
              <CloseIcon />
            </div>
          </div>

          <div className={styles.modalContent}>
            {Array.from(labelMap ?? []).map(([grpId, labels]) => {
              const validLabels = labels.filter(label => label.val && label.lbl);
              if (validLabels.length === 0) return null;

              return (
                <div className={styles.detailItem} key={grpId}>
                  {validLabels.map((label, idx) => (
                    <div key={idx} className={styles.lblWrapper}>
                      <span className={styles.label}>
                        {htmlDecode(label.lbl)}:
                      </span>
                      <span className={styles.artValue} dangerouslySetInnerHTML={{ __html: label.val }} />
                    </div>
                  ))}
                </div>
              );
            })}

            {/* Render mapped keys */}
            {mappedCards.map((cardConfig, index) => (
              <React.Fragment key={index}>
                {cardConfig.properties.map((property) => {
                  const value = getValue(property, wizardProps, "");
                  const displayValue = renderValue(value);

                  if (displayValue === null) return null;

                  return (
                    <div className={styles.detailItem} key={property.key}>
                      <span className={styles.label}>{property.label}:</span>
                      <span className={styles.artValue}>{displayValue}</span>
                    </div>
                  );
                })}
              </React.Fragment>
            ))}

            {/* Render unmapped keys */}
            {unmappedKeys.map(key => {
              const value = wizardProps[key];
              const displayValue = renderValue(value);

              if (displayValue === null) return null;

              return (
                <div className={styles.detailItem} key={key}>
                  <span className={styles.label}>{splitCamelCase(key)}:</span>
                  <span className={styles.artValue}>{displayValue}</span>
                </div>
              );
            })}
          </div>

          <div className={`${styles.footerButtons} p-6 border-t border-solid border-blueGray-200 rounded-b`}>
            <button className={[styles.button, styles.cancelBtn].join(' ')} onClick={() => { onClose(); }} >Close</button>
          </div>
        </div>
      </div>
    </div >
  ) : null;
};

export default ArtDetailsModal;
