import React, { useEffect, useState } from 'react';
import { updateDashboard } from '../../models/app-actions'
import { useMedia } from '../../utils/match-media';
import { isMobile } from '../../utils/isMobile';
import { NextIcon, PreviousIcon } from '../carousel/Carousel';

// Styles
import styles from './pager.module.css';

interface PagerProps {
  totalItems: number;
  itemsPerPage: number;
  currentState?: string;
  searchText?: string;
}

export const Pager: React.FC<PagerProps> = ({ totalItems, itemsPerPage, currentState, searchText }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [state, setState] = useState<string | undefined>(currentState);
  const [search, setSearch] = useState<string | undefined>(searchText);
  const matchMedia = useMedia("(max-width: 810px)");
  const mobile = isMobile();
  const pageLimit = (matchMedia || mobile) ? 3 : 5;
  const className = "relative flex m-4 items-center justify-between border-t border-gray-200 px-4 sm:px-0";
  const archived = currentState === "ARCHIVED";

  useEffect(() => {
    if (state !== currentState || search !== searchText) {
      setCurrentPage(1);
      setState(currentState);
      setSearch(searchText);
    }
  }, [currentState, searchText]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
      updateDashboard({ pageIndex: newPage, archived })
    }
  }

  const generatePageLinks = () => {
    let startPage = currentPage - Math.floor(pageLimit / 2);
    startPage = Math.max(startPage, 1);
    let endPage = startPage + pageLimit - 1;
    endPage = Math.min(endPage, totalPages);

    if (endPage - startPage + 1 < pageLimit) {
      startPage = Math.max(endPage - pageLimit + 1, 1);
    }

    const pageLinks = [];
    if (startPage > 1) {
      pageLinks.push(
        <a
          key={1}
          href="#"
          onClick={() => handlePageChange(1)}
          className={`px-4 pt-4 font-medium text-gray-500 hover:text-gray-700`}
        >
          1
        </a>
      );
      if (startPage > 2) {
        pageLinks.push(<span key="start-ellipsis" className="px-4 pt-4 font-medium">...</span>);
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      pageLinks.push(
        <a
          key={page}
          href="#"
          onClick={() => handlePageChange(page)}
          className={`inline-flex items-center px-4 pt-4 font-medium ${page === currentPage ? 'text-orange-600' : 'text-gray-500 hover:text-gray-700'
            }`}
        >
          {page}
        </a>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageLinks.push(<span key="end-ellipsis" className="px-4 pt-4 font-medium">...</span>);
      }
      pageLinks.push(
        <a
          key={totalPages}
          href="#"
          onClick={() => handlePageChange(totalPages)}
          className={`px-4 pt-4 font-medium text-gray-500 hover:text-gray-700`}
        >
          {totalPages}
        </a>
      );
    }

    return pageLinks;
  };

  // Calculate progress bar width
  const progressBarWidth = (currentPage / totalPages) * 100;

  return (
    <nav className={[className, (matchMedia || mobile ? styles.mpBox : '')].join(' ')}>
      <div
        className="absolute top-0 left-0 h-1 border-t-2 border-orange-500"
        style={{ width: `${progressBarWidth}%`, borderColor: `rgb(249 115 22 / var(--tw-border-opacity))` }}
      ></div>
      <div className={`-mt-px flex w-0 flex-1`}>
        <a
          href="#"
          onClick={() => handlePageChange(currentPage - 1)}
          className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 font-medium ${currentPage === 1 ? 'text-gray-500' : 'text-gray-700 hover:border-gray-300 hover:text-gray-700'
            }`}
        >
          {mobile || matchMedia ? <span className={styles.icon}><PreviousIcon /></span> : "Previous"}
        </a>
      </div>
      <div className={`${(matchMedia || mobile) && styles.fSize} -mt-px md:flex`}>{generatePageLinks()}</div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <a
          href="#"
          onClick={() => handlePageChange(currentPage + 1)}
          className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 font-medium ${currentPage === totalPages
            ? 'text-gray-500'
            : 'text-gray-700 hover:border-gray-300 hover:text-gray-700'
            }`}
        >
          {mobile || matchMedia ? <span className={styles.icon}><NextIcon /></span> : "Next"}
        </a>
      </div>
    </nav>
  );
};