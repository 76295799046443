import { useSnapshot } from "valtio";
import { DesignList } from "./design-list";
import appModel from "../../models/app-model";
import { ArtStates } from "../../types/art-status";
import HistoryList from "./history-list";

interface ArtListPageProps { }


const ArtDashboardPage: React.FC<ArtListPageProps> = () => {
  
  const appSnap = useSnapshot(appModel)

  if(appSnap.artStatusFilter === ArtStates.HISTORY)
    return <HistoryList />
  else
    return <DesignList />
}

export default ArtDashboardPage;