import React, { useState } from 'react';
import CommentBox from '../commentBox/commentBox';
import Spinner from '../../pages/design-detail/comps/spinner';
import { useSnapshot } from 'valtio';
import appModel from '../../models/app-model';
import { InternalArtStates } from '../../types/art-status';

// Styles
import styles from './customMsgModal.module.css';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  message: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onCancel?: () => void;
  onConfirm?: (comment?: string) => void;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  processing?: boolean;
  userId?: string;
  isCustomerView: boolean;
}

function CloseIcon() {
  return (
    <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
      <span className="sr-only">Close menu</span>
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  );
}

const CustomModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  message,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  onCancel,
  onConfirm,
  showCancelButton = true,
  showConfirmButton = true,
  processing,
  userId,
  isCustomerView
}) => {

  const [comment, setComment] = useState<string>('');
  const appSnap = useSnapshot(appModel);
  const isApproved = appSnap.activeDesignDoc?.statusID == InternalArtStates.APPROVED;

  const handleCommentChange = (value: string) => {
    setComment(value);
  }

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(comment);
      setComment('');
    }
    onClose();
  }

  return isOpen ? (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          {title ?
            <div className={styles.header}>
              <span>{title}</span>
              <div className={styles.closeIcon} onClick={onCancel || onClose}>
                <CloseIcon />
              </div>
            </div> :
            <div className={styles.noHeader} onClick={onCancel || onClose}>
              <CloseIcon />
            </div>
          }
          <div className={[styles.modalContent, !title ? styles.pt0 : ''].join(' ')}>
            <span>{message}</span>
          </div>
          {/* Comment Section */}
          {(userId && !isApproved) &&
            <div className={styles.commentContent}>
              <CommentBox
                onCommentChange={handleCommentChange}
                isCustomerView={isCustomerView}
                showTitle={true} />
            </div>
          }
          <div className={`${styles.footerButtons} p-6 border-t border-solid border-blueGray-200 rounded-b`}>
            {showCancelButton && (
              <button className={[styles.button, styles.cancelBtn].join(' ')} onClick={onCancel || onClose}>
                {cancelButtonText}
              </button>
            )}
            {showConfirmButton && (
              <button className={styles.acceptBtn} onClick={handleConfirm} disabled={processing}>
                {!processing ? confirmButtonText : <Spinner spinning={processing} color="#d1d5db" size={{ width: "22px", height: "22px" }} />}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default CustomModal;
