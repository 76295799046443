
import { useSnapshot } from "valtio"
import appModel from "../../models/app-model"
import { formatRedeable } from "../../utils/general";
import { updateDashboard } from "../../models/app-actions";
import { ArtStates } from "../../types/art-status";
import { isMobile } from "../../utils/isMobile";
import { useMedia } from "../../utils/match-media";

// Styles
import styles from './art-filter.module.css';

interface ArtDashboardFilterProps {
  reduceFilterSize?: boolean;
  menuToggle?: boolean;
  onSelectedStatus: () => void;
}

const getStatusColor = (statusName: ArtStates) => {
  switch (statusName) {
    case ArtStates.REQUIRES_ACTION:
      return styles['requireActionColor'];
    case ArtStates.READY_TO_ORDER:
      return styles['readyToOrderColor'];
    case ArtStates.IN_PROGRESS:
      return styles['inProgressColor'];
    case ArtStates.ARCHIVED:
      return styles['archivedColor'];
    default:
      break;
  }
}

export const ArtDashboardFilter: React.FC<ArtDashboardFilterProps> = ({ reduceFilterSize, menuToggle, onSelectedStatus }) => {
  const appSnap = useSnapshot(appModel);
  const isLoading = appSnap.isPageLoading;
  const selectedStateClass = styles.selected;
  const unselectedStateClass = 'flex items-center text-white hover:bg-white hover:text-black';
  const gapCls = !reduceFilterSize && "gap-x-5";
  const className = `${styles.wizzardWrapper} ${gapCls} order-last flex w-full text-xs font-light sm:order-none sm:w-auto`;
  const matchMedia = useMedia("(max-width: 810px)");
  const mobile = isMobile();

  const handleStateChangeClick = async (artState: ArtStates) => {
    const archived = artState == ArtStates.ARCHIVED;
    updateDashboard({ artState, archived });
    onSelectedStatus();
  };

  const getSelectedClass = (selected: boolean) => { return selected ? selectedStateClass : unselectedStateClass }

  return (
    <div className={[className, styles.bar, (menuToggle || mobile || matchMedia) ? styles.statusContainer : ''].join(' ')}>
      {
        Object.values(ArtStates).map((artState, index) => {
          const itemSelected = appSnap.artStatusFilter === artState

          return (
            <a href="#"
              key={index}
              className={[
                "px-3 py-5",
                styles.status,
                getSelectedClass(itemSelected),
                getStatusColor(artState),
                (isLoading ? styles.disableBtn : ""),
                (reduceFilterSize ? styles.filtersWidth : "")].join(' ')}
              onClick={() => handleStateChangeClick(artState)}>
              {formatRedeable(artState)}
            </a>
          )
        })
      }
    </div>
  )
}