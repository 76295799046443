import { useEffect, useState, useCallback, useRef } from "react";

export function useMedia(query: string) {
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches);
  const mediaRef = useRef<any>(null);

  const listener = useCallback(() => {
    if (mediaRef.current && mediaRef.current.matches !== matches) {
      setMatches(mediaRef.current.matches);
    }
  }, [matches]);

  useEffect(() => {
    mediaRef.current = window.matchMedia(query);
    mediaRef.current.addEventListener("change", listener);
    return () => {
      mediaRef.current.removeEventListener("change", listener);
    };
  }, [query, listener]);

  return matches;
}