import SkeletonLoader from "../../components/list/skeletonAnimation";
import {
  SkeletonArtCenterContainer,
  SkeletonButton,
  SkeletonCard,
  SkeletonCommunication,
  SkeletonImageContainer,
  SkeletonMainBar
} from "../../components/skeletons/skeletons";

// Styles
import styles from './art-details.module.css'; // index.tsx

export function ArtDetailsLoading() {
  // Loading screen
  return (<div className={styles.wrapper}>
    <SkeletonMainBar />
    <div className={styles.container}>
      <div className={[styles.column, styles.dualImg].join(' ')}>
        <SkeletonImageContainer />
      </div>
      <div className={styles.column}>
        <div className={styles.communicationContainer}>
          <div className={styles.wrapper}>
            <SkeletonButton />
            <SkeletonButton />
          </div>
          <div className={styles.wrapper}>
            <SkeletonCommunication />
          </div>
        </div>
      </div>
    </div>
    <div>
      <SkeletonArtCenterContainer />
    </div>
    <div className={styles.infoWrapperSkelton}>
      {[1, 2, 3].map((i) => (
        <SkeletonCard key={i} />
      ))}
    </div>
  </div>);
}

export function DashboardLoading() {
  const loaderArray = [...Array(12).keys()];

  return (<div className={styles.wrapper}>
    <SkeletonMainBar />
    <div style={{ height: "38px", margin: "0.75rem" }} />
    <ul role="list"
      className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-3 md:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {loaderArray?.map((i) => (
        <li key={i} className="relative">
          <SkeletonLoader />
        </li>
      ))}
    </ul>
  </div>)
}