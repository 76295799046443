//here's the query data for graphic fonts: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/teamperfect-wizard-config.js#L13
//changes in the graphic fonts seem rare so we'll have a fixed map instead of querying each time
//all in all the code executed goes like this:

// async function main() {
//   const query = encodeURI(`[{"type":"TEAMPERFECT_LIMITS","template":{"\${GraphicFont}":["\${Graphic}|\${GraphicFontColorSameAsGraphic}"]}}]`)
//   const resp = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//     "headers": {
//       "accept": "application/json, text/javascript, */*; q=0.01",
//       "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//       "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//     },
//     "body": "QueryJSON=" + query,
//     "method": "POST"
//   })
//   const jsonResp = await resp.json()
//   var data=jsonResp[0]
//                 var a = {};
//                 for (var b in data) {
//                     a[b] = {};
//                     switch (b){
//                         case "PG":
//                             a[b].name = "No Font";
//                             break;
//                         case "GV":
//                             a[b].name = "Varsity";
//                             break;
//                         case "GP":
//                             a[b].name = "Pro Block";
//                             break;       
//                     }
//                     a[b].graphics = [];
//                     for (var i = 0; i < data[b].length; i++)
//                     {
//                         var parts = data[b][i].split('|');

//                         a[b].graphics.push(parts[0]);

//                     }
//                 }

//   console.log(a)
// }
// main().catch(err => console.error(err))

//here's the resulting data for graphic fonts

import graphicFonts from './GraphicFonts.json'



//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/static/graphics.js#L1
const GraphicData = {
  "BB": {"value": "BB", "cadworxValue": "Baseball 1",     "cartValue":  "1", "name": "Baseball",  "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Baseball.png"},
  "BS": {"value": "BS", "cadworxValue": "Basketball 1",   "cartValue":  "8", "name": "Basketball","imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Basketball.png"},
  "C1": {"value": "C1", "cadworxValue": "Capsule 1",      "cartValue": "11", "name": "Capsule 1", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Capsule1.png"},
  "C2": {"value": "C2", "cadworxValue": "Capsule 2",      "cartValue": "12", "name": "Capsule 2", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Capsule2.png"},
  "FH": {"value": "FH", "cadworxValue": "Field Hockey 1",     "pathExtWithFont": "Field Hockey 2",        "cartValue": "17", "name": "Field Hockey", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Fieldhockey.png"},
  "FB": {"value": "FB", "cadworxValue": "Football 1",         "pathExtWithFont": "Football 3",            "cartValue":  "3", "name": "Football", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Football.png"},
  "SF": {"value": "SF", "cadworxValue": "Striped Football 1", "pathExtWithFont": "Striped Football 2",    "cartValue":  "7", "name": "Football (striped)", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-FootballSTP.png"},
  "GO": {"value": "GO", "cadworxValue": "Golf 1",         "cartValue": "16", "name": "Golf",      "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Golf.png"},
  "HP": {"value": "HP", "cadworxValue": "Hockey 1",           "pathExtWithFont": "Hockey 2",              "cartValue":  "6", "name": "Hockey", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Hockey.png"},
  "LA": {"value": "LA", "cadworxValue": "Lacrosse 2",         "pathExtWithFont": "Lacrosse 3",            "cartValue": "14", "name": "Lacrosse", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Lacross.png"},
  "MG": {"value": "MG", "cadworxValue": "Megaphone 1",    "cartValue": "10", "name": "Megaphone", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Megaphone.png"},
  "OV": {"value": "OV", "cadworxValue": "Oval 1",         "cartValue": "13", "name": "Oval",      "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Oval.png"},
  "SB": {"value": "SB", "cadworxValue": "Soccer Ball 1",      "pathExtWithFont": "Soccer Ball 2",         "cartValue":  "4", "name": "Soccer", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Soccer.png", graphicFontColorSameAsGraphic: true},
  "SW": {"value": "SW", "cadworxValue": "Swimming 1",     "cartValue": "15", "name": "Swimming",  "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Swimming.png"},
  "TK": {"value": "TK", "cadworxValue": "Track 1",        "cartValue":  "9", "name": "Track",     "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Track.png"},
  "VB": {"value": "VB", "cadworxValue": "VolleyBall 2",       "pathExtWithFont": "Volleyball 3",          "cartValue":  "5", "name": "Volleyball", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Volleyball.png"}
}

export const GraphicStep:(wizardData:any,editParams:any,playerProductData:any)=>{name:string, detail:string}|null = (_wizardData:any,_editParams:any,playerProductData:any) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/graphic.js#L85

  if (playerProductData.GraphicFontCode && playerProductData.GraphicCode) {
    if (graphicFonts[playerProductData.GraphicFontCode as keyof typeof graphicFonts].graphics.includes(playerProductData.GraphicCode)) {
        var graphicFontName = graphicFonts[playerProductData.GraphicFontCode as keyof typeof graphicFonts].name;
        var graphicName = GraphicData[playerProductData.GraphicCode as keyof typeof GraphicData].name;

        return {
            "name": "Graphic",
            "detail": graphicFontName + "<br />" + graphicName
        };
    }
  }
  return null
}