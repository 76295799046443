export const serviceMapping: Record<string, string> = {
  "CadPrintz": "CAD-PRINTZ® Digital Transfers",
  "UltraColorDTFTransfers": "UltraColor® MAX DTF Transfers",
  "CustomCut": "Custom Cut Designs",
  "CustomCutLetters": "Custom Cut Letters",
  "CustomCutNumbers": "Custom Cut Numbers",
  "3DEmbrPatches": "3D Embroidered Patches",
  "EmbrPatches": "Embroidered & Print Stitch Patches",
  "FlexStyle": "FlexStyle® Emblems",
  "ChenillePatches": "Chenille Patches",
  "FlexStyleHolographic": "FlexStyle® Holographic Emblems",
  "LeatherPatches": "Leather & Suede Patches (Genuine & Faux)",
  "PVCEmblems": "PVC Patch",
  "WovenPatches": "Woven Patches & Woven Tags"
};