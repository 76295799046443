import React from 'react';

type SpinnerProps = {
  spinning: boolean;
  size?: { width: string, height: string };
  color?: string;
  darkBkg?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({ spinning, size, color, darkBkg }) => {
  const spin = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const backgroundColor = darkBkg ? "rgba(0, 0, 0, 0.1)" : "rgba(255, 255, 255, 0.8)";

  // Styles for the spinner
  const spinnerStyle = spinning ? {
    display: 'inline-block',
    width: size?.width || '36px',
    height: size?.height || '36px',
    border: `4px solid ${backgroundColor}`,
    borderRadius: '50%',
    borderTopColor: color || '#09f',
    animation: 'spin 1s ease infinite'
  } : {
    display: 'none'
  };

  return (
    <div style={spinnerStyle}>
      <style>{spin}</style>
      <div aria-label="Loading"></div>
    </div>
  );
};

export default Spinner;