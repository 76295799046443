export class Url{
  queryParams: { [key: string]: string } = {};
  constructor(url: string){
    const params = new URL(url)
    for (const [paramKey, paramValue] of params.searchParams.entries()) {
        this.queryParams[paramKey.toLocaleLowerCase()] = paramValue
    }
  }
  getParam(key: string){
    return this.queryParams[key.toLocaleLowerCase()]
  }
}