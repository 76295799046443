import React, { useRef, useState } from 'react';
import useOutsideClick from '../../utils/use-outside-click';

// Styles
import styles from './dropdownMenu.module.css';

interface MenuItem {
  text: string;
  onItemClicked?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}

interface Props {
  buttonText: string;
  menuItems: MenuItem[];
  setOverflow?: boolean;
}

const Dropdown: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const menu = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick(menu, () => {
    setIsOpen(false);
  });

  const handleItemClick = (index: number) => {
    const { onItemClicked, disabled } = props.menuItems[index];
    if (!disabled && onItemClicked) {
      setIsOpen(false);
      onItemClicked();
    }
  };

  const menuContent = props.menuItems.map((item, index) => (
    <button
      key={item.text}
      className={[styles.menuItem, item.disabled ? styles.disableBtn : ""].join(' ')}
      role="menuitem"
      style={item.style}
      onClick={() => handleItemClick(index)}
      disabled={item.disabled}
    >
      <span>{item.text}</span>
    </button>
  ));

  return (
    <div className={[styles.dropdown, props.setOverflow ? styles.relative : ''].join(' ')} ref={menu}>
      <button
        className={styles.button}
        type="button"
        aria-haspopup={true}
        aria-expanded={isOpen}
        aria-controls="headlessui-menu-items"
        onClick={toggleDropdown}
      >
        <span>{props.buttonText}</span>
        <svg className="w-5 h-5 ml-2 -mr-1" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
        </svg>
      </button>
      {isOpen && (
        <div className={styles.dropdownMenu} aria-labelledby="headlessui-menu-button" id="headlessui-menu-items" role="menu">
          {menuContent}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

// How to use it
{/* <Dropdown
buttonText="Button text"
menuItems={[
  { text: 'item option text', onItemClicked: handleClick, style: { }, disabled: true },
]}
/> */}