import { productMapping } from "../../types/product-types";
import { serviceMapping } from "../../types/service-types";
import { ArrayValue, BooleanValue, ObjectValue, StringValue } from "./cardValues";

export const renderValue = (value: any) => {
  if (value === null || value === undefined || value === '' || value === ' ') return null;

  // Check if the value is an object with a 'val' property
  if (typeof value === 'object' && 'val' in value ) {
    const val = value.val;
    if (val.length == 0) return null 
    // Check if val contains HTML by looking for HTML tags
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(val);
    return isHTML ? (
      <span dangerouslySetInnerHTML={{ __html: val }}></span>
    ) : (
      <span>{val}</span>
    );
  }

  if (typeof value === 'boolean') return <BooleanValue value={value} />;
  if (typeof value === 'string' && value.trim() !== '') return <StringValue value={value} />;
  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.length > 0 ? <ArrayValue value={value} /> : null;
    }
    return Object.keys(value).length > 0 ? <ObjectValue value={value} /> : null;
  }

  return <span>{String(value)}</span>;
};


export const getValue = (property: any, restProps: any, artIdParam: string | null) => {
  switch (property.key) {
    case 'aspectRatio':
      return Number(restProps[property.key]).toFixed(2);
    case 'designId':
      return artIdParam;
    case 'serviceType':
      return serviceMapping[restProps[property.key]];
    case 'productType':
      return productMapping[restProps[property.key]];
    case 'quantities':
      const quantities = restProps[property.key];
      if (quantities && typeof quantities === 'object') {
        return Object.entries(quantities).map(([_, v]) => `${v}`).join(', ');
      }
      return '';
    default:
      return restProps[property.key];
  }
};