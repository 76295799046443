import { useEffect, useState } from "react";
import 'react-tabs/style/react-tabs.css';
import appModel from "../../models/app-model";
import { useSnapshot } from "valtio";
import Info from "./comps/info";
import formattedDate from "../../utils/formatted-date";
import { getWizardDocById, importCustomerDesignFiles } from "../../api/backend";
const flex = "flex-wrap gap-6 px-4 sm:px-6 lg:px-8";
import { AppPages } from "../../app-pages";
import { updateDashboard } from "../../models/app-actions";
import { addToCart } from "../../utils/cart";
import { ArtDetailsLoading } from "../design-detail/loading";

// Styles
import styles from './history-details.module.css'; // index.tsx
import artStyles from '../art-dashboard/art.module.css';

export default function HistoryDetailPage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imgLoading, setImgLoading] = useState<boolean>(true);
  const appSnap = useSnapshot(appModel);
  const historyDesign = appSnap.activeHistoryDoc;
  const isLNTransfer = historyDesign?.docType === "LNTransfer";
  const isEasyView = historyDesign?.wizardID === 'EasyView';
  let wizardData = isEasyView ? { ...historyDesign?.wizardData, ...historyDesign?.wizardData?.extendedData } : historyDesign?.wizardData;
  const customerId = appSnap.customerId as string;

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleImageLoaded = () => {
    setImgLoading(false);
  };

  const handleImageError = () => {
    setImgLoading(false);
  };


  const scrollToTop = () => {
    window.scrollTo({
      top: 0
    });
  };

  const handleClick = async () => {
    setIsLoading(true);
    try {
      if (!historyDesign) {
        console.warn('No historyDesign found.');
        return;
      }

      const docId = await importCustomerDesignFiles(customerId, historyDesign);
      if (!docId) {
        throw new Error("Error importing Customer Design Files");
      }

      const wizardDoc = await getWizardDocById(docId);
      if (!wizardDoc) {
        throw new Error("Failed to fetch wizardDoc data");
      }

      const { id, docType, wizardID } = wizardDoc;

      await addToCart(id, customerId, docType, wizardID, false);
    } catch (error) {
      throw error
    } finally {
      setIsLoading(false);
    }
  };

  const MainBar = () => {
    const handleBack = () => {
      appModel.activeHistoryDoc = undefined
      appModel.page = AppPages.Dashboard
      appModel.pageIndex = 0
      updateDashboard()
    };

    return (
      <div className={[flex, styles.mainBar].join(' ')}>
        <div>
          <button
            onClick={handleBack}
            className={`${artStyles.orangeBtn} flex flex-row gap-1 text-white hover:bg-blue-500`}
          >
            Go Back
          </button>
        </div>
      </div>
    );
  };

  if (!historyDesign)
    return <ArtDetailsLoading />
  else
    return <>
      <div className={styles.wrapper}>
        <MainBar />
        <div className={styles.container}>
          <div className={[styles.column, styles.dualImg].join(' ')}>
            <div className={[styles.historyImgWrapper, imgLoading ? styles.skeletonImg : ''].join(' ')}>
              <img
                onLoad={handleImageLoaded}
                onError={handleImageError}
                src={historyDesign?.productionArtworkPreviewUrl}
                alt="history_img" />
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.btnWrapper}>
              <button
                type="button"
                className={`${artStyles.orangeBtn} non-printable text-white ${isLoading ? artStyles.disabledBtn : ''}`}
                onClick={handleClick}
              >
                Add To Cart
              </button>
            </div>
          </div>
        </div>
        <div className={styles.artCenterContainer}>
          <span className={styles.title}>ART CENTER</span>
          <div className={styles.artStatusInfo}>
            <div className={styles.info}>
              <div className={styles.info}>
                {historyDesign?.createdDate &&
                  <span className={styles.details}>
                    Last Modified: {(formattedDate({ dateString: historyDesign?.createdDate, format: 'MM/DD/YYYY' }) ?? "--")}
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
        <div>
          <Info
            isEasyView={isEasyView}
            isLNTransfer={isLNTransfer}
            {...wizardData} />
        </div>
      </div>
    </>
}