//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/playerperfect-wizard-config.js#L298
import StyleOptionsPerPruductName from './GenericStyleOptionsPerProductName.json'

export const LayoutStep:(wizardData:any,editParams:any,playerProductData:any,wizardId:string)=>{name:string, detail:string}|null = (_wizardData:any,_editParams:any,playerProductData:any,wizardId) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/layout.js#L136
  /* only if step is complete, return structure defining "selections" for step  otherwise return null*/
  var result = null;
  var wiz = wizardId;
  switch (wizardId) {
      case "PreSpacedLetters":
          wiz = "PreSpacedText";
  }
  const StyleOptions = StyleOptionsPerPruductName[wiz as keyof typeof StyleOptionsPerPruductName]
  if (playerProductData.StyleType) {
      var selectedStyle = null;

      for (var i = 0; i < StyleOptions.length; i++) {
          if (StyleOptions[i].value == playerProductData.StyleType)
              selectedStyle = StyleOptions[i]
      }

      if (selectedStyle) {
          var selectedBackground = null;

          if (playerProductData.Layer) {
              for (var j = 0; j < selectedStyle.BackgroundOptions.length; j++) {
                  if (selectedStyle.BackgroundOptions[j].value == playerProductData.Layer)
                      selectedBackground = selectedStyle.BackgroundOptions[j];
              }

              result = { "name": "Layout", "detail": selectedStyle.text + "<br>" + (selectedBackground?.text ?? '') };
          }
      }
  }
  return result
}