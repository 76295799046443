import React, { useEffect, useState } from 'react';
import { DownIcon, NextIcon } from '../carousel/Carousel';

// Styles
import styles from './commentBox.module.css';

interface CommentBoxProps {
  onCommentChange: (comment: string) => void;
  showTitle?: boolean;
  resetComment?: boolean;
  isCustomerView: boolean;
}

const CommentBox: React.FC<CommentBoxProps> = ({ onCommentChange, showTitle = false, resetComment, isCustomerView }) => {
  const [comment, setComment] = useState<string>('');
  const [addNote, setAddNote] = useState<boolean>(false);

  useEffect(() => {
    if (resetComment) {
      setComment('');
    }
  }, [resetComment]);

  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newComment = event.target.value;
    setComment(newComment);
    onCommentChange(newComment);
  };

  return (
    <div className={styles.commentContainer}>
      {showTitle && (
        <div className={styles.noteTitle}>
          <span>Add Comment</span>
          <span className={styles.noteIcon} onClick={() => setAddNote(!addNote)}>
            {!addNote ? <NextIcon /> : <DownIcon />}
          </span>
        </div>
      )}
      {(addNote || !showTitle) && (
        <div className={styles.commentWrapper}>
          <textarea
            rows={2}
            name="comment"
            id="comment"
            className={styles.commentArea}
            placeholder={isCustomerView ? `Add your comment for the art team...` : `Add your comment for the user...`}
            value={comment}
            onChange={handleCommentChange}
          />
        </div>
      )}
    </div>
  );
};

export default CommentBox;
