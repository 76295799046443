import { getBackendApiUrl, getCoreAPIUrl } from "../config";
import { ArtworkItemType } from "../types/api-types";
import { getBusinessCentralURLParams, isURL } from "./general";

const imageTypeMapping: Record<string, string> = {
  original: 'Original',
  productionArtwork: 'Production',
  productionArtworkPreview: 'Proof'
};

// Order + Line Item + File Category + File Role 
export const createFileName = (fileRole?: string | null, imageType?: string, extension?: string) => {
  const { artid, DocLineNo } = getBusinessCentralURLParams();
  const mappedImageType = imageType ? imageTypeMapping[imageType] : '';
  const parts = [artid, DocLineNo, mappedImageType, fileRole].filter(Boolean);
  return parts.join('_') + (extension || '');
}

export const getFileExtension = (fileName: string): string => {
  if (!fileName) {
    return '';
  }

  const match = fileName.match(/\.[0-9a-zA-Z]+$/);
  return match ? match[0] : '';
}

export const downloadProductionFile = async (s: string, fileRole?: string | null, imageType?: string, extension?: string) => {
  try {
    const a = document.createElement('a');
    const generatedFileName = createFileName(fileRole, imageType, extension);
    let downloadUrl = "";

    if (isURL(s)) {
      downloadUrl = s;
    } else {
      const fileBaseName = s.split('/').pop()?.replace("_thumbnail", "");
      const server = getBackendApiUrl();
      downloadUrl = `${server}/download?blobName=${fileBaseName}`;
    }

    const response = await fetch(downloadUrl);

    if (!response.ok) {
      throw new Error('Blob download failed');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = generatedFileName;

    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error during blob download:', error);
  }
}

export const downloadCxCoreOriginalFile = async (art:ArtworkItemType) => {
  try {
    const a = document.createElement('a');


    const modelData = encodeURIComponent(JSON.stringify({
      config: art.userFileConfig,
      Ident: {
        SessionId: art.sessionId,
        SuperUser: false
      },
      model: {
          Doc: {
              Id: art.documentId
          },
          fileType: "pdf",
          FileName: createFileName(art.fileRole, 'original', '.pdf')
      }
    }));

    const downloadUrl =  `${getCoreAPIUrl()}/Core2/Doc/Export?${modelData}`;

    const response = await fetch(downloadUrl);

    if (!response.ok) {
      throw new Error('Blob download failed');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = createFileName(art.fileRole, 'original', '.pdf');

    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error during blob download:', error);
  }
}

