//Sew disk for 3DGreek: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/3dgreek-wizard-config.js#L130
//does this

// async function main() {
//   const result = {}
//   const query = encodeURI(`[{"type":"SEWDISKMG","template":["\${ItemNumber}"],"filter":["=","\${WizardID}","3DGreek"]}]`)
//   const resp = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//     "headers": {
//       "accept": "application/json, text/javascript, */*; q=0.01",
//       "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//       "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//     },
//     "body": "QueryJSON=" + query,
//     "method": "POST"
//   })
//   const jsonResp = await resp.json()
//   var data=jsonResp[0]
//     var imagesMap = {
//         "SA": "https://assets.stahls.com/stahls/wizards/letters-numbers/images/sewdisk_images/satin_stitch.jpg",
//         "ZZ": "https://assets.stahls.com/stahls/wizards/letters-numbers/images/sewdisk_images/zigzag_stitch.jpg"
//     };

//     var nameSuffix = "";

//     var results = [];
//     for (var key in data) {
//         var bits = key.split("|");
//         //  Zig-Zag for Mega Greek and Satin for Mega Greek Ledge.
//         results.push({
//             "StitchType": bits[0],
//             "StitchTypeDescription": bits[1],
//             "StitchTypeName": bits[1] + nameSuffix,
//             "ItemNumber": bits[2],
//             "ImageURL": imagesMap[bits[0]], 
//             "CustomType": bits[3] ? bits[3] : "",
//             "Materials": data[key]
//         });
//     }
//     var SewDiskMaterials = { "3DGreek": results[0].Materials };
  
//   console.log({ "3DGreek": results })
//   console.log(SewDiskMaterials)
// }
// main().catch(err => console.error(err))


//for autostitch and custom cut change the and type to SEWDISK and the wizardID

const SewDiskMaterialsPerWizardID = {
  "3DGreek": "EDMG",
    "AutoStitch": [
        "APP",
        "PST",
        "TWI"
    ],
    "CustomCut": [
        "APP",
        "FEA",
        "FEW",
        "PST",
        "TWI"
    ]
}

const SewDiskPerWizardID = {
  "3DGreek": [
      {
          "StitchType": "0",
          "StitchTypeName": "undefined",
          "CustomType": "",
          "Materials": "EDMG",
          "StitchTypeDescription": undefined
      }
  ],
    "AutoStitch": [
        {
            "StitchType": "SA",
            "StitchTypeDescription": "Satin",
            "StitchTypeName": "Satin",
            "ItemNumber": "EDAL",
            "ImageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/images/sewdisk_images/satin_stitch.jpg",
            "CustomType": "",
            "Materials": [
                "APP",
                "PST",
                "TWI"
            ]
        },
        {
            "StitchType": "ZZ",
            "StitchTypeDescription": "Zig Zag",
            "StitchTypeName": "Zig Zag",
            "ItemNumber": "EDAL",
            "ImageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/images/sewdisk_images/zigzag_stitch.jpg",
            "CustomType": "",
            "Materials": [
                "APP",
                "PST",
                "TWI"
            ]
        }
    ],
    "CustomCut": [
        {
            "StitchType": "SA",
            "StitchTypeDescription": "Satin",
            "StitchTypeName": "Satin",
            "ItemNumber": "EDCL",
            "ImageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/images/sewdisk_images/satin_stitch.jpg",
            "CustomType": "",
            "Materials": [
                "APP",
                "FEA",
                "FEW",
                "PST",
                "TWI"
            ]
        },
        {
            "StitchType": "ZZ",
            "StitchTypeDescription": "Zig Zag",
            "StitchTypeName": "Zig Zag",
            "ItemNumber": "EDCL",
            "ImageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/images/sewdisk_images/zigzag_stitch.jpg",
            "CustomType": "",
            "Materials": [
                "APP",
                "FEA",
                "FEW",
                "PST",
                "TWI"
            ]
        }
    ]
}

export const SewDiskStep:(wizardData:any,data:any,wizardId:string)=>{name:string, detail:string}|null = (_wizardData:any,data:any,wizardId) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/sew-disk.js#L224
  var details:string|null = null;
  
  //var collectionType = wizard.SewDiskTypes["collection"] ? wizard.SewDiskTypes["collection"][0] : {};
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/autostitch-wizard-config.js#L603
  var collectionType:{value:string,text:string}|{value?:undefined,text?:undefined} = wizardId == 'AutoStitch' ? { "text": "Sew File Collection CD", "value": "Sew File Collection CD" } : {}
  const SewDiskMaterials = SewDiskMaterialsPerWizardID[wizardId as keyof typeof SewDiskMaterialsPerWizardID]
  const SewDisk = SewDiskPerWizardID[wizardId as keyof typeof SewDiskMaterialsPerWizardID]

  if (data.SewDiskSelected == 0 || data.SewDiskSelected == "0") {
      details = "No";
  } else if (data.SewDiskType != undefined &&
          (data.StitchType != undefined || SewDiskMaterials == "EDMG") &&
          data.DiskFormat != undefined && data.SewDiskType != collectionType.value) {

      var StitchTypeDescription = data.StitchType
      for (var i = 0; i < SewDisk.length; i++) {
          if (SewDisk[i].StitchType == data.StitchType) {
              StitchTypeDescription = SewDisk[i].StitchTypeDescription
          }
      }

      details = data.SewDiskType + "<br />" +
              //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/sew-disk.js#L44
              //(this.diskItemNumber(wizard, data) != "EDMG") is true when wizard is different from 3DGreek
              //((this.diskItemNumber(wizard, data) != "EDMG") ? StitchTypeDescription + " Stitch <br />" : "") +
              (wizardId != '3DGreek' ? StitchTypeDescription + " Stitch <br />" : "") +
              data.DiskFormat + " Format";
  } else if (data.SewDiskType === collectionType.value) {
      details = collectionType.text as string;
  }

  return details == null ? null : { "name": "Sew File", "detail": details };
}