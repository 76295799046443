  //same as CmeSize, code extracted from
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L106
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L532
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/data/post-process.js#L2
  
  //code goes as follows

//   fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//     "headers": {
//       "accept": "application/json, text/javascript, */*; q=0.01",
//       "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//       "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//     },
//     "body": "QueryJSON=%5B%7B%22type%22%3A%22CME_LIMITS%22%2C%22template%22%3A%5B%22%24%7BStyle%7D%7C%24%7BFont%7D%7C%24%7BConnected%7D%7C%24%7BNonConnected%7D%7C%24%7BOneColor%7D%7C%24%7BTwoColor%7D%7C%24%7BThreeColor%7D%7C%24%7BCMESize%7D%7C%24%7BTail%7D%7C%24%7BMaxChar%7D%22%5D%7D%5D",
//     "method": "POST"
//   }).then(resp => resp.json())
//   .then(jsonResp => {
//   var data=jsonResp[0]
  
//                 var limits = {};

//                 for (var i = 0; i < data.length; i++) {
//                     var bits = data[i].split("|");

//                     var style = bits[0];
//                     var font = bits[1];
//                     var connected = bits[2] == 1;
//                     var nonConnected = bits[3] == 1;
//                     var oneColor = bits[4] == 1;
//                     var twoColor = bits[5] == 1;
//                     var threeColor = bits[6] == 1;
//                     var cmeSize = bits[7];
//                     var tail = bits[8] == 1;
//                     var maxChar = bits[9] * 1;
//                     for (var j = 1; j <= maxChar; j++) {
//                         if (!limits[j])
//                             limits[j] = {};
//                         if (!limits[j][cmeSize])
//                             limits[j][cmeSize] = {};
//                         if (!limits[j][cmeSize][style])
//                             limits[j][cmeSize][style] = {};
//                         if (!limits[j][cmeSize][style][font])
//                             limits[j][cmeSize][style][font] = {
//                                 OneColor: { Connected: false, NonConnected: false, Tail: false },
//                                 TwoColor: { Connected: false, NonConnected: false, Tail: false },
//                                 ThreeColor: { Connected: false, NonConnected: false, Tail: false }
//                             };
//                         if (oneColor) {
//                             limits[j][cmeSize][style][font].OneColor.Connected = limits[j][cmeSize][style][font].OneColor.Connected || connected;
//                             limits[j][cmeSize][style][font].OneColor.NonConnected = limits[j][cmeSize][style][font].OneColor.NonConnected || nonConnected;
//                             limits[j][cmeSize][style][font].OneColor.Tail = limits[j][cmeSize][style][font].OneColor.Tail || tail;
//                         }
//                         if (twoColor) {
//                             limits[j][cmeSize][style][font].TwoColor.Connected = limits[j][cmeSize][style][font].TwoColor.Connected || connected;
//                             limits[j][cmeSize][style][font].TwoColor.NonConnected = limits[j][cmeSize][style][font].TwoColor.NonConnected || nonConnected;
//                             limits[j][cmeSize][style][font].TwoColor.Tail = limits[j][cmeSize][style][font].TwoColor.Tail || tail;
//                         }
//                         if (threeColor) {
//                             limits[j][cmeSize][style][font].ThreeColor.Connected = limits[j][cmeSize][style][font].ThreeColor.Connected || connected;
//                             limits[j][cmeSize][style][font].ThreeColor.NonConnected = limits[j][cmeSize][style][font].ThreeColor.NonConnected || nonConnected;
//                             limits[j][cmeSize][style][font].ThreeColor.Tail = limits[j][cmeSize][style][font].ThreeColor.Tail || tail;
//                         }
//                     }

//                 }

//                 // moved from text.js or it never runs for editing
//                 var maxLengths = {};
//                 var maxMaxLength = 0;
//                 Object.keys(limits).forEach(maxLengthString => {
//                     const value = limits[maxLengthString];
//                     const maxLength = parseInt(maxLengthString, 10);
//                     Object.keys(value).forEach(size => {
//                         if (!maxLengths[size] || maxLength > maxLengths[size])
//                             maxLengths[size] = maxLength;

//                         if (maxLength > maxMaxLength)
//                             maxMaxLength = maxLength;
//                     })
//                 })
//                 maxLengths["other"] = maxMaxLength;
//                 //wizard.MaxLengths = maxLengths;
//                 //wizard.MaxMaxLength = maxMaxLength;

// console.log(limits);
  
//   })

// fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//     "headers": {
//       "accept": "application/json, text/javascript, */*; q=0.01",
//       "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//       "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//     },
//     "body": "QueryJSON=%5B%7B%22type%22%3A%22CME_LAYOUT%22%2C%22template%22%3A%5B%22%24%7BLayout%7D%7C%24%7BLayoutDescription%7D%22%5D%7D%5D",
//     "method": "POST"
//   }).then(resp => resp.json())
//   .then(jsonResp => {
//   var data=jsonResp[0]
  
//     var map = {};
//     for (var i = 0; i < data.length; i++) {
//         var bits = data[i].split("|");
//         map[bits[0]] = bits[1];
//     }
    
//     console.log(map);
  
//   })

import CmeLayoutLimits from './CmeLayoutLimits.json'
import LayoutTitleMap from './CmeLayoutTitleMap.json'

//sizes object is obtained in the same query as description in CmeSize
import sizesPerProductName from './CmeLayoutSizesPerProductName.json'

export const CmeLayout:(wizardData:any)=>{name:string, detail:string} = (wizardData:any) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cme/layout.js#L69
  type CMELimitsColors = {
    OneColor:{Connected?:boolean,NonConnected?:boolean,Tail?:boolean},
    TwoColor:{Connected?:boolean,NonConnected?:boolean,Tail?:boolean},
    ThreeColor:{Connected?:boolean,NonConnected?:boolean,Tail?:boolean},
  }
  const CMELimits:{[maxLength:number]:{[cmeSize:string]:{[style:string]:{[font:string]:CMELimitsColors}}}} = CmeLayoutLimits

  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1067
  
  function GetCurrentLimits():{Connected?:boolean,NonConnected?:boolean} {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1295
    var selectedLayerCount = (!!wizardData.material3 && !!wizardData.color3) ? 3 : (!!wizardData.material2 && !!wizardData.color2) ? 2 : 1;
    function GetEffectiveSizes()
    {
        // default to largest
        var productSizes = sizesPerProductName[wizardData.CMETYPES as keyof typeof sizesPerProductName]
        if(!productSizes) throw new Error(`Missing CmeLayoutSizesPerProductName.json entry for ${wizardData.CMETYPES}`)
        var sizes = productSizes[selectedLayerCount + "" as keyof typeof productSizes];
        var topSize = wizardData.topsize as string|undefined;
        var bottomSize = wizardData.bottomsize as string|undefined;

        // select the next largest stock size based on their custom size
        if (topSize == "other") {
            var other:string[] = wizardData.topsizeother.split('x')
            var h = Number.parseFloat(other[0]);
            var w = Number.parseFloat(other[1]);
            if (!h) h = Number.MAX_VALUE;
            if (!w) w = Number.MAX_VALUE;
            var minHeight = -1;
            topSize = undefined;
            for (var key in sizes) {
                var size = sizes[key as keyof typeof sizes];

                if (h <= size.height && w <= size.width && 
                    (minHeight == -1 || (size.height < minHeight))) {
                    minHeight = size.height;
                    topSize = key;
                }
            }
            if (!h && !w) topSize = "other";
        }

        // select the next largest stock size based on their custom size
        if (bottomSize == "other") {
            var other:string[] = wizardData.bottomsizeother.split('x')
            var h = Number.parseFloat(other[0]);
            var w = Number.parseFloat(other[1]);
            if (!h) h = Number.MAX_VALUE;
            if (!w) w = Number.MAX_VALUE;
            var minHeight = -1;
            bottomSize = undefined;
            for (var key in sizes) {
                var size = sizes[key as keyof typeof sizes];

                if (h <= size.height && w <= size.width && 
                    (minHeight == -1 || (size.height < minHeight))) {
                    minHeight = size.height;
                    bottomSize = key;
                }
            }
            if (!h && !w) bottomSize = "other";
        }

        return { TopSize: topSize, BottomSize: bottomSize };
    }
    var sizes = GetEffectiveSizes();      
    var limits:{Connected?:boolean,NonConnected?:boolean} = {};

    if (!wizardData.blockscript || !wizardData.font)
        return {};

    function mergeLimits(l1:{Connected?:boolean,NonConnected?:boolean}|undefined, l2:{Connected?:boolean,NonConnected?:boolean}|undefined) {
        if (!l1) return l2 as {Connected?:boolean,NonConnected?:boolean};
        if (!l2) return l1; 
        var res = l2;
        for (var k in res) {
            var a = k as keyof typeof res
            res[a] = !!l1[a] && !!l2[a];
        }
        return res;
    }

    var top:Partial<CMELimitsColors> = (!!sizes.TopSize && !!wizardData.toptext) ? CMELimits[wizardData.toptext.length][sizes.TopSize][wizardData.blockscript][wizardData.font] || {} : {};
    var bot:Partial<CMELimitsColors> = (!!sizes.BottomSize && !!wizardData.bottomtext) ? CMELimits[wizardData.bottomtext.length][sizes.BottomSize][wizardData.blockscript][wizardData.font] || {} : {};

    switch (selectedLayerCount) {
        case 1:
            limits = mergeLimits(top.OneColor, bot.OneColor);
            break;
        case 2:
            limits = mergeLimits(top.TwoColor, bot.TwoColor);
            break;
        case 3:
            limits = mergeLimits(top.ThreeColor, bot.ThreeColor);
            break;
    }

    return limits;
  }

  var currentLimits:{Connected?:boolean,NonConnected?:boolean} = GetCurrentLimits() || {};
  var details = [];

  const CmeCapitalStyle = wizardData.layoutconnect == '1' ? "Connected" : "Not-Connected"
  if (!!CmeCapitalStyle && (currentLimits.Connected || currentLimits.NonConnected))
      details.push((wizardData.blockscript == "Block" ? 'Background Connect: ' : 'Foreground Cap Connect: ') + (!!CmeCapitalStyle ? CmeCapitalStyle : ""));

  if (!!wizardData.layoutbk)
      details.push('Background Style: ' + (!!wizardData.layoutbk ? wizardData.layoutbk : ""));

  const CmeTopWordLayoutTitle = LayoutTitleMap[wizardData.toplayout as keyof typeof LayoutTitleMap]
  details.push('Text Layout: ' + (!!wizardData.toplayout ? CmeTopWordLayoutTitle : ""));
  if (!!wizardData.bottomtext) {
      const CmeBottomWordLayoutTitle = LayoutTitleMap[wizardData.bottomlayout as keyof typeof LayoutTitleMap]
      details.push('2nd Line Text Layout: ' + (!!wizardData.bottomlayout ? CmeBottomWordLayoutTitle : ""));
  }
  
  return {
    'name': 'Layout',
    'detail': details.join("<br />")
  };

}
