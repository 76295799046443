import { GetSizeName } from "./GetSizeName";

//font and size rarely changes, here's retrieved: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/3dgreek-wizard-config.js#L47
//and here's processed: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/data/post-process.js#L283

//code goes like this

// const GetSizeName = function (size) {
//   var sizeMap = {
//     "0.625": "5/8\"",
//     "0.75": "3/4\"",
//     "1.125": "1 1/8\"",
//     "1.25": "1 1/4\"",
//     "1.5": "1 1/2\"",
//     "2.5": "2 1/2\"",
//     "2.75": "2 3/4\"",
//     "3.5": "3 1/2\"",
//     "6.75": "6 3/4\""
//   };

//   if (sizeMap[size]) {
//     return sizeMap[size];
//   } else {
//     return size + "\"";
//   }
// };


// async function main() {
//   const result = {}
//   const query = encodeURI(`[{"type":"WIZARDS","template":{"\${WizardID}":{"\${Font}":{"\${Size}":{"\${Material}":["\${LowerCase}"]}}}}}]`)
//   const resp = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//     "headers": {
//       "accept": "application/json, text/javascript, */*; q=0.01",
//       "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//       "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//     },
//     "body": "QueryJSON=" + query,
//     "method": "POST"
//   })
//   const jsonResp = await resp.json()
//   var dataPerWizard=jsonResp[0]
//   delete dataPerWizard[""]
//   for(var wizardKey in dataPerWizard) {
//     var data = dataPerWizard[wizardKey]
//         var fonts = [];
//     for (var fontCode in data) {
//         var font_materials = {};
//         var font = {};
//         //font.name = wizard.FontMap[fontCode];
//         font.value = fontCode;

//         font.Sizes = [];
//         for (var sizeCode in data[fontCode]) {
//             var size = {};
//             size.value = sizeCode;
//             size.name = GetSizeName(sizeCode);
//             var materials = [];
            
//             for (var key in data[fontCode][sizeCode])
//             {
//                 var material = key;
//                 //materials[materials.length] = wizard.MaterialMap[material];
//                 //font_materials[material] = wizard.MaterialMap[material];
//                 for (var i = 0; i < data[fontCode][sizeCode][key].length; i++)
//                 {
//                     font.LowerCase |= data[fontCode][sizeCode][key][i];
//                 }
//             }
//             //materials = materials.sort()
//             size.Materials = materials.join(", ");
//             font.Sizes[font.Sizes.length] = size;
//             //if (StahlsWizards.Static["FontData"][fontCode]) {
//             //    StahlsWizards.Static["FontData"][fontCode].LowerCase = font.LowerCase;
//             //    if (typeof font.LowerCase == "undefined" || font.LowerCase == null) StahlsWizards.Static["FontData"][fontCode].LowerCase = 0
//             //} else {
//             //    if (typeof(console.log) != "undefined") {
//             //        console.warn("WARNING: No Font Data in StahlsWizards.Static.FontData for FontCode=" + fontCode);
//             //    }
//             //}
//         }
//         font.Sizes.sort(function (a,b) { if (a.value - b.value < 0) return -1; return 1})

//         //var a = [];
//         //for (var material in font_materials) {
//         //    a[a.length] = font_materials[material];
//         //}

//         fonts[fonts.length] = font;
//     }
    
//     result[wizardKey] = fonts

//   }
  
//   console.log(result)
// }
// main().catch(err => console.error(err))

//here's the result

import FontAndSizesByProductName from './GenericFontAndSizesByProductName.json'
import FontMapByProduct from './GenericFontMapByProduct.json'

export const TextFontSizeStep:(wizardData:any,data:any,wizardId:string)=>{name:string, detail:string}|null = (_wizardData:any,data:any,wizardId) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/text-font-size.js#L220
  const FontAndSize = FontAndSizesByProductName[wizardId as keyof typeof FontAndSizesByProductName]
  const FontMap = FontMapByProduct[wizardId as keyof typeof FontAndSizesByProductName]
  if (typeof FontAndSize == "undefined") {
      return { "name": "Font & Size", "detail": FontMap[data.FontCode as keyof typeof FontMap] + " " + GetSizeName(data.FontSize) }
  }
  for (var i = 0; i < FontAndSize.length; i++) {
      var font = FontAndSize[i];
      if (font.value == data.FontCode) {
          for (var j = 0; j < font.Sizes.length; j++) {
              if (font.Sizes[j].value == data.FontSize) {
                  return { "name": "Font & Size", "detail": FontMap[data.FontCode as keyof typeof FontMap] + " " + GetSizeName(data.FontSize) }
              }
          }
      }
  }
  return null
}