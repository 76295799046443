import { useEffect, useState } from 'react'

import UCMaxItems from '../design-detail/comps/uc-max-items'
import Dropdown from '../../components/dropdown/dropdownMenu'
import ArtInformationAndQRCode from '../design-detail/comps/artInformationAndQRCode'
import { IProdOrderLineItem } from '../../types/iprod-order'
import { getBackendApiUrl } from "../../config"
import { useSnapshot } from 'valtio'
import appModel from '../../models/app-model'
import { queryUCMItems } from '../../models/ucm-queries'
import { UcmLineItem } from './ucm-line-item'
import { UCMHeader } from './header'
import { UcmHeader } from './ucm-header'
import CircularProgressBar from '../../components/progressBar/progressBar'

// Styles
import styles from '../design-detail/art-details.module.css' // index.tsx
import artStyles from '../art-dashboard/art.module.css'
import { addAuthorizationHeader } from '../../api/network'

const flex = "flex-wrap gap-6 px-4 sm:px-6 lg:px-8"

export function UCMProductionPage() {
  const appSnap = useSnapshot(appModel)
  const prodLineItems = appSnap.prodLineItems as IProdOrderLineItem[]
  const url = new URL(window.location.href)
  const docNo = url.searchParams.get('DocNo')
  const docLineNo = url.searchParams.get('DocLineNo')
  const [ucmHeader, setUmcHeader] = useState<UcmHeader>()
  const [ucmLineItems, setUcmLineItems] = useState<UcmLineItem[]>([])
  const [batchingAllUCMaxItems, setBatchingAllUCMaxItems] = useState<boolean>(false)
  const [batchingSelectedUCMaxItems, setBatchingSelectedUCMaxItems] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0);
  const [_, setShowCheck] = useState<boolean>(false);

  useEffect(() => {
    //convert prodLineItems to UcmLineItems
    queryUCMItems(prodLineItems)
      .then((ucmLineItems) => {
        const totalQuantities = ucmLineItems.reduce((total, prodOrder) => total + prodOrder.quantity, 0);
        const processed = ucmLineItems.map((
          //x
        ) => {
          //asierad: ignore processed flag for the time being
          //const productionArtwork = x.productionArtwork
          return false//!!(productionArtwork && productionArtwork[0] && productionArtwork[0].DTFProcessed)
        })
        const totalRunTime = ucmLineItems.reduce((total, prodOrder) => total + prodOrder.size.width * prodOrder.size.height * prodOrder.quantity, 0) * 0.0094

        setUcmLineItems(ucmLineItems.map((x, i) => (
          {
            docId: x.docId,
            quantity: x.quantity,
            printQuantity: x.printQuantity,
            processed: processed[i],
            selected: false,
            size: x.size,
            productionArtwork: x.productionArtwork,
            productionArtworkPreview: x.productionArtworkPreview,
            originalArtwork: x.originalArtwork,

          })))

        setUmcHeader({
          docNo: docNo === null ? 'UNKNOWN' : docNo,
          lineNo: docLineNo == null ? 'UNKNOWN' : docLineNo,
          salesOrderNo: prodLineItems[0]?.salesorderno_vel,
          salesOrderLineNo: prodLineItems[0]?.saleslineno_vel.toString(),
          woEncodedMessage: `%P%${docNo} ${docLineNo}`,
          warehouseShipmentNo: prodLineItems[0]?.whseshipno,
          whEncodeMessage: `%WS%${prodLineItems[0]?.whseshipno}`,
          customerId: prodLineItems[0]?.customerNumber ?? "--",
          customerName: prodLineItems[0]?.customerName ?? "--",
          shippingAgent: prodLineItems.filter(x => !!x.shippingAgentServiceCode)[0]?.shippingAgentName ?? "--",
          shippingAgentService: prodLineItems.filter(x => !!x.shippingAgentServiceCode)[0]?.shippingAgentServiceDescription ?? "--",
          shipDate: prodLineItems[0]?.duedate,
          itemNo: prodLineItems[0]?.itemno_,
          totalQuantities: totalQuantities,
          runTime: totalRunTime,
          lineItemsCount: prodLineItems[0]?.lineItemsCount ?? 0,
          lineItemsShowing: ucmLineItems.length
        })
      })
      .catch((err) => {
        console.error('Error fetching documents for Multi item View:', err);
      })
  }, [docNo, docLineNo, prodLineItems])

  const handleSelectItem = (item: UcmLineItem) => {
    const newItems = ucmLineItems.map(x => {
      if (x.docId === item.docId) {
        x.selected = !x.selected
      }
      return x
    })
    setUcmLineItems(newItems)
  }

  const handlePrintQuantityChnage = (item: UcmLineItem, newQuantity: number) => {
    const newItems = ucmLineItems.map(x => {
      if (x.docId === item.docId) {
        x.printQuantity = newQuantity
      }
      return x
    })
    setUcmLineItems(newItems)
  }

  const handlePrint = () => {
    window.print();
  };

  const processAll = async () => {
    const headers = addAuthorizationHeader({
      'Content-Type': 'application/json'
    });

    const processPayload = { docIds: ucmLineItems.map(x => x.docId), orderNumber: docNo }
    let intervalId: NodeJS.Timeout | null = null;

    try {
      intervalId = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress < 35) {
            return prevProgress + 7;
          }
          clearInterval(intervalId!);
          return prevProgress;
        });
      }, 1000);

      const processResp = await fetch(`${getBackendApiUrl()}/ProcessUCMax`, {
        method: 'POST',
        headers,
        body: JSON.stringify(processPayload)
      });

      if (!processResp.ok) throw new Error("Error processing UCMax " + await processResp.text())

      const processRespObj = await processResp.json() as { processed: { id: string }[], errors: string[] }
      const processedDocs = new Set((processRespObj.processed).map(x => x.id as string))
      ucmLineItems.forEach(x => {
        if (processedDocs.has(x.docId)) {
          x.processed = true
        }
      })

      if (processRespObj.errors && (processRespObj.errors).some(x => typeof x != "undefined" && x != null)) {
        throw new Error("Some items weren't processed correctly: " + JSON.stringify(processRespObj))
      }

      setUcmLineItems([...ucmLineItems])
    } finally {
      if (intervalId) clearInterval(intervalId);
      setProgress(35);
    }
  }

  const batchAll = async () => {
    const headers = addAuthorizationHeader({
      'Content-Type': 'application/json'
    });

    const processPayload = {
      docs: ucmLineItems.map(x => ({ id: x.docId, quantity: x.printQuantity })),
      orderNo: prodLineItems[0]?.salesorderno_vel
    };

    const processResp = await fetch(`${getBackendApiUrl()}/BatchUCMax`, {
      method: 'POST',
      headers,
      body: JSON.stringify(processPayload)
    });

    if (!processResp.ok) throw new Error("Error batching UCMax " + await processResp.text())

    const reader = processResp.body?.getReader()
    const contentLength = +processResp.headers.get('Content-Length')!;
    let receivedLength = 0;
    const chunks = [];

    while (true) {
      const { done, value } = await reader!.read();
      if (done) break;
      chunks.push(value);
      receivedLength += value.length;
      setProgress(35 + (receivedLength / contentLength) * 65);
    }

    const blob = new Blob(chunks);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;

    if (processResp.headers.has("Content-Disposition")) {
      const disp = processResp.headers.get("Content-Disposition") as string;
      const dispParts = disp.split(';')
      if (dispParts.length > 1) {
        const fileToken = dispParts[1]
        const fileTokenParts = fileToken.split('=')
        const fileName = fileTokenParts.length > 1 ? fileTokenParts[1].replace(/"/g, '') : undefined;
        if (fileName) a.download = fileName
      }
    }

    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }

  const processSelected = async () => {
    const headers = addAuthorizationHeader({
      'Content-Type': 'application/json'
    });

    const processPayloadDocs = ucmLineItems.filter(x => x.selected).map(x => x.docId);
    const processPayload = { docIds: processPayloadDocs, orderNumber: docNo }
    let intervalId: NodeJS.Timeout | null = null;

    try {
      intervalId = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress < 35) {
            return prevProgress + 7;
          }
          clearInterval(intervalId!);
          return prevProgress;
        });
      }, 1000);

      const processResp = await fetch(`${getBackendApiUrl()}/ProcessUCMax`, {
        method: 'POST',
        headers,
        body: JSON.stringify(processPayload)
      });

      if (!processResp.ok) throw new Error("Error processing UCMax " + await processResp.text())

      const processRespObj = await processResp.json() as { processed: { id: string }[], errors: string[] }
      const processedDocs = new Set((processRespObj.processed).map(x => x.id as string))
      ucmLineItems.forEach(x => {
        if (processedDocs.has(x.docId)) {
          x.processed = true
        }
      })

      if (processRespObj.errors && (processRespObj.errors).some(x => typeof x != "undefined" && x != null)) {
        throw new Error("Some items weren't processed correctly: " + JSON.stringify(processRespObj))
      }

      setUcmLineItems([...ucmLineItems])
    } finally {
      if (intervalId) clearInterval(intervalId);
      setProgress(35);
    }
  }

  const batchSelected = async () => {
    const headers = addAuthorizationHeader({
      'Content-Type': 'application/json'
    });

    const processPayload = {
      docs: ucmLineItems.filter(x => x.selected).map(x => ({
        id: x.docId,
        quantity: x.printQuantity
      })),
      orderNo: prodLineItems[0]?.salesorderno_vel
    };

    const processResp = await fetch(`${getBackendApiUrl()}/BatchUCMax`, {
      method: 'POST',
      headers,
      body: JSON.stringify(processPayload)
    });

    if (!processResp.ok) throw new Error("Error batching UCMax " + await processResp.text())

    const reader = processResp.body?.getReader();
    const contentLength = +processResp.headers.get('Content-Length')!;
    let receivedLength = 0;
    const chunks = [];

    while (true) {
      const { done, value } = await reader!.read();
      if (done) break;
      chunks.push(value);
      receivedLength += value.length;
      setProgress(35 + (receivedLength / contentLength) * 65);
    }

    const blob = new Blob(chunks);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;

    if (processResp.headers.has("Content-Disposition")) {
      const disp = processResp.headers.get("Content-Disposition") as string;
      const dispParts = disp.split(';');
      if (dispParts.length > 1) {
        const fileToken = dispParts[1];
        const fileTokenParts = fileToken.split('=');
        const fileName = fileTokenParts.length > 1 ? fileTokenParts[1].replace(/"/g, '') : undefined;
        if (fileName) a.download = fileName;
      }
    }

    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }

  const handleBatchAll = async () => {
    setBatchingAllUCMaxItems(true);
    setProgress(0);
    try {
      if (ucmLineItems.some(x => !x.processed)) await processAll();
      await batchAll();

      setShowCheck(true);
      setTimeout(() => {
        setShowCheck(false);
        setBatchingAllUCMaxItems(false);
      }, 2000);
    } catch (err) {
      alert("Failed to batch all UCMax items");
      console.error(err);
      setBatchingAllUCMaxItems(false);
    }
  };

  const handleBatchSelected = async () => {
    setBatchingSelectedUCMaxItems(true);
    setProgress(0);
    try {
      const processedSelected = ucmLineItems.filter(x => x.selected).map(x => x.processed);
      if (processedSelected.some(x => !x)) await processSelected();
      await batchSelected();

      setShowCheck(true);
      setTimeout(() => {
        setShowCheck(false);
        setBatchingSelectedUCMaxItems(false);
      }, 2000);
    } catch (err) {
      alert("Failed to batch selected UCMax items");
      console.error(err);
      setBatchingSelectedUCMaxItems(false);
    }
  };

  const MainBar = () => {
    const hasUCMaxItems = ucmLineItems?.length > 0
    const hasUCMaxSelectedItems = ucmLineItems.some(x => x.selected)
    const processing = batchingAllUCMaxItems || batchingSelectedUCMaxItems;
    const disableAllFilesButtons = !hasUCMaxItems || processing;
    const disableSelectedFilesButtons = !hasUCMaxSelectedItems || processing;
    const ucMaxButtons = <>
      {/* Batch options */}
      <Dropdown
        buttonText="Process"
        setOverflow={true}
        menuItems={[
          {
            text: batchingAllUCMaxItems ? "Process Selected" : "Process Selected Files",
            onItemClicked: () => disableSelectedFilesButtons ? void 0 : processSelected(),
            disabled: disableSelectedFilesButtons
          },
          {
            text: batchingAllUCMaxItems ? "Process All" : "Process All Files",
            onItemClicked: () => disableAllFilesButtons ? void 0 : processAll(),
            disabled: disableAllFilesButtons,
            style: { background: '#F9FAFB' }
          }
        ]}
      />

      <Dropdown
        buttonText="Download"
        setOverflow={true}
        menuItems={[
          {
            text: batchingAllUCMaxItems ? "Download Selected" : "Download Selected Files",
            onItemClicked: () => disableSelectedFilesButtons ? void 0 : handleBatchSelected(),
            disabled: disableSelectedFilesButtons
          },
          {
            text: batchingAllUCMaxItems ? "Download All" : "Download All Files",
            onItemClicked: () => disableAllFilesButtons ? void 0 : handleBatchAll(),
            disabled: disableAllFilesButtons,
            style: { background: '#F9FAFB' }
          }
        ]}
      />
    </>

    return (
      <div className={[flex, styles.mainBar].join(' ')}>
        <h3 className="text-base font-light leading-6 text-white uppercase">
          <ArtInformationAndQRCode
            LineNo={docLineNo}
            DocNo={docNo}
            isProductionMode={true}
          />
        </h3>
        <div className={styles.navBarButtonsWrapper}>
          {(batchingAllUCMaxItems || batchingSelectedUCMaxItems) &&
            <div className={styles.progressWrapper}>
              <CircularProgressBar progress={progress} />
            </div>
          }
          {ucMaxButtons}
          <button
            className={`${artStyles.orangeBtn} ${styles.printBtn} non-printable`}
            onClick={handlePrint}>
            <svg
              width={"22px"}
              height={"22px"}
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
            </svg>
          </button>
        </div>
      </div>
    );
  };

  return <div className={styles.multiWrapper}>
    <div className={styles.nonPrintable}>
      <MainBar />
    </div>
    {ucmHeader === undefined ? <></> : <UCMHeader header={ucmHeader} />}
    <UCMaxItems
      lineItems={ucmLineItems}
      docNo={docNo ?? ''}
      lineNo={docLineNo ?? ''}
      onSelectItem={handleSelectItem}
      onPrintQuantityChnage={handlePrintQuantityChnage}
    />
  </div>
}