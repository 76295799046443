import { proxy } from 'valtio'
import { ITEMS_PER_PAGE } from "../config"
import { HistoryDesign, DesignDoc, WizardDocConversation } from '../types/api-types'
import { AppPages } from '../app-pages'
import { IProdOrderLineItem } from '../types/iprod-order'
import { ArtStates } from '../types/art-status'


export const appModel = proxy({

  //** App Model
  page: AppPages.Loading,
  isPageLoading: true,

  // URL Params
  lineNo: null as string | null,
  orderNo: null as string | null,
  customerId: null as string | null,
  adminId: null as string | null,
  artId: null as string | null,


  //** Design Details Model 
  activeDesignDoc: undefined as DesignDoc | undefined,
  activeConversation: undefined as WizardDocConversation[] | undefined,
  activeHistoryDoc: undefined as HistoryDesign | undefined,


  //**  Dashboard  Model
  artStatusFilter: ArtStates.ALL,
  statuses: [] as ArtStates[],
  pageIndex: 0,
  pageSize: ITEMS_PER_PAGE,
  searchText: '',

  designDocs: [] as DesignDoc[],
  historyDocs: [] as HistoryDesign[],
  totalDesigns: 0,

  //** UCM Production Model
  prodLineItems: [] as IProdOrderLineItem[],


})





export default appModel



