

//We don't know all art status, but we know these
export enum InternalArtStates {
  OPEN = 'OPEN',
  PEND_C = 'PEND_C',
  PEND_S_ART = 'PEND_S_ART',
  PEND_S_CSR = 'PEND_S_CSR',
  PEND_S_OUT = 'PEND_S_OUT',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  REVIEW = 'REVIEW'
}

export enum ArtStates {
  ALL = 'ALL',
  READY_TO_ORDER = 'READY_TO_ORDER',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  IN_PROGRESS = 'IN_PROGRESS',
  ARCHIVED = 'ARCHIVED',
  HISTORY = 'HISTORY'
}

export const InternalArtStatesDescriptions = {
  [InternalArtStates.OPEN]: { description: "Open" },
  [InternalArtStates.PEND_C]: { description: "Waiting on Customer" },
  [InternalArtStates.PEND_S_ART]: { description: "Waiting on Art Service" },
  [InternalArtStates.PEND_S_CSR]: { description: "Waiting on Customer Service" },
  [InternalArtStates.PEND_S_OUT]: { description: "Waiting on Digitizing Service" },
  [InternalArtStates.APPROVED]: { description: "Approved" },
  [InternalArtStates.CANCELED]: { description: "Canceled" },
  [InternalArtStates.REVIEW]: { description: "Review" },
};

export const ArtStatesDescriptions = {
  [ArtStates.ALL]: { description: "All" },
  [ArtStates.READY_TO_ORDER]: { description: "Ready to order" },
  [ArtStates.REQUIRES_ACTION]: { description: "Requires customer action" },
  [ArtStates.IN_PROGRESS]: { description: "In Process" },
  [ArtStates.HISTORY]: { description: "History" },
  [ArtStates.ARCHIVED]: { description: "Archived" }
};

export type ArtworkFolder = 'originalArtwork' | 'productionArtwork' | 'productionArtworkPreview';

export function toInternalArtState(artState: ArtStates) {

  switch (artState) {
    case ArtStates.READY_TO_ORDER:
      return [InternalArtStates.APPROVED,InternalArtStates.REVIEW]
    case ArtStates.IN_PROGRESS:
      return [InternalArtStates.OPEN,
      InternalArtStates.PEND_S_ART,
      InternalArtStates.PEND_S_CSR,
      InternalArtStates.PEND_S_OUT]
    case ArtStates.REQUIRES_ACTION:
      return [InternalArtStates.PEND_C]
    default: // ArtStateFilter.ALl
      return [InternalArtStates.OPEN,
      InternalArtStates.PEND_C,
      InternalArtStates.PEND_S_ART,
      InternalArtStates.PEND_S_CSR,
      InternalArtStates.PEND_S_OUT,
      InternalArtStates.APPROVED,
      InternalArtStates.REVIEW,
      InternalArtStates.CANCELED]

  }
}

export function toArtState(artState: InternalArtStates) {

  switch (artState) {
    case InternalArtStates.APPROVED:
    case InternalArtStates.REVIEW:
      return ArtStates.READY_TO_ORDER
    case InternalArtStates.OPEN:
    case InternalArtStates.PEND_S_ART:
    case InternalArtStates.PEND_S_CSR:
    case InternalArtStates.PEND_S_OUT:
      return ArtStates.IN_PROGRESS
    case InternalArtStates.PEND_C:
      return ArtStates.REQUIRES_ACTION
    default: // ArtStateFilter.ALl
      return ArtStates.ALL
  }
}







