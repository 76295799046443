
import React, { ReactNode, useEffect, useState } from 'react';
import InitFailPage from '../../pages/init-fail';
import { AppError } from '../../types/error-types';

interface ErrorBoundaryProps {
  children: ReactNode;
}

const getErrorMessage = (error: AppError): string => {
  if ('response' in error && error.response?.data?.error) {
    return error.response.data.error;
  }
  return error.message;
};


const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [error, setError] = useState<AppError | null>(null);

  useEffect(() => {
    const errorHandler = (ev: ErrorEvent) => {
      setError(ev.error);
    };

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  useEffect(() => {
    const unhandledRejectionHandler = (ev: PromiseRejectionEvent) => {
      setError(ev.reason);
    };

    window.addEventListener('unhandledrejection', unhandledRejectionHandler);

    return () => {
      window.removeEventListener('unhandledrejection', unhandledRejectionHandler);
    };
  }, []);

  if (error) {
    const errorMessage = getErrorMessage(error);
    return <InitFailPage errorMsg={errorMessage} />;
  }

  return <>{children}</>;
};

export default ErrorBoundary;

