import axios, { AxiosRequestConfig } from 'axios';

export function objectToQueryString(params: Record<string, string | number | boolean>): string {
  const queryParams = [];

  for (const key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      if (Array.isArray(value)) {
        // If the value is an array, we join its elements with commas
        queryParams.push(`${encodeURIComponent(key)}=${value.map(v => encodeURIComponent(v)).join(',')}`);
      } else if (typeof value == 'object') {
        queryParams.push(`${encodeURIComponent(key)}=${JSON.stringify(value)}`);
      } else {
        // For other types of values, we simply encode them
        queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  }

  return queryParams.join('&');
}

type Params = {
  [key: string]: string;
};

export function appendParamsToCurrentUrl(params: Params): void {
  const queryString = objectToQueryString(params);
  const baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
  const newUrl = `${baseUrl}?${queryString}`;
  history.pushState({ path: newUrl }, '', newUrl);
}

export interface IHttpClientRequestParameters<T> {
  endPoint?: string
  headers?: Record<string, string>
  resource: string
  payload?: T
}

export function getCustomerToken(): string {
  const params = new URLSearchParams(window.location.search);
  const key = Array.from(params.keys()).find(x => x.toLowerCase()=='customertoken') ?? 'customerToken';
  return params.get(key) ?? '';
}

export function getAdminToken(): string {
  const params = new URLSearchParams(window.location.search);
  const key = Array.from(params.keys()).find(x => x.toLowerCase()=='admintoken') ?? 'adminToken';
  return params.get(key) ?? '';
}

export function getAdminId(): string {
  const params = new URLSearchParams(window.location.search);
  const key = Array.from(params.keys()).find(x => x.toLowerCase()=='adminid') ?? 'AdminId';
  return params.get(key) ?? '';
}

const customerToken = getCustomerToken()
const adminToken = getAdminToken()

export function addAuthorizationHeader(headers: Record<string, string> = {}): Record<string, string> {
  if (adminToken || customerToken) {
    headers['Authorization'] = `Bearer ${adminToken || customerToken}`;
  }
  return headers;
}

export class HttpClient {
  public _url: string;

  constructor(url: string) {
    this._url = url;
  }

  public encodeGetParams = (p: { [key: string]: string | number | boolean }): string =>
    Object.entries(p)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
      .join('&');

  public get<T, U>(parameters: IHttpClientRequestParameters<T>): Promise<U> {
    return new Promise((resolve, reject) => {
      const { endPoint, resource: method, payload, headers } = parameters;

      const options: AxiosRequestConfig = {
        headers: addAuthorizationHeader(headers)
      };

      let url = buildUrl(this._url, endPoint || "", method);
      if (payload && Object.keys(payload as Record<string, string>).length > 0) {
        url += '?' + objectToQueryString(payload as Record<string, string>);
      }

      axios
        .get(url, options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }

  public patch<T>(parameters: IHttpClientRequestParameters<T>) {
    return new Promise((resolve, reject) => {
      const { endPoint, resource: method, payload, headers } = parameters;

      const options: AxiosRequestConfig = {
        headers: addAuthorizationHeader(headers)
      };

      const url = buildUrl(this._url, endPoint || "", method);

      axios
        .patch(url, payload, options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }

  public post<T, U>(parameters: IHttpClientRequestParameters<T>): Promise<U> {
    return new Promise((resolve, reject) => {
      const { endPoint, resource: method, payload, headers } = parameters;

      const options: AxiosRequestConfig = {
        headers: addAuthorizationHeader(headers)
      };

      const url = buildUrl(this._url, endPoint || "", method);

      axios
        .post(url, payload, options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }
}

function buildUrl(base: string, endPoint: string, method: string): string {
  const url = [base];

  if (endPoint) {
    url.push(endPoint);
  }

  url.push(method);

  return url.join('/');
}
