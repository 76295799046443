export const CmeSplit:(wizardData:any)=>{name:string, detail:string} = (wizardData:any) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cme/split.js#L129
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1106
  var between = wizardData.ssplit == "Zipper" ? "seams" : "buttons";
  var optionsText = { 
      "ButtonPosition": { "Men": "Buttons on left", "Women" : "Buttons on right" },
      "ButtonDistance": "Distance between " + between + " is ${value}&quot;",
      "DropTail": "Drop tail below 3rd button: ${value}",
      "Distort": "Distort design between 2nd & 3rd Button: ${value}",
      "SeamDistance": "Distance between seams is ${value}&quot;",
      "SplitPosition": "Split Front position: ${value}",
      "BottomSplitPosition": "Bottom text split front position: ${value}"
  };

  var details:string[] = [];

  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cme/split.js#L29
  const SplitStyles = {
      "No": { name: "No Split Front", "options": [] as string[] },
      "Between": { name: "Between Letter", "options": ["SplitPosition", "BottomSplitPosition"] },
      "Overlap": { name: "Overlap", "options": ["ButtonPosition"] },
      "Custom": { name:"Custom Overlap", "options": ["ButtonPosition", "ButtonDistance", "DropTail", "Distort"] },
      "Extension": { name:"Between Letters", "options": ["SplitPosition", "BottomSplitPosition", "ButtonPosition", "ButtonDistance", "DropTail", "Distort"] },
      "Zipper": { name:"Zipper", "options": ["SeamDistance"] }
  }

  const OptionsDependency = {
      "BottomSplitPosition": "BottomSize"
  }

  const optionsDependencyWizardDataMap = {
      "BottomSize": "bottomsize"
  }

  const splitStylesOptionsWizardDataMap = {
     "SplitPosition": "splitp",
     "BottomSplitPosition": "bottomsplitp",
     "ButtonPosition": "mw",
     "ButtonDistance": "dbuttons4custom",
     "DropTail": "oktodrop4custom",
     "Distort": "okdistort4custom",
     "SeamDistance": "dbuttons4zipper"
  }

  if (wizardData.ssplit) {
      details = [SplitStyles[wizardData.ssplit as keyof typeof SplitStyles].name];
      SplitStyles[wizardData.ssplit as keyof typeof SplitStyles].options.forEach(option => {
          var depends = OptionsDependency[option as keyof typeof OptionsDependency];
          var required = !depends || !!wizardData[optionsDependencyWizardDataMap[depends as keyof typeof optionsDependencyWizardDataMap]];
          if (!required) return;

          var dataValue = wizardData[splitStylesOptionsWizardDataMap[option as keyof typeof splitStylesOptionsWizardDataMap]];
          if (dataValue) {
              const actualOptionsText = optionsText[option as keyof typeof optionsText]
              if (typeof actualOptionsText == "object") {
                  if (actualOptionsText[dataValue as keyof typeof actualOptionsText]) details.push(actualOptionsText[dataValue as keyof typeof actualOptionsText].replace("${value}", dataValue));
              } else {
                  if (!!actualOptionsText) {
                      details.push(actualOptionsText.replace("${value}", dataValue));
                  }
              }
          }
      })
  } else {
      details.push(SplitStyles["No"].name);
  }

  return {'name': "Split Front options", 'detail': details.join("<br />")}
}
