import React from 'react';
import { IWizardData } from '../../../types/iwizard-data';
import { InfoProps } from './info';
import { ArrayValue, BooleanValue, ObjectValue, StringValue } from '../../../components/cardDetails/cardValues';

// Styles
import styles from './../art-details.module.css'; // index.tsx

interface PrintArtDetailsProps {
  title: string;
  properties: { key: keyof InfoProps; label: string }[];
  wizardDocData: IWizardData;
}

const PrintArtDetails: React.FC<PrintArtDetailsProps> = ({ title, properties, wizardDocData }) => {
  const renderProperty = (property: { key: keyof InfoProps; label: string }, index: number) => {
    const value = wizardDocData[property.key as keyof typeof wizardDocData];
    const displayValue = getDisplayValue(value);

    if (!displayValue) {
      return null;
    }

    return (
      <div className={styles.artDetails} key={index}>
        <span className={styles.name}>{property.label}:</span>
        <span className={styles.value}>{displayValue}</span>
      </div>
    );
  };

  const getDisplayValue = (value: any) => {
    if (value === null || value === undefined || value === '' || value === ' ') return null;
    if (typeof value === 'boolean') return <BooleanValue value={value} />;
    if (typeof value === 'string' && value.trim() !== '') return <StringValue value={value} />;
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return value.length > 0 ? <ArrayValue value={value} /> : null;
      }
      return Object.keys(value).length > 0 ? <ObjectValue value={value} /> : null;
    }
    return <span>{String(value)}</span>;
  };

  //get the count of the visible properties
  const count = properties.reduce((acc, curr) => {
    if (getDisplayValue(wizardDocData[curr.key as keyof typeof wizardDocData]))
      return acc + 1
    else
      return acc
  }, 0)

  if (count === 0)
    return null
  else
    return (
      <div>
        <h3 className={styles.title}>{title}</h3>
        {properties?.map((property, index) => renderProperty(property, index))}
      </div>
    );
};

export default PrintArtDetails;
