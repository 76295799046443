import React from 'react';
import QRCodeComp from './qr-code'; // Assuming this is the path to your DataMatrixComponent
import { useMedia } from '../../../utils/match-media';

interface ArtInformationAndQRCodeProps {
  DocNo: string | null;
  LineNo: string | null;
  isProductionMode?: boolean;
}
function getTitle(isProductionMode?: boolean) {
  if(isProductionMode) {
    return 'PRODUCTION MODE'
  }else{
    return 'UNKOWN MODE'
  }
}

const ArtInformationAndQRCode: React.FC<ArtInformationAndQRCodeProps> = ({ DocNo, LineNo, isProductionMode }) => {
  const matchMedia = useMedia("(max-width: 640px)");
  
  const flexContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const artInfoStyle: React.CSSProperties = {
    marginRight: '20px'
  };

  return (
    <div style={flexContainerStyle}>
      <div style={artInfoStyle}>
        {!matchMedia && <h1>{getTitle(isProductionMode)}</h1>}
      </div>
      {(DocNo && LineNo && !isProductionMode) && <QRCodeComp DocNo={DocNo} LineNo={LineNo} />}
    </div>
  );
}

export default ArtInformationAndQRCode;