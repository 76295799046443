//same as CmeSize, code extracted from
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/wizards.js#L1599
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/wizards.js#L1644
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L22
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/data/post-process.js#L2
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1579
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1548
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/data/post-process.js#L1331
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L302
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/data/post-process.js#L406

//code goes as follows

// fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//   "headers": {
//     "accept": "application/json, text/javascript, */*; q=0.01",
//     "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//     "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//   },
//   "body": "QueryJSON=%5B%7B%22type%22%3A%22MATERIAL_COLORS%22%2C%22template%22%3A%5B%22%24%7BMaterial%7D%7C%24%7BColor%7D%7C%24%7BHex%7D%22%5D%2C%22filter%22%3A%5B%22!%3D%22%2C%20%22%24%7BHex%7D%22%2C%20%22%22%5D%7D%5D",
//   "method": "POST"
// }).then(resp => resp.json())
//   .then(jsonResp => {
//   var data=jsonResp[0]
//   var res = {};
//   for (var i = 0; i < data.length; i++) {
//     var s = data[i].split("|");
//     res[s[0] + s[1]] = s[2];
//   }

//   console.log(res)

// })

// fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//   "headers": {
//     "accept": "application/json, text/javascript, */*; q=0.01",
//     "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//     "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//   },
//   "body": "QueryJSON=%5B%7B%22type%22%3A%22MATERIAL%22%2C%22template%22%3A%5B%22%24%7BMaterial%7D%7C%24%7BMaterialDescription%7D%7C%24%7BLongDescription%7D%7C%24%7BImageURL%7D%22%5D%7D%5D",
//   "method": "POST"
// }).then(resp => resp.json())
//   .then(jsonResp => {
//   var data=jsonResp[0]

//           var map = {};
//           for (var i = 0; i < data.length; i++) {
//               var bits = data[i].split("|");
//               map[bits[0]] = bits[1];
//           }
//   console.log(map)

// })

// fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//   "headers": {
//     "accept": "application/json, text/javascript, */*; q=0.01",
//     "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//     "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//   },
//   "body": "QueryJSON=%5B%7B%22type%22%3A%22COLOR%22%2C%22template%22%3A%5B%22%24%7BColor%7D%7C%24%7BColorDescription%7D%22%5D%7D%5D",
//   "method": "POST"
// }).then(resp => resp.json())
//   .then(jsonResp => {
//   var data=jsonResp[0]
//   var map = {};
//   for (var i = 0; i < data.length; i++) {
//       var bits = data[i].split("|");
//       map[bits[0]] = bits[1];
//   }
//   console.log(map)

// })

// fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//   "headers": {
//     "accept": "application/json, text/javascript, */*; q=0.01",
//     "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//     "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//   },
//   "body": "QueryJSON=%5B%%7B%22type%22%3A%22CME_DIGITAL_FILL_EFFECTS%22%2C%22template%22%3A%7B%22%24%7BFillType%7D%22%3A%7B%22%24%7BColor%7D%22%3A%5B%22%24%7BSmallImageFileName%7D%7C%24%7BCadWorxTextureName%7D%22%5D%7D%7D%7D%5D",
//   "method": "POST"
// }).then(resp => resp.json())
//   .then(jsonResp => {
//   var data=jsonResp[0];
//   const results={};
//   const allEffects={};
//   ['SublimatedTwill','SublimatedSimStitch'].forEach(productName => {
//     var ColorMap = {};
//     var MaterialMap = {};
//     var effects = [];
//     var effectsMap = {};
//     for (var fillType in data) {
//         var effect = {};
//         effect.value = fillType;
//         effect.name = fillType;
//         effect.description = "";
//         effect.ImageURL = "https://assets.stahls.com/stahls/wizards/letters-numbers/images/cme/texture/" + fillType.replace(/ /g, "_") + ".jpg";
//         effect.Colors = [];
//         switch (productName) {
//             case "SublimatedSimStitch":
//                 effect.OneColor = true;
//                 effect.TwoColor = false;
//                 effect.ThreeColor = false;
//                 break;
//             case "SublimatedTwill":
//                 effect.OneColor = true;
//                 effect.TwoColor = true;
//                 effect.ThreeColor = true;
//                 break;
//         }
//         var colorsMap = {};
//         for (var color in data[fillType]) {
//             var c = {};
//             c.value = color;
//             c.name = color;
//             var el = data[fillType][color][0].split("|");
//             c.ImageURL = "https://assets.stahls.com/stahls/wizards/letters-numbers/images/cme/texture/" + el[0];
//             c.CadWorxTexture = el[1];

//             effect.Colors.push(c);
//             colorsMap[c.value] = c;

//             ColorMap[color] = color;
//         }
//         effects.push(effect);
//         effectsMap[effect.value] = { name: effect.name, value: effect.value, Colors: colorsMap };

//         MaterialMap[fillType] = fillType;
//     }

//     //wizard.MaterialAndColorMap = effectsMap;
//     results[productName] = {
//       ColorMap,
//       MaterialMap
//     }
//     allEffects[productName] = effects
//   });
  
//   for(var c in results.SublimatedTwill.ColorMap) {
//     if(results.SublimatedSimStitch.ColorMap[c]!=results.SublimatedTwill.ColorMap[c]) console.log(c)
//   }
//   for(var c in results.SublimatedTwill.MaterialMap) {
//     if(results.SublimatedSimStitch.MaterialMap[c]!=results.SublimatedTwill.MaterialMap[c]) console.log(c)
//   }

//   for(var c in results.SublimatedSimStitch.ColorMap) {
//     if(results.SublimatedSimStitch.ColorMap[c]!=results.SublimatedTwill.ColorMap[c]) console.log(c)
//   }
//   for(var c in results.SublimatedSimStitch.MaterialMap) {
//     if(results.SublimatedSimStitch.MaterialMap[c]!=results.SublimatedTwill.MaterialMap[c]) console.log(c)
//   }
  
//   //use this when all items are equal
//   console.log(results.SublimatedSimStitch.ColorMap)
//   console.log(results.SublimatedSimStitch.MaterialMap)
//   console.log(allEffects)
// })

// async function additional() {
//   const queryText = escape(`[{"type":"MATERIAL","template":["\${Material}|\${MaterialDescription}|\${LongDescription}|\${ImageURL}"]}]`)
//   const queryRes = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//     "headers": {
//       "accept": "application/json, text/javascript, */*; q=0.01",
//       "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//       "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//     },
//     "body": "QueryJSON=" + queryText,
//     "method": "POST",
//   });
//   const res = await queryRes.json();
//   const data = res[0];
//               var map = {};
//               for (var i = 0; i < data.length; i++) {
//                   var bits = data[i].split("|");
//                   map[bits[0]] = {
//                       "Name": bits[1],
//                       "LongDescription": bits[2],
//                       "ImageURL": bits[3]
//                       };
//               }
//   console.log(map)
//   }
//   async function main(){
//   await additional();
//   const AdditionalMaterialsData = {}//paste result here
//   const MaterialMap = {}//paste result here
//   const ColorMap = {}//paste result here
//   const productNames = ["CadCut","Standard","KissCut","SimStitch"]
//   const result = {};
//   for(let productName of productNames) {
//   const queryText = escape(`[{"type":"CME_MATERIAL_COLORS","template":{"\${Material}":{"\${OneColor}|\${TwoColor}|\${ThreeColor}":["\${Color}"]}},"filter":["=","\${ProductName}","${productName}"]}]`)
//   const queryRes = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//     "headers": {
//       "accept": "application/json, text/javascript, */*; q=0.01",
//       "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//       "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//     },
//     "body": "QueryJSON=" + queryText,
//     "method": "POST",
//   });
//   const res = await queryRes.json();
//   const data = res[0];
//       var materials = [];
//       var materialsMap = {};
//       for (var materialCode in data) {
//           var material = {}
//           material.value = materialCode;
//           material.name = MaterialMap[materialCode];
          
//           if (AdditionalMaterialsData) {
//               material.description = AdditionalMaterialsData[materialCode].LongDescription;
//               material.ImageURL = "https://assets.stahls.com/stahls/wizards/letters-numbers/images/materialimages/mat-" + materialCode + ".jpg";
//           }
//           var colors = [];
//           var colorsMap = {};
//           material.OneColor = (productName == "KissCut") ? true : false;
//           material.TwoColor = false;
//           material.ThreeColor = false;
//           var addColors = true;
//           for (var c in data[materialCode]) {
//               for (var i = 0; i < data[materialCode][c].length && addColors; i++) {  
//                   var color = {};
//                   var colorCode = data[materialCode][c][i];
//                   color.value = colorCode;
//                   color.name = ColorMap[colorCode];
//                   //color.isNew = wizard.ColorIsNew[materialCode + colorCode];
//                   colors[colors.length] = color;
//                   colorsMap[color.value.replace(/^00/, "").replace(/^0/, "")] = color;
//               }
//               addColors = false;
//               switch(c) {
//                   case "1|0|0": material.OneColor = true; break;
//                   case "0|1|0": material.TwoColor = true; break;
//                   case "0|0|1": material.ThreeColor = true; break;
//               }
//           }
//           material.Colors = colors;
  
//           materials[materials.length] = material;
//           materialsMap[material.value] = { name: material.name, value: material.value, Colors: colorsMap };
  
//       }
//       //wizard.MaterialAndColorMap = materialsMap;
//       //if (materials && materials.length) {
//       //    ProcessMaterialTypeData(wizard, materials);
//       //}
//       result[productName] = materials;
//   }
//   console.log(result);
//   };
//   main().catch(err => console.error(err));

import ColorRGB from './CmeMaterialColorColorRGB.json'

import MaterialMap from './CmeMaterialColorMaterialMap.json'

import ColorMap from './CmeMaterialColorColorMap.json'

import MaterialAndColorByProductName from './CmeMaterialColorMaterialAndColorByProductName.json'

export const CmeMaterialColor:(wizardData:any)=>{name:string, detail:string} = (wizardData:any) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cme/material.js#L172
  let colorImg:string;
  const details:string[] = [];
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cme/material.js#L186
  function pad(val:string) {
    // this is ridiculous
    var padding = "";
    if (wizardData.CMETYPES != "SublimatedSimStitch") {
        if (!isNaN(parseFloat(val)))
        {
            if (parseFloat(val) < 100) { padding = "0"; }
            if (parseFloat(val) < 10) { padding = "00"; }
        }
    }
    return padding + val;
    // end ridiculous
  }

  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/static/pattern-thumbnails.js#L2
  const StahlsWizards = { 
    Static: {
      "PatternThumbnails":
      {
        '800': "background-image:url('https://assets.stahls.com/stahls/content/images/BlackGold_thumb.png');",
        '801': "background-image:url('https://assets.stahls.com/stahls/content/images/BrownPink_thumb.png');",
        '802': "background-image:url('https://assets.stahls.com/stahls/content/images/NavySilver_thumb.png');",
        '803': "background-image:url('https://assets.stahls.com/stahls/content/images/OrangeWhite_thumb.png');",
        '804': "background-image:url('https://assets.stahls.com/stahls/content/images/PinkBlack_thumb.png');",
        '805': "background-image:url('https://assets.stahls.com/stahls/content/images/leopard_thumb.png');",
        '806': "background-image:url('https://assets.stahls.com/stahls/content/images/zebra_thumb.png');",
        '807': "background-image:url('https://assets.stahls.com/stahls/content/images/madraspink2_thumb.png');",	
        '808': "background-image:url('https://assets.stahls.com/stahls/content/images/boxercraft-037_thumb.png');",
        '906': "background-image:url('https://assets.stahls.com/stahls/content/images/silver_thumb.png');",	
        '907': "background-image:url('https://assets.stahls.com/stahls/content/images/gold_thumb.png');"
      }
    }
  }
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cme/material.js#L15
  function GetColorRGB(matCode:string, colorCode:string) {
    return ColorRGB[matCode + colorCode as keyof typeof ColorRGB] ? ColorRGB[matCode + colorCode as keyof typeof ColorRGB] : ColorRGB[colorCode as keyof typeof ColorRGB];
  }
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1067
  const needsColorSplit = wizardData.material1 == 'STW' && (wizardData.color1 + "").includes('/')
  const splitColor:string[] =  needsColorSplit ? wizardData.color1.split('/') : []
  const foregroundMaterialColor = needsColorSplit ? splitColor[1] : wizardData.color1
  const foregroundMaterialCode = needsColorSplit ? splitColor[0] : wizardData.material1
  const MaterialAndColor:{value:string,Colors:{value:string,ImageURL?:string}[]}[] = MaterialAndColorByProductName[wizardData.CMETYPES as keyof typeof MaterialAndColorByProductName]

  if (!!foregroundMaterialColor && !!foregroundMaterialCode) {
    colorImg = StahlsWizards.Static['PatternThumbnails'][foregroundMaterialColor as keyof typeof StahlsWizards['Static']['PatternThumbnails']];
    if (!colorImg && MaterialAndColor) {
      for (var i = 0, len = MaterialAndColor.length; i < len; i++) {
          var mat = MaterialAndColor[i];

          if (mat.value == foregroundMaterialCode) {
              for (var j = 0, jlen = mat.Colors.length; j < jlen; j++) {
                  var matColor = mat.Colors[j];

                  if (matColor.value == foregroundMaterialColor && matColor.ImageURL) {
                      colorImg = "background-image:url('" + matColor.ImageURL + "');"
                      break;
                  }
              }
              break;
          }
      }
    }

    details.push("Foreground: "
    + MaterialMap[foregroundMaterialCode as keyof typeof MaterialMap] + "<br /> "
    + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(foregroundMaterialCode, pad(foregroundMaterialColor)) + ';' + colorImg + '"></div><span class="colorName">'
    + ColorMap[pad(foregroundMaterialColor) as keyof typeof ColorMap] + '</span>');
  }
  if (!!wizardData.color2 && !!wizardData.material2) {
      colorImg = StahlsWizards.Static['PatternThumbnails'][wizardData.color2 as keyof typeof StahlsWizards['Static']['PatternThumbnails']];
      if (!colorImg && MaterialAndColor) {
        for (var i = 0, len = MaterialAndColor.length; i < len; i++) {
            var mat = MaterialAndColor[i];
  
            if (mat.value == wizardData.material2) {
                for (var j = 0, jlen = mat.Colors.length; j < jlen; j++) {
                    var matColor = mat.Colors[j];
  
                    if (matColor.value == wizardData.color2 && matColor.ImageURL) {
                        colorImg = "background-image:url('" + matColor.ImageURL + "');"
                        break;
                    }
                }
                break;
            }
        }
      }  
  
      details.push("2nd Color Background: "
      + MaterialMap[wizardData.material2 as keyof typeof MaterialMap] + "<br /> "
      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(wizardData.material2, pad(wizardData.color2)) + ';' + colorImg + '"></div><span class="colorName">'
      + ColorMap[pad(wizardData.color2) as keyof typeof ColorMap] + '</span>');
  }
  if (!!wizardData.color3 && !!wizardData.material3) {
      colorImg = StahlsWizards.Static['PatternThumbnails'][wizardData.color3 as keyof typeof StahlsWizards['Static']['PatternThumbnails']];
      if (!colorImg && MaterialAndColor) {
        for (var i = 0, len = MaterialAndColor.length; i < len; i++) {
            var mat = MaterialAndColor[i];
  
            if (mat.value == wizardData.material3) {
                for (var j = 0, jlen = mat.Colors.length; j < jlen; j++) {
                    var matColor = mat.Colors[j];
  
                    if (matColor.value == wizardData.color3 && matColor.ImageURL) {
                        colorImg = "background-image:url('" + matColor.ImageURL + "');"
                        break;
                    }
                }
                break;
            }
        }
      }  

      details.push("3rd Color Background: "
      + MaterialMap[wizardData.material3 as keyof typeof MaterialMap] + "<br /> "
      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(wizardData.material3, pad(wizardData.color3)) + ';' + colorImg + '"></div><span class="colorName">'
      + ColorMap[pad(wizardData.color3) as keyof typeof ColorMap] + '</span>');
  }

  return { name: "Material and Color", detail: details.join('<br />') };
}
