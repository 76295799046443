import React from 'react';
import { ItemWorkFlow } from './ItemWorkFlow';
import formatDateString from '../../../utils/formatted-date';
import { WizardDocConversation } from '../../../types/api-types';
import { InternalArtStates } from '../../../types/art-status';

//Styles
import styles from './user-workflow.module.css';

interface UserWorkFlowProps {
  designId: string;
  designStatus: InternalArtStates;
  conversations: WizardDocConversation[];
  allowThreads: boolean;
  isAdminMode: boolean;
  archived: boolean;
  designCustomerId: string;
}

const UserWorkFlow: React.FC<UserWorkFlowProps> = ({ conversations, designId, designStatus, isAdminMode, archived, designCustomerId }) => {
  const sortedConversations = React.useMemo(() => {
    return conversations.slice().sort((a, b) => {
      const dateA = new Date(a.updateDate || a.createdDate || 0).getTime();
      const dateB = new Date(b.updateDate || b.createdDate || 0).getTime();
      return dateB - dateA;
    });
  }, [conversations]);

  return (
    <>
      <div>
        <ItemWorkFlow
          designId={designId}
          designStatus={designStatus}
          activeConversationId={1}
          adminMode={isAdminMode}
          archived={archived}
        />
        <div className={styles.wrapper}>
          <span className={styles.title}>Communication History</span>
          {sortedConversations.map((conversation) => (
            <ConversationItem key={conversation.id} conversation={conversation} designCustomerId={designCustomerId} isAdminMode={isAdminMode} />
          ))}
        </div>
      </div>
    </>
  );
};

type ConversationItemProps = {
  conversation: WizardDocConversation;
  designCustomerId: string;
  isAdminMode: boolean;
};

const ConversationItem: React.FC<ConversationItemProps> = ({ conversation, designCustomerId, isAdminMode }) => {
  const conversationData = JSON.parse(conversation.message);
  const userDataToken = isAdminMode ? (conversation?.userDataToken == designCustomerId ? 'Customer' : 'Art team') : (conversation?.userDataToken == designCustomerId ? 'You' : 'Art team')
  const lastUpdate = conversation.createdDate || conversation.updateDate;
  const formattedDate = lastUpdate ? formatDateString({ dateString: lastUpdate }) : "";

  return (
    <div className={styles.container}>
      <span className={`${styles.column} ${styles.column1}`}>
        {userDataToken}:
      </span>
      <span className={`${styles.column} ${styles.column2}`}>
        {conversationData.message}
      </span>
      <span className={`${styles.column} ${styles.column3}`}>
        {formattedDate}
      </span>
    </div>
  );
};

export default UserWorkFlow;