import { useEffect, useState } from 'react';
import { FileRole } from './fileUpload';
import { ArtworkFolder } from '../../../types/art-status';

// Styles
import styles from './fileUploadModal.module.css';

// Defining the prop types
interface ModalProps {
  isOpen: boolean;
  productType?: string;
  onClose: () => void;
  onFileSelected: (role: FileRole, artType: ArtworkFolder) => void;
}

const FileUploadModal: React.FC<ModalProps> = ({ isOpen, productType, onClose, onFileSelected }) => {
  const [selectedRole, setSelectedRole] = useState<FileRole>(FileRole.DefaultFile);
  const [selectedArtType, setSelectedArtType] = useState<ArtworkFolder>('productionArtworkPreview');

  useEffect(() => {
    if (isOpen) {
      setSelectedRole(FileRole.DefaultFile);
      setSelectedArtType('productionArtworkPreview');
    }
  }, [isOpen]);

  const handleRoleClick = (role: FileRole) => {
    setSelectedRole(role);
  };

  const handleArtTypeClick = (artType: ArtworkFolder) => {
    setSelectedArtType(artType);
  };

  const handleConfirm = () => {
    if (selectedRole && selectedArtType) {
      onFileSelected(selectedRole, selectedArtType);
    }
  };

  const getRoleName = (roleName: string) => {
    switch (roleName) {
      case FileRole.DefaultFile:
        return "DEFAULT";
      case FileRole.FlipFile:
        return "FLIP";
      case FileRole.SewFile:
        return "SEW";
    }
  }

  const isFlipFileAllowed = productType === "FlexStyleHolographicHA" || productType === "FlexStyleHolographicPS";

  return isOpen ? (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          <div className={styles.header}>
            <span>Upload File</span>
            <div className={styles.closeIcon} onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.optionsWrapper}>
              <span className={styles.uploadTitle}>Art Type</span>
              <div className={styles.fileRoleOptions}>
                <div
                  onClick={() => handleArtTypeClick("productionArtwork")}
                  className={[styles.options, selectedArtType === "productionArtwork" ? styles.selectedOption : ""].join(' ')}>
                  PRODUCTION
                </div>
                <div
                  onClick={() => handleArtTypeClick("productionArtworkPreview")}
                  className={[styles.options, selectedArtType === "productionArtworkPreview" ? styles.selectedOption : ""].join(' ')}>
                  PROOF
                </div>
              </div>
            </div>
            <div className={styles.optionsWrapper}>
              <span className={styles.uploadTitle}>File Role type</span>
              <div className={styles.fileRoleOptions}>
                {Object.values(FileRole).map((role) => {
                  // Filter Flip option if productType is not the expected
                  if (role === FileRole.FlipFile && !isFlipFileAllowed) {
                    return null;
                  }

                  return <div
                    className={[styles.options, selectedRole === role ? styles.selectedOption : ""].join(' ')}
                    key={role}
                    onClick={() => handleRoleClick(role)}>
                    <span>{getRoleName(role)}</span>
                  </div>
                })}
              </div>
            </div>
          </div>
          <div className={`${styles.footerButtons} p-6 border-t border-solid border-blueGray-200 rounded-b`}>
            <button
              className={styles.acceptBtn}
              onClick={handleConfirm}
              disabled={!selectedRole}>
              Select file
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default FileUploadModal;

function CloseIcon() {
  return (
    <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
      <span className="sr-only">Close menu</span>
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  );
}