
// There are 4 different views for Art Details page

// Art Details Customer Mode
// Art Details Artist Mode
// Art Details Production Mode

// UC Max Production Mode

export enum AppPages {
  DetailsCustomer = 'DetailsCustomer',
  DetailsArtist = 'DetailsArtist',
  DetailsProduction = 'DetailsProduction',
  DetailsUCMProduction = 'DetailsUCMProduction',
  DetailsHistory = 'DetailsHistory',
  Dashboard = 'Dashboard',
  Loading = 'Loading',
  InitFail = 'InitFail'
}