import React from 'react';
import QRCode from "react-qr-code";

interface QRCodeProps {
    DocNo: string;
    LineNo: string;
}

const QRCodeComp: React.FC<QRCodeProps> = ({ DocNo, LineNo }) => {
    const encodedMessage = `%P%WO${DocNo} ${LineNo}`;

    return (

      <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }} >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={encodedMessage}
            viewBox={`0 0 256 256`}
          />
      </div>
    );
}

export default QRCodeComp;