  //here's the query data for size descriptions: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L28
  //and here the post processing to get size descriptions: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cme/size.js#L269
  //changes in the sizes descriptions seem rare so we'll have a fixed map instead of querying each time
  //all in all the code executed goes like this for Standard:

// fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//   "headers": {
//     "accept": "application/json, text/javascript, */*; q=0.01",
//     "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//     "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//   },
//   "body": "QueryJSON=%5B%7B%22type%22%3A%22CME_SIZE%22%2C%22template%22%3A%7B%22%24%7BOneColor%7D%7C%24%7BTwoColor%7D%7C%24%7BThreeColor%7D%22%3A%7B%22%24%7BCMESize%7D%7C%24%7BCMESizeDescription%7D%22%3A%5B%22%24%7BWidth%7D%7C%24%7BHeight%7D%22%5D%7D%7D%2C%22filter%22%3A%5B%22%3D%22%2C%22%24%7BProductName%7D%22%2C%22Standard%22%5D%7D%5D",
//   "method": "POST"
// }).then(resp => resp.json())
// .then(jsonResp => {
// var data=jsonResp[0]
// var sizes = {};
// Object.keys(data).forEach(c => {
// var colors      = c.split('|'),
//     oneColor    = colors[0] == '1',
//     twoColor    = colors[1] == '1',
//     threeColor  = colors[2] == '1',
//     s           = data[c],
//     count;
  
//   oneColor    && (count = '1');
//   twoColor    && (count = '2');
//   threeColor  && (count = '3');
  
//   sizes[count] = {};
  
//   Object.keys(s).forEach(function(size) {
//     var tmp = size.split("|");
//     var parts = data[c][size][0].split("|");
//     sizes[count][tmp[0]] = {
//       code: tmp[0],
//       description: tmp[1],
//       count: count,
//       width: parseFloat(parts[0]),
//       height: parseFloat(parts[1])
//     };
//   })
  
// });
  
//         var allSizes = {},
//             b = [];
  
// Object.values(sizes).forEach(s => {
// Object.keys(s).forEach(size => {
//                 if ( ! allSizes[size] ) {
//                     allSizes[size] = s[size]; //description
//                     b[b.length] = size;
//                 }
  
// })
// })
  
//         b.sort(function(x, y) { 
//             if (x == "other" || y == "other") return 0;  
//             return (parseFloat(allSizes[x].width) - parseFloat(allSizes[y].width) > 0 ? 1 : -1);  
//         })
//         var newArray = {};
//         for (var i = 0; i < b.length; i++) {
//             newArray[b[i]] = allSizes[b[i]];
//         }
  
// newArray["other"] = "Other";
  
// console.log(newArray)
// console.log(sizes)
// })

  //here's the resulting data for size descriptions

import sizeDescriptionsPerProductName from './CmeSizeDescriptionsPerProductName.json'

export const CmeSize:(wizardData:any)=>{name:string, detail:string} = (wizardData:any) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cme/size.js#L330
  
  const details = [];
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1054
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1055
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1057
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1058
  if (wizardData.topsize != "other") {
    const cmeType = wizardData.CMETYPES as keyof typeof sizeDescriptionsPerProductName
    const sizeDescriptions = sizeDescriptionsPerProductName[cmeType]
    if(!sizeDescriptions) throw new Error(`Missing CmeSizeDescriptionsPerProductName.json for ${cmeType}`)
    details.push("Text Size: " + sizeDescriptions[wizardData.topsize as keyof typeof sizeDescriptions].description);
  }
  else {
    const otherSize = (wizardData.topsizeother + "").split("x")
    const data = {
      TopMaxHeight: otherSize[0],
      TopMaxLength: otherSize[1]
    }
    details.push("Text Size: " + data.TopMaxHeight + "\" x " + data.TopMaxLength + "\"");
  }

  if (!!wizardData.bottomsize) {
      if (wizardData.bottomsize != "other") {
              const cmeType = wizardData.CMETYPES as keyof typeof sizeDescriptionsPerProductName
              const sizeDescriptions = sizeDescriptionsPerProductName[cmeType]
              if(!sizeDescriptions) throw new Error(`Missing CmeSizeDescriptionsPerProductName.json for ${cmeType}`)
              details.push("2nd Line Text Size: " + sizeDescriptions[wizardData.bottomsize as keyof typeof sizeDescriptions].description);
      } else {
        const otherSize = (wizardData.bottomsizeother + "").split("x")
        const data = {
          BottomMaxHeight: otherSize[0],
          BottomMaxLength: otherSize[1]
        }
        details.push("2nd Line Text Size: " + data.BottomMaxHeight + "\" x " + data.BottomMaxLength + "\"");
      }                
  }

  var o = { 
      name: "Size",
      detail: details.join("<br />")
  }   

  return o;
}
