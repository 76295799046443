import React, { useState } from 'react';
import appModel from '../../models/app-model';
import { useSnapshot } from 'valtio';

// Styles
import styles from './Carousel.module.css';

interface Slide {
  title: string;
  subtitle: string;
  image: string;
  type?: string;
  fileType?: string;
  originalFileName?: string ;
}

interface CarouselProps {
  slides: Slide[] | undefined;
  selectedIndex: number;
  onSlideChange?: (newIndex: number) => void;
  isAdmin?: boolean;
  downloadButton?: JSX.Element;
}

export function NextIcon() {
  return (
    <svg viewBox="0 0 100 100">
      <path d="M 50,0 L 60,10 L 20,50 L 60,90 L 50,100 L 0,50 Z" className="arrow" transform="translate(85,100) rotate(180)"></path>
    </svg>
  );
}

export function PreviousIcon() {
  return (
    <svg viewBox="0 0 100 100">
      <path d="M 50,0 L 60,10 L 20,50 L 60,90 L 50,100 L 0,50 Z" className="arrow" transform="translate(15,0)"></path>
    </svg>
  );
}

export function DownIcon() {
  return (
    <svg fill='none'
      viewBox="0 0 24 24"
      height="24px"
      width="24px" strokeWidth={2.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

const Carousel: React.FC<CarouselProps> = ({ slides, selectedIndex, onSlideChange, isAdmin, downloadButton }) => {
  const appSnap = useSnapshot(appModel)
  const customerId = appSnap.customerId
  const classItem = isAdmin ? [styles.carouselItem, styles.admin].join(' ') : styles.carouselItem;
  const [loadingStates, setLoadingStates] = useState(Array(slides?.length).fill(true));

  const nextSlide = () => {
    if (slides) {
      const newIndex = selectedIndex === slides.length - 1 ? 0 : selectedIndex + 1
      onSlideChange && onSlideChange(newIndex)
    }
  }

  const prevSlide = () => {
    if (slides) {
      const newIndex = selectedIndex === 0 ? slides.length - 1 : selectedIndex - 1;
      onSlideChange && onSlideChange(newIndex);
    }
  }

  const handleImageLoaded = (index: number) => {
    const newLoadingStates = [...loadingStates];
    newLoadingStates[index] = false;
    setLoadingStates(newLoadingStates);
  }

  const handleImageError = (index: number) => {
    handleImageLoaded(index);
  }

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <div className={styles.carousel}>
      <div className={styles.carouselInner}>
        {slides.map((slide, index) => {
          return (
            <React.Fragment key={index}>
              <div
                className={[(index === selectedIndex ? classItem + ' ' + styles.active : classItem),
                loadingStates[index] ? styles.skeletonImg : ''].join(' ')}
              >
                {index === selectedIndex && (
                  <img src={slide.image}
                    alt="slide"
                    className={styles.slideImage}
                    onLoad={() => handleImageLoaded(index)}
                    onError={() => handleImageError(index)}
                    style={{ display: loadingStates[index] ? 'none' : 'block' }} />
                )}
              </div>
              {index === selectedIndex && (
                <div className={styles.carouselCaption}>
                  <div className={styles.firstColumn}>
                    {(slide?.type !== 'productionArtworkPreview' || !customerId) && downloadButton}
                  </div>
                  <div className={styles.contentColumn}>
                    <h3 className={styles.slideTitle}>{slide?.title}</h3>
                    {slide?.fileType && <span className={styles.slideSubtitle}>
                      <span className={styles.fileType}>File Type:</span> {slide?.fileType} <br/>
                      {slide?.originalFileName && (
                        <>
                          <span className={styles.fileType}>File Name:</span> {slide.originalFileName}
                        </>
                      )}
                    </span>}
                    <p className={styles.slideSubtitle}></p>
                  </div>
                  <div className={styles.thirdColumn}>
                    <div className={styles.arrowsWrapper}>
                      <a className={styles.carouselArrowLeft} onClick={prevSlide}>
                        <span className={styles.prevIcon}>
                          <PreviousIcon />
                        </span>
                      </a>
                      <a className={styles.carouselArrowRight} onClick={nextSlide}>
                        <span className={styles.nextIcon}>
                          <NextIcon />
                        </span>
                      </a>
                    </div>
                    <div className={styles.countWrapper}>
                      <span className={styles.slideSubtitle}>{slide?.subtitle}</span>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Carousel
