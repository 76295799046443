

import { useCallback, useEffect, useRef, useState } from 'react';

function useDivHeights(count: number) {
  const refs = useRef<HTMLDivElement[]>([]);
  const [heights, setHeights] = useState<number[]>(Array(count).fill(0));

  const updateHeights = useCallback(() => {
    setHeights(refs.current.map(ref => ref?.clientHeight || 0));
  }, []);

  useEffect(() => {
    updateHeights();

    window.addEventListener('resize', updateHeights);
    return () => {
      window.removeEventListener('resize', updateHeights);
    };
  }, [updateHeights]);

  return { refs, heights };
}

export default useDivHeights;

