import React, { useState, useCallback } from 'react';
import { DeleteIcon } from './imageSelector';
// Styles
import styles from './notesEmbroideryModal.module.css';
import './status.module.css';
import { patchWizardDocWizardData } from '../../../api/backend';

// Defining the prop types/*  */
interface NotesEmbroideryModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentId?: string;
  currentNote?: string
}

const NotesEmbroideryModal: React.FC<NotesEmbroideryModalProps> = ({ isOpen, onClose, documentId, currentNote }) => {
  const [processing, setProcessing] = useState<boolean>(false)
  const [notes, setNotes] = useState<string>(currentNote || "");

  const handleNoteChange = useCallback((e: { target: { value: React.SetStateAction<string>; }; }) => {
    setNotes(e.target.value);
  }, [setNotes]);

  const removeNote = async () => {
    setProcessing(true);
    await patchWizardDocWizardData(documentId as string, { sewFileInternalEmbroideryNotes: "" });
    setNotes("");
    setProcessing(false); 
    onClose();
  };

  const addNote = async () => {
    setProcessing(true);
    await patchWizardDocWizardData(documentId as string, {  sewFileInternalEmbroideryNotes: notes });
    setProcessing(false); 
    onClose();
  };

  function CloseIcon() {
    return (
      <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span className="sr-only">Close menu</span>
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    );
  }

  const modal = 
      <div className={styles.modalOverlay}>
        <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
          <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
            <div className={styles.header}>
              <span>Embroidery Notes</span>
              <div className={styles.closeIcon} onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
            {
              <div className={styles.notesBox}>
                <span>
                  <div className={styles.noNotes}>
                    <textarea
                      placeholder="Add your note here..."
                      className={styles.notesInput}
                      onChange={handleNoteChange}
                      value={notes}
                      rows={4} cols={50}
                    />
                  </div>
                </span>
                <span
                  className={styles.deleteIcon}
                  onClick={() => (processing ? void 0 : removeNote())}
                >
                  <DeleteIcon />
                </span>
              </div>
            }
            <div className={`${styles.footerButtons} p-6 border-t border-solid border-blueGray-200 rounded-b`}>
              <button className={[styles.button, styles.cancelBtn].join(' ')} onClick={() => { onClose(); }} disabled={processing}>Close</button>
              <button className={styles.acceptBtn} onClick={() => { addNote(); }} disabled={processing}>Update Note</button>
            </div>
          </div>
        </div>
      </div>;

  return isOpen ? modal : null;
};

export default NotesEmbroideryModal;