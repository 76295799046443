//here's the query data for font maps: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/playerperfect-wizard-config.js#L28
//and here the post processing to get font maps: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/data/post-process.js#L11
//changes in the font maps seem rare so we'll have a fixed map instead of querying each time
//all in all the code executed goes like this:

//use minheight for CustomCut
// const includeMinHeight = false
// async function main() {
//   const result = {}
//   const FontData = {}
//   const query = encodeURI(includeMinHeight ? `[{"type":"FONT","template":["\${WizardID}|\${Font}|\${FontDescription}|\${FontPath}|\${Greek}|\${MinHeight}"]}]` : `[{"type":"FONT","template":["\${WizardID}|\${Font}|\${FontDescription}|\${FontPath}|\${Greek}"]}]`)
//   const resp = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//     "headers": {
//       "accept": "application/json, text/javascript, */*; q=0.01",
//       "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//       "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//     },
//     "body": "QueryJSON=" + query,
//     "method": "POST"
//   })
//   const jsonResp = await resp.json()
//   var data=jsonResp[0]
//       for (var i = 0; i < data.length; i++) {
//           var bits = data[i].split("|");
//           result[bits[0]] = result[bits[0]] ?? {}
//           FontData[bits[0]] = FontData[bits[0]] ?? {}
//           result[bits[0]][bits[1]] = bits[2];
  
//           FontData[bits[0]][bits[1]] = {
//               path: bits[3],
//               greek: bits[4] == "1" ? true : false
//           };
  
//           if (bits[5] && bits[0]=="CustomCut") {
//               FontData[bits[0]][bits[1]].minHeight = parseFloat(bits[5]);
//           }
//       }
      
//       delete FontData[""]
//       delete result[""]

//   console.log(result)
//   console.log(FontData)
// }
// main().catch(err => console.error(err))


//here's the resulting data for font maps

import fontMapPerProductName from './GenericFontMapByProduct.json'

//same for PrespacedMinMaxLengths: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/playerperfect-wizard-config.js#L60
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/data/post-process.js#L1292

// const SortArray = function(data) {
//   return data.sort(function (a, b) { if (a - b < 0) return -1; return 1 });
// }

// async function main() {
// const results = {}
// const wizardIds = ['3DGreek', 'AutoStitch', 'CustomCut', 'CustomMadeEasy', 'Embroidered', 'HelmetNumber', 'PlayerPerfect', 'PreCut', 'PreSpacedNumbers', 'PreSpacedText', 'ScreenPrintedTransfer', 'SimStitch', 'SimStitchPSNumbers', 'SimStitchText', 'TeamPerfect']
// for(let wizardId of wizardIds) {
// results[wizardId] = {}
// const query = encodeURI(`[{"type":"PRESPACED_LIMITS","template":{"\${Layout}":{"\${Size}":["\${MinLength}|\${MaxLength}|\${MaxLengthDesc}"]}},"filter":["=","\${WizardID}","${wizardId}"]}]`)
// const resp = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
// "headers": {
//   "accept": "application/json, text/javascript, */*; q=0.01",
//   "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//   "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
// },
// "body": "QueryJSON=" + query,
// "method": "POST"
// })
// const jsonResp = await resp.json()
// var layoutdata=jsonResp[0]
// for(var layout in layoutdata) {
// var data = layoutdata[layout]
//     var map = {};

//   for (var fontSize in data) {
      
//       map[fontSize] = {};
//       map[fontSize].MaxLengths = [];
//       map[fontSize].HasOther = false;
//       map[fontSize].MaxOthers = [];
//       map[fontSize].MinLength = 99;
//       map[fontSize].MaxLength = 0;

//       for (var i = 0; i < data[fontSize].length; i++) {

//           var lengths = data[fontSize][i].split("|"); // data format example: 6|12|Youth up to 12"

//           if (lengths[2] != "Other") {
//               map[fontSize].MaxLengths.push({"value": lengths[1], "name": lengths[2]});
//           } else { // skip "Other" option, will add it at the end
              
//               // in case there are multiple "Other" options, 
//               // get the minimum of all MinLenghts and maximum of MaxLengths
//               // to get the interval that encompasses intervals for all "Other" options
//               map[fontSize].MinLength = Math.min(lengths[0], map[fontSize].MinLength);
//               map[fontSize].MaxLength = Math.max(lengths[1], map[fontSize].MaxLength);
//               map[fontSize].MaxOthers.push(lengths[1]); // collect all MaxLengths - needed for the price step
//               map[fontSize].HasOther = true;
//           }
//       }

//       if (map[fontSize].HasOther) {
//           map[fontSize].MaxLengths.push({"value": "other", "name": "Other"}); // to make sure option "Other" is last added
//           map[fontSize].MaxOthers = SortArray(map[fontSize].MaxOthers);
//       }
//   }
//   results[wizardId][layout] = map;

// }
// }
// console.log(results)
// }
// main().catch(err => console.error(err))

import PrespacedMinMaxLengthsPerProductNameAndLayout from './GenericPrespacedMinMaxLengthsByProductAndLayout.json'

// same for material name and id
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/_common-player-products.js#L197

// async function main() {
//   const result = {}
//   const queryText = escape(`[{"type":"PRESPACED_CART_VALUES","template":["\${Material}|\${szMaterialID}|\${WizardID}|\${Font}"]}]`)
//   const queryRes = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//      "headers": {
//        "accept": "application/json, text/javascript, */*; q=0.01",
//        "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//        "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//      },
//      "body": "QueryJSON=" + queryText,
//      "method": "POST",
//   });
//   const res = await queryRes.json();
//   const data = res[0];
//   for(let item of data) {
//     const [matCodeString, matCodeNum, wizardID, fontName] = item.split('|');
//     if(!result[wizardID]) result[wizardID] = {};
//     if(!result[wizardID][fontName]) result[wizardID][fontName] = {};
//     result[wizardID][fontName][matCodeNum] = matCodeString;
//   }
//   console.log(result)
// }
// main().catch(err => console.error(err))

import MaterialNameByIdPerFontPerProductName from './GenericMaterialNameByIdPerFontPerProductName.json'

export function getPlayerProductData(wizardData:any, editParams:any, type:string):any {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/_common-player-products.js#L95
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/static/graphics.js#L1
  const GraphicData = {
    "BB": {"value": "BB", "cadworxValue": "Baseball 1",     "cartValue":  "1", "name": "Baseball",  "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Baseball.png"},
    "BS": {"value": "BS", "cadworxValue": "Basketball 1",   "cartValue":  "8", "name": "Basketball","imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Basketball.png"},
    "C1": {"value": "C1", "cadworxValue": "Capsule 1",      "cartValue": "11", "name": "Capsule 1", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Capsule1.png"},
    "C2": {"value": "C2", "cadworxValue": "Capsule 2",      "cartValue": "12", "name": "Capsule 2", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Capsule2.png"},
    "FH": {"value": "FH", "cadworxValue": "Field Hockey 1",     "pathExtWithFont": "Field Hockey 2",        "cartValue": "17", "name": "Field Hockey", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Fieldhockey.png"},
    "FB": {"value": "FB", "cadworxValue": "Football 1",         "pathExtWithFont": "Football 3",            "cartValue":  "3", "name": "Football", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Football.png"},
    "SF": {"value": "SF", "cadworxValue": "Striped Football 1", "pathExtWithFont": "Striped Football 2",    "cartValue":  "7", "name": "Football (striped)", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-FootballSTP.png"},
    "GO": {"value": "GO", "cadworxValue": "Golf 1",         "cartValue": "16", "name": "Golf",      "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Golf.png"},
    "HP": {"value": "HP", "cadworxValue": "Hockey 1",           "pathExtWithFont": "Hockey 2",              "cartValue":  "6", "name": "Hockey", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Hockey.png"},
    "LA": {"value": "LA", "cadworxValue": "Lacrosse 2",         "pathExtWithFont": "Lacrosse 3",            "cartValue": "14", "name": "Lacrosse", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Lacross.png"},
    "MG": {"value": "MG", "cadworxValue": "Megaphone 1",    "cartValue": "10", "name": "Megaphone", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Megaphone.png"},
    "OV": {"value": "OV", "cadworxValue": "Oval 1",         "cartValue": "13", "name": "Oval",      "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Oval.png"},
    "SB": {"value": "SB", "cadworxValue": "Soccer Ball 1",      "pathExtWithFont": "Soccer Ball 2",         "cartValue":  "4", "name": "Soccer", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Soccer.png", graphicFontColorSameAsGraphic: true},
    "SW": {"value": "SW", "cadworxValue": "Swimming 1",     "cartValue": "15", "name": "Swimming",  "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Swimming.png"},
    "TK": {"value": "TK", "cadworxValue": "Track 1",        "cartValue":  "9", "name": "Track",     "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Track.png"},
    "VB": {"value": "VB", "cadworxValue": "VolleyBall 2",       "pathExtWithFont": "Volleyball 3",          "cartValue":  "5", "name": "Volleyball", "imageURL": "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Volleyball.png"}
  }

  const GraphicFonts = {
      'GV': 22,
      'GP': 21,
      'PG': -1
  }

  // const GraphicFontsImages = {
  //     'GV': "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Font-Varsity.png",
  //     'GP': "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Font-ProBlock.png",
  //     'PG': "https://assets.stahls.com/stahls/wizards/letters-numbers/teamperfect/TPW-Font-GraphicOnly.png"
  // }

  const playerProductData:{[key:string]:any} = {}
  const playerProductDataSetter = {
    SetValue: (key:string, value:any) => {
      playerProductData[key] = value;
    },
    GetValue: (key:string):any => {
      return playerProductData[key]
    }
  }
  // check for v. arch
  var layout = editParams['cboLayouts'].toLowerCase();
  if (layout == "v. arch") layout = "AR"
  if (layout.toLowerCase() == "arch") layout = "AR"
  if (layout.toLowerCase() == "straight") layout = "ST"
  playerProductDataSetter.SetValue("StyleType",                  layout)    // should come in as straight or arch   
  playerProductDataSetter.SetValue("ForegroundMaterialCode",     wizardData['cboMaterials']);
  playerProductDataSetter.SetValue("MaximumLength",              editParams['cboWidths']);
  playerProductDataSetter.SetValue("BackgroundMaterialColor",    wizardData['h2ColorID']);
  playerProductDataSetter.SetValue("ForegroundMaterialColor",    wizardData['hColorID']);
  playerProductDataSetter.SetValue("FontLetterSize",             wizardData['hLetterHeight']);
  playerProductDataSetter.SetValue("FontNumberSize",             wizardData['hNumberHeight']);
  playerProductDataSetter.SetValue("OrderName",                  wizardData['txtTeamName']);
  playerProductDataSetter.SetValue("typingFee",                  (!!wizardData['hTypingFee'] ? true : false) );
  playerProductDataSetter.SetValue("EffectType",                 wizardData['EffectType']);
  playerProductDataSetter.SetValue("EffectStyle",                wizardData['EffectStyle']);
  playerProductDataSetter.SetValue("ProductType",                (wizardData['EffectType'] && wizardData['EffectStyle'] ? "effects" : "none"));

  //
  // Restore Graphics step
  //
  var gfx = wizardData['optGraphic'];
  var gfxfont = wizardData['optGraphicStyle'];

  for (var i in GraphicData){
      if (GraphicData[i as keyof typeof GraphicData].cartValue == gfx) {
        playerProductDataSetter.SetValue("GraphicCode", i);
      }
  }
  for (var i in GraphicFonts){
      if (GraphicFonts[i as keyof typeof GraphicFonts] == gfxfont) {
        playerProductDataSetter.SetValue("GraphicFontCode", i);
      }
  }

  var font = editParams["hFont"];
  if (font == "Vertical Pro") font = "Vertical Pro Block";
  if (font == "Homerun") font = "Home Run";
  if (font == "Team U.S.") font = "Team US";
  if (font == "Bauer Contour") font = "Contour (Bauer)";
  if (font == "Bauer Modern") font = "Modern (Bauer)";
  if (font == "Bauer National") font = "National (Bauer)";

  // set layer based on h2color
  var layer = "foreground";
  if (typeof wizardData['h2ColorID'] != "undefined" && wizardData['h2ColorID'] != "" ){
      layer = "foreground_and_background";
      playerProductDataSetter.SetValue("BackgroundMaterialCode", wizardData['cbo2Materials']);
      //skus['cbo2Materials']  = wizard.PrespacedCartValues[data.BackgroundMaterialCode];
  }
  playerProductDataSetter.SetValue("Layer", layer);

  //
  // The following wizards do not have arch/straight, so StyleType is the same as layer.
  // 
  if (type == "PreSpacedNumbers" || type == "SimStitchPSNumbers")    playerProductDataSetter.SetValue("StyleType", layer);
  if (type == "VerticalText")        playerProductDataSetter.SetValue("StyleType", layer);

  // look up the font
  var fontCode = ""
  
  var wiz = type;
  switch (type) {
      case "VerticalText":
      case "PreSpacedLetters":
      case "ZippWeedText":
          wiz = "PreSpacedText";
  }

  const fontMap = fontMapPerProductName[wiz as keyof typeof fontMapPerProductName]
  for (var i in fontMap) { 
      if (fontMap[i as keyof typeof fontMap] == font) fontCode = i; 
  }
  playerProductDataSetter.SetValue("FontCode", fontCode);

  playerProductDataSetter.SetValue("ProductMaximumLength", undefined);
  var maxLen = wizardData['cboWidths'], letterSize = wizardData['hLetterHeight'];
  const PrespacedMinMaxLengthsByLayers = PrespacedMinMaxLengthsPerProductNameAndLayout[wiz as keyof typeof PrespacedMinMaxLengthsPerProductNameAndLayout]
  const PrespacedMinMaxLengths = PrespacedMinMaxLengthsByLayers && PrespacedMinMaxLengthsByLayers[playerProductDataSetter.GetValue("StyleType") as keyof typeof PrespacedMinMaxLengthsByLayers]
  if (!isNaN(parseFloat(maxLen)) && letterSize && PrespacedMinMaxLengths && PrespacedMinMaxLengths[letterSize as keyof typeof PrespacedMinMaxLengths]) {
      var maxOthers = PrespacedMinMaxLengths[letterSize as keyof typeof PrespacedMinMaxLengths].MaxOthers;
      var prodMaxLen = maxLen;

      // find the upper limit of the selected MaximumLength within MaxOthers
      for (var j = 0; j < maxOthers.length; j++) {
          if (parseFloat(maxOthers[j]) >= parseFloat(maxLen)) {
              prodMaxLen = maxOthers[j];
              break;
          }
      }
      playerProductDataSetter.SetValue("ProductMaximumLength", prodMaxLen);
  }

  // teamnames list ID
  if ( !! wizardData["listID"] && ! isNaN( parseInt(wizardData["listID"]) ) ) 
  {
    playerProductDataSetter.SetValue("TeamListID", wizardData["listID"]);
  }

  const MaterialNameByIdPerFont = MaterialNameByIdPerFontPerProductName[wiz as keyof typeof MaterialNameByIdPerFontPerProductName]
  const MaterialNameById = MaterialNameByIdPerFont && MaterialNameByIdPerFont[fontCode as keyof typeof MaterialNameByIdPerFont] ? MaterialNameByIdPerFont[fontCode as keyof typeof MaterialNameByIdPerFont] : undefined
  if(MaterialNameById) {
    if(MaterialNameById[playerProductData.ForegroundMaterialCode as keyof typeof MaterialNameById]) playerProductDataSetter.SetValue("ForegroundMaterialCode", MaterialNameById[playerProductData.ForegroundMaterialCode as keyof typeof MaterialNameById]);
    if(MaterialNameById[playerProductData.BackgroundMaterialCode as keyof typeof MaterialNameById]) playerProductDataSetter.SetValue("BackgroundMaterialCode", MaterialNameById[playerProductData.BackgroundMaterialCode as keyof typeof MaterialNameById]);
  }

  return playerProductData
}
