import React, { useEffect, useState } from 'react';
import Spinner from './spinner';
import { EditIcon } from './imageSelector';
import { getLabelForKey } from '../../../utils/artDetailsUtils';
import { getBackendApiUrl } from "../../../config";
import CommentBox from '../../../components/commentBox/commentBox';
import { addAuthorizationHeader } from '../../../api/network';
import { InternalArtStates } from '../../../types/art-status';

// Styles
import styles from './changeLayersNameModal.module.css';

// Defining the prop types
interface ChangeLayerNameProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (comment: string) => void;
  wizardData: Record<string, any>;
  docId?: string;
  isCustomerView: boolean;
}

const ChangeLayerName: React.FC<ChangeLayerNameProps> = ({ isOpen, onClose, onSuccess, wizardData, docId, isCustomerView }) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [editedValues, setEditedValues] = useState<Record<string, string>>({});
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [comment, setComment] = useState<string>('');
  const isApproved = wizardData?.statusID == InternalArtStates.APPROVED;

  useEffect(() => {
    if (!isOpen) {
      setEditedValues({});
      setEditingKey(null);
    }
  }, [isOpen]);

  const handleCommentChange = (value: string) => {
    setComment(value);
  }

  const editableProperties = Object.keys(wizardData)
    .filter(key => /^color\dDescription$/.test(key))
    .reduce((acc, key) => ({ ...acc, [key]: wizardData[key] }), {});

  const handleEdit = (key: string) => {
    setEditingKey(key);
  };

  const handleChange = (key: string, value: string) => {
    setEditedValues(prev => ({ ...prev, [key]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const confirm = () => {
    setProcessing(true);

    const headers = addAuthorizationHeader({
      'Content-Type': 'application/json'
    });

    const descriptions = Object.keys(editedValues).reduce((acc, key) => {
      if (editedValues[key] !== wizardData[key]) {
        acc[key] = editedValues[key];
      }
      return acc;
    }, {} as Record<string, string>);

    const updatedValues = {
      id: docId,
      descriptions
    };

    fetch(`${getBackendApiUrl()}/DesignDescriptions`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(updatedValues)
    }).then(async (processResp) => {
      if (!processResp.ok) throw new Error("Error editing layer names " + await processResp.text())
      setProcessing(false);
      onSuccess(comment ?? null);
    }).catch(err => {
      console.log("Failed to update Layer Names: " + (err as Error).message)
      setProcessing(false);
    });
  };

  function CloseIcon() {
    return (
      <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span className="sr-only">Close menu</span>
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    );
  }

  return isOpen ? (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          <div className={styles.header}>
            <span>Edit Layer Name</span>
            <div className={styles.closeIcon} onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.modalContent}>
            <form onSubmit={handleSubmit}>
              {Object.keys(editableProperties).map(key => (
                <div key={key} className={styles.formRow}>
                  <span className={styles.keyColumn}>{getLabelForKey(key)}:</span>
                  {editingKey === key ? (
                    <input
                      type="text"
                      value={editedValues[key] ?? wizardData[key]}
                      onChange={(e) => handleChange(key, e.target.value)}
                      className={[styles.input, styles.valueColumn].join(' ')}
                    />
                  ) : (
                    <span
                      className={[
                        styles.valueColumn,
                        editedValues[key] !== undefined && editedValues[key] !== wizardData[key] ? styles.editedValue : ''
                      ].join(' ')}
                    >
                      {editedValues[key] ?? wizardData[key]}
                    </span>
                  )}
                  <div className={styles.editColumn}>
                    <button
                      type="button"
                      className={styles.editIcon}
                      onClick={() => handleEdit(key)}
                    >
                      <EditIcon />
                    </button>
                  </div>
                </div>
              ))}
            </form>
          </div>

          {/* Comment Section */}
          {!isApproved &&
            <div className={styles.commentContent}>
              <CommentBox
                onCommentChange={handleCommentChange}
                isCustomerView={isCustomerView}
                showTitle={true} />
            </div>
          }
          <div className={`${styles.footerButtons} p-6 border-t border-solid border-blueGray-200 rounded-b`}>
            <button className={[styles.button, styles.cancelBtn].join(' ')} onClick={onClose} disabled={processing}>Close</button>
            <button className={styles.acceptBtn} onClick={confirm} disabled={processing}>
              {!processing ? "Confirm" : <Spinner spinning={processing} color="#d1d5db" size={{ width: "22px", height: "22px" }} />}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ChangeLayerName;