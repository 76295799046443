//here's the query data for effect data: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/playerperfect-wizard-config.js#L21
//and here the post processing to get effect data: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/data/post-process.js#L35
//changes in the effect data seem rare so we'll have a fixed map instead of querying each time
//all in all the code executed goes like this:

// async function main() {
//   const result = {}
//   const wizardIds = ["PlayerPerfect","PreSpacedText","PreSpacedNumbers"]
//   for(let wizardId of wizardIds) {
//     const query = encodeURI(`[{"type":"EFFECT","template":["\${EffectType}|\${EffectStyle}|\${EffectTypeDescription}|\${EffectStyleDescription}"],"filter":["=","\${WizardID}","${wizardId}"]}]`)
//     const resp = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//       "headers": {
//         "accept": "application/json, text/javascript, */*; q=0.01",
//         "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//         "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//       },
//       "body": "QueryJSON=" + query,
//       "method": "POST"
//     })
//     const jsonResp = await resp.json()
//     var data=jsonResp[0]
//     const map = {}
//         for (var i = 0; i < data.length; i++) {
//         var bits = data[i].split("|");

//         if (!map[bits[0]]) {
//             map[bits[0]] = {
//                 code: bits[0],
//                 description: bits[2],
//                 styles: {}
//             };
//         }
//         map[bits[0]].styles[bits[1]] = {
//             code: bits[1],
//             description: bits[3]
//         };
//     }
//     result[wizardId] = map
//   }
//   console.log(result)
// }
// main().catch(err => console.error(err))


//here's the resulting data for size descriptions

import EffectDataPerProductName from './GenericEffectDataByProductName.json'

export const EffectStep:(wizardData:any,editParams:any,playerProductData:any,wizardId:string)=>{name:string, detail:string}|null = (_wizardData:any,_editParams:any,playerProductData:any,wizardId) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/effect.js#L119
  /* only if step is complete, return structure defining "selections" for step  otherwise return null*/
  var result = null;
  if (playerProductData.ProductType == "none") {
      return result;
  } else if (playerProductData.EffectType && playerProductData.EffectStyle) {
    const EffectData = EffectDataPerProductName[wizardId as keyof typeof EffectDataPerProductName]
    const EffectDataDescription = EffectData[playerProductData.EffectType as keyof typeof EffectData].description
    const EffectDataStyles = EffectData[playerProductData.EffectType as keyof typeof EffectData].styles
    result = { "name": "Effects", "detail": "Type: " + EffectDataDescription + "<br>" + "Style: " + EffectDataStyles[playerProductData.EffectStyle as keyof typeof EffectDataStyles].description };
  }
  return result
}