//same as CME
import ColorMap from './CmeMaterialColorColorMap.json'
import ColorRGB from './CmeMaterialColorColorRGB.json'
import MaterialMap from './CmeMaterialColorMaterialMap.json'

//values for helmet numbers
// async function main() {
//   const result = {}
//   const rgb = {}
//   const query = encodeURI(`[{"type":"HELMETNUMBER","template":["\${Material}|\${ColorID}|\${Color}|\${Hex}"]}]`)
//   const resp = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//     "headers": {
//       "accept": "application/json, text/javascript, */*; q=0.01",
//       "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//       "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//     },
//     "body": "QueryJSON=" + query,
//     "method": "POST"
//   })
//   const jsonResp = await resp.json()
//   var data=jsonResp[0]
//   for(var item of data) {
//     var bits=item.split('|')
//     rgb[bits[0]+bits[2]] = bits[3]
//     result[bits[2]] = bits[2]
//   }
//   console.log(rgb)
//   console.log(result)
// }
// main().catch(err => console.error(err))


export const MaterialColorStep:(wizardData:any,editParams:any,playerProductData:any,wizardId:string)=>{name:string, detail:string}|null = (_wizardData:any,_editParams:any,playerProductData:any,_wizardId) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/material-color.js#L638
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/static/pattern-thumbnails.js#L2
  const PatternThumbnails = 
  {
    '800': "background-image:url('https://assets.stahls.com/stahls/content/images/BlackGold_thumb.png');",
    '801': "background-image:url('https://assets.stahls.com/stahls/content/images/BrownPink_thumb.png');",
    '802': "background-image:url('https://assets.stahls.com/stahls/content/images/NavySilver_thumb.png');",
    '803': "background-image:url('https://assets.stahls.com/stahls/content/images/OrangeWhite_thumb.png');",
    '804': "background-image:url('https://assets.stahls.com/stahls/content/images/PinkBlack_thumb.png');",
    '805': "background-image:url('https://assets.stahls.com/stahls/content/images/leopard_thumb.png');",
    '806': "background-image:url('https://assets.stahls.com/stahls/content/images/zebra_thumb.png');",
    '807': "background-image:url('https://assets.stahls.com/stahls/content/images/madraspink2_thumb.png');",	
    '808': "background-image:url('https://assets.stahls.com/stahls/content/images/boxercraft-037_thumb.png');",
    '906': "background-image:url('https://assets.stahls.com/stahls/content/images/silver_thumb.png');",	
    '907': "background-image:url('https://assets.stahls.com/stahls/content/images/gold_thumb.png');"
  }

  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/material-color.js#L12
  const GetColorRGB = function(matCode:string, colorCode:string) {
    return ColorRGB[matCode + colorCode as keyof typeof ColorRGB] ? ColorRGB[matCode + colorCode as keyof typeof ColorRGB] : ColorRGB[colorCode as keyof typeof ColorRGB];
  }

  var details: string|null = null;
  switch (playerProductData.Layer) {//we also merge these cases here: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cc-material-color.js#L601
      case "foreground":
          if (playerProductData.ForegroundMaterialCode && playerProductData.ForegroundMaterialColor) {
              details = "Foreground: "
                      + MaterialMap[playerProductData.ForegroundMaterialCode as keyof typeof MaterialMap] + "<br />"
                      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(playerProductData.ForegroundMaterialCode, playerProductData.ForegroundMaterialColor) + ';' + PatternThumbnails[playerProductData.ForegroundMaterialColor as keyof typeof PatternThumbnails] + '"></div><span class="colorName">'
                      + ColorMap[playerProductData.ForegroundMaterialColor as keyof typeof ColorMap] + '</span>';
          }
          break;
      case "background":
          if (playerProductData.BackgroundMaterialCode && playerProductData.BackgroundMaterialColor) {
              details = "Background: "
                      + MaterialMap[playerProductData.BackgroundMaterialCode as keyof typeof MaterialMap] + "<br /> "
                      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(playerProductData.BackgroundMaterialCode, playerProductData.BackgroundMaterialColor) + ';' + PatternThumbnails[playerProductData.BackgroundMaterialColor as keyof typeof PatternThumbnails] + '"></div><span class="colorName">'
                      + ColorMap[playerProductData.BackgroundMaterialColor as keyof typeof ColorMap] + '</span>';
          }
          break;
      case "foreground_and_background":
      case "both":
          if (playerProductData.ForegroundMaterialCode && playerProductData.ForegroundMaterialColor && 
            playerProductData.BackgroundMaterialCode && playerProductData.BackgroundMaterialColor) {

              details = "Foreground: "
                      + MaterialMap[playerProductData.ForegroundMaterialCode as keyof typeof MaterialMap] + "<br />"
                      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(playerProductData.ForegroundMaterialCode, playerProductData.ForegroundMaterialColor) + ';' + PatternThumbnails[playerProductData.ForegroundMaterialColor as keyof typeof PatternThumbnails] + '"></div><span class="colorName">'
                      + ColorMap[playerProductData.ForegroundMaterialColor as keyof typeof ColorMap] + '</span>'
                      + '<br />'
                      + "Background: "
                      + MaterialMap[playerProductData.BackgroundMaterialCode as keyof typeof MaterialMap] + "<br />"
                      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(playerProductData.BackgroundMaterialCode, playerProductData.BackgroundMaterialColor) + ';' + PatternThumbnails[playerProductData.BackgroundMaterialColor as keyof typeof PatternThumbnails] + '"></div><span class="colorName">'
                      + ColorMap[playerProductData.BackgroundMaterialColor as keyof typeof ColorMap] + '</span>';
          }
          break;
        case "background1":
          if (playerProductData.BackgroundMaterialCode && playerProductData.BackgroundMaterialColor) {
              details = "2<sup>nd</sup> Layer Background: "
                      + MaterialMap[playerProductData.BackgroundMaterialCode as keyof typeof MaterialMap] + "<br /> "
                      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(playerProductData.BackgroundMaterialCode, playerProductData.BackgroundMaterialColor) + ';' + PatternThumbnails[playerProductData.BackgroundMaterialColor as keyof typeof PatternThumbnails] + '"></div><span class="colorName">'
                      + ColorMap[playerProductData.BackgroundMaterialColor as keyof typeof ColorMap] + '</span>';
          }
          break;
      case "background2":
          if (playerProductData.BackgroundMaterial2Code && playerProductData.BackgroundMaterial2Color) {
              details = "3<sup>rd</sup> Layer Background: "
                      + MaterialMap[playerProductData.BackgroundMaterial2Code as keyof typeof MaterialMap] + "<br /> "
                      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(playerProductData.BackgroundMaterial2Code, playerProductData.BackgroundMaterial2Color) + ';' + PatternThumbnails[playerProductData.BackgroundMaterial2Color as keyof typeof PatternThumbnails] + '"></div><span class="colorName">'
                      + ColorMap[playerProductData.BackgroundMaterial2Color as keyof typeof ColorMap] + '</span>';
          }
          break;
      case "2layer":
          if (playerProductData.ForegroundMaterialCode && playerProductData.ForegroundMaterialColor && 
            playerProductData.BackgroundMaterialCode && playerProductData.BackgroundMaterialColor) {

              details = "Foreground: "
                      + MaterialMap[playerProductData.ForegroundMaterialCode as keyof typeof MaterialMap] + "<br />"
                      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(playerProductData.ForegroundMaterialCode, playerProductData.ForegroundMaterialColor) + ';' + PatternThumbnails[playerProductData.ForegroundMaterialColor as keyof typeof PatternThumbnails] + '"></div><span class="colorName">'
                      + ColorMap[playerProductData.ForegroundMaterialColor as keyof typeof ColorMap] + '</span>'
                      + '<br />'
                      + "2<sup>nd</sup> Layer Background: "
                      + MaterialMap[playerProductData.BackgroundMaterialCode as keyof typeof MaterialMap] + "<br />"
                      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(playerProductData.BackgroundMaterialCode, playerProductData.BackgroundMaterialColor) + ';' + PatternThumbnails[playerProductData.BackgroundMaterialColor as keyof typeof PatternThumbnails] + '"></div><span class="colorName">'
                      + ColorMap[playerProductData.BackgroundMaterialColor as keyof typeof ColorMap] + '</span>';
          }
          break;
      case "3layer":
          if (playerProductData.ForegroundMaterialCode && playerProductData.ForegroundMaterialColor && 
              playerProductData.BackgroundMaterialCode && playerProductData.BackgroundMaterialColor &&
              playerProductData.BackgroundMaterial2Code && playerProductData.BackgroundMaterial2Color) {

              details = "Foreground: "
                      + MaterialMap[playerProductData.ForegroundMaterialCode as keyof typeof MaterialMap] + "<br />"
                      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(playerProductData.ForegroundMaterialCode, playerProductData.ForegroundMaterialColor) + ';' + PatternThumbnails[playerProductData.ForegroundMaterialColor as keyof typeof PatternThumbnails] + '"></div><span class="colorName">'
                      + ColorMap[playerProductData.ForegroundMaterialColor as keyof typeof ColorMap] + '</span>'
                      + '<br />'
                      + "2<sup>nd</sup> Layer Background: "
                      + MaterialMap[playerProductData.BackgroundMaterialCode as keyof typeof MaterialMap] + "<br />"
                      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(playerProductData.BackgroundMaterialCode, playerProductData.BackgroundMaterialColor) + ';' + PatternThumbnails[playerProductData.BackgroundMaterialColor as keyof typeof PatternThumbnails] + '"></div><span class="colorName">'
                      + ColorMap[playerProductData.BackgroundMaterialColor as keyof typeof ColorMap] + '</span>'
                      + '<br />'
                      + "3<sup>rd</sup> Layer Background: "
                      + MaterialMap[playerProductData.BackgroundMaterial2Code as keyof typeof MaterialMap] + "<br /> "
                      + '<div class="previewColorSwatch preview-color-swatch" style="background-color: #' + GetColorRGB(playerProductData.BackgroundMaterial2Code, playerProductData.BackgroundMaterial2Color) + ';' + PatternThumbnails[playerProductData.BackgroundMaterial2Color as keyof typeof PatternThumbnails] + '"></div><span class="colorName">'
                      + ColorMap[playerProductData.BackgroundMaterial2Color as keyof typeof ColorMap] + '</span>';
          }
          break;
}

  if (details) {
      return {
          "name": "Material & Color",
          "detail": details
      };
  }

  return null;
}