import React, { useEffect, useState } from 'react'
import { getBackendApiUrl } from "../../../config"
import { generateDownloadOriginalArtworkURL, generateImageSrcFromArtwork } from '../../../utils/general'
import { DEFAULT_IMAGE } from '../../../config'
import Carousel from '../../../components/carousel/Carousel'
import CustomModal from '../../../components/customMessageModal/customMsgModal'
import { ArtworkType } from '../../../types/api-types'
import { downloadCxCoreOriginalFile, downloadProductionFile, getFileExtension } from '../../../utils/download-file'
import { updateDesignDetails } from '../../../models/app-actions'
import { ArtworkFolder } from '../../../types/art-status'
import { addAuthorizationHeader } from '../../../api/network'
import { getFileNameExtension } from '../../../utils/artDetailsUtils'

// Styles
import styles from './ImageSelector.module.css'

type ImageSelectorProps = {
  className?: string;
  disabledButtonClass?: string;
  alt?: string;
  isAdmin?: boolean;
  documentId: string;
  onImageSelect?: (url: string) => void
  imageType: ArtworkFolder
  imageData: ArtworkType | null;
  isEasyView?: boolean;
  docType?: string;
}

export function DownloadIcon() {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#353432"
      height="24px"
      width="24px"
    >
      <path d="M11 14.59V3a1 1 0 012 0v11.59l3.3-3.3a1 1 0 011.4 1.42l-5 5a1 1 0 01-1.4 0l-5-5a1 1 0 011.4-1.42l3.3 3.3zM3 17a1 1 0 012 0v3h14v-3a1 1 0 012 0v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3z" />
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg
      fill='none'
      viewBox="0 0 24 24"
      height="24px"
      width="24px" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
    </svg >
  );
}

export function EditIcon() {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      width="24px" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
    </svg>
  );
}

const getPreviewUrl = (imageData: ArtworkType | null, selectedIndex: number) => {
  if (imageData && imageData[selectedIndex]) {
    return generateImageSrcFromArtwork(imageData[selectedIndex], DEFAULT_IMAGE);
  }
  return DEFAULT_IMAGE;
}

const getDownloadUrl = (imageData: ArtworkType | null, selectedIndex: number) => {
  //if cadworx document
  if (imageData != null && imageData[selectedIndex] && imageData[selectedIndex].documentId) {
    //cphillips: it appears this isn't used for cadworx documents, so this does nothing
    return DEFAULT_IMAGE;
  } else if (imageData != null) {
    return generateDownloadOriginalArtworkURL(imageData[selectedIndex], DEFAULT_IMAGE);
  }
  return DEFAULT_IMAGE;
}

const getFileName = (imageData: ArtworkType | null, selectedIndex: number) => {
  if (imageData != null && imageData[selectedIndex] && imageData[selectedIndex].documentId) {  //if this is cadworx document
    return imageData[selectedIndex].documentId;
  } else if (imageData != null && imageData[selectedIndex] && imageData[selectedIndex].userFileId) { //if this is a user file
    return imageData[selectedIndex].userFileId;
  }
  return DEFAULT_IMAGE;
}

const ImageSelector: React.FC<ImageSelectorProps> = (props) => {
  const documentId = props.documentId
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [openDeleteFileModal, setOpenDeleteFileModal] = useState<boolean>(false)
  const [modalProcessing, setModalProcessing] = useState<boolean>(false)
  const [selectdImageIndex, setSelectedImageIndex] = useState<number>(0)
  const isLNTransfer = props?.docType === "LNTransfer"

  useEffect(() => {
    if (props.imageData && props.imageData.length > 0) {
      setSelectedImageIndex(0);
      const initialImage = getPreviewUrl(props.imageData, 0);
      if (props.onImageSelect) {
        props.onImageSelect(initialImage);
      }
    } else {
      if (props.onImageSelect) {
        props.onImageSelect(DEFAULT_IMAGE);
      }
    }
  }, [props.imageData]);

  const selectedImage = getPreviewUrl(props.imageData, selectdImageIndex)
  const selectedImageDownload = getDownloadUrl(props.imageData, selectdImageIndex)
  const selectedImageDownloadName = getFileName(props.imageData, selectdImageIndex)

  const slides = props.imageData?.map((item, index, array) => {
    const userFileId = item?.userFileId;
    const fileType = props?.isEasyView ? "CADX" : (userFileId ? getFileNameExtension(userFileId) : '');

    if (props.imageType == "originalArtwork") {
      return {
        title: "Original Artwork",
        subtitle: `${index + 1} of ${array.length}`,
        image: generateImageSrcFromArtwork(item, DEFAULT_IMAGE),
        originalFileName: item?.originalFileName,
        type: props.imageType,
        fileType
      }
    } else {
      return {
        title: props.imageType == "productionArtwork" ? "Production Artwork" : "Proof Artwork",
        subtitle: `${index + 1} of ${array.length}`,
        image: generateImageSrcFromArtwork(item, DEFAULT_IMAGE),
        type: props.imageType,
        fileType
      }
    }
  })

  const deleteProductionFile = async (wizardDocId: string, fileId: string) => {
    setModalProcessing(true);
    try {
      const headers = {
        'Content-Type': 'application/json'
      };

      const processPayload = { wizardDocId, fileId };
      const processResp = await fetch(`${getBackendApiUrl()}/deleteArtwork`, {
        method: 'POST',
        headers: addAuthorizationHeader(headers),
        body: JSON.stringify(processPayload)
      });

      if (!processResp.ok) {
        console.error('Error during blob delete:', processResp.statusText);
      }
      else {
        await updateDesignDetails()
      }
    } catch (error) {
      console.error('Error during blob delete:', error);
    } finally {
      setModalProcessing(false);
      setOpenDeleteFileModal(false);
    }
  }

  const handleImageLoaded = () => {
    setIsLoading(false);
  }

  const handleImageError = () => {
    setIsLoading(false);
    setHasError(true);
  }

  const handleSlideChange = (newIndex: number) => {
    setSelectedImageIndex(newIndex);
    const newSelectedImage = getPreviewUrl(props.imageData, newIndex);
    if (props.onImageSelect) {
      props.onImageSelect(newSelectedImage);
    }
  }

  if (selectedImage === null) {
    return <div>Preview Not Available</div>;
  }

  // Combine class names
  const combinedClassName = [styles.imgDetails, styles.active, props.className].join(' ');

  const isValidUrl = (url: string) => {
    try {
      const { hostname, pathname } = new URL(url);
      return (
        hostname === 'dyn.stahls.com' &&
        (pathname === '/api/template/render.ashx' || pathname === '/api/cme/render.ashx' || pathname === '/nn/api/namenumber/render.ashx')
      );
    } catch {
      return false;
    }
  };

  const getRedirectUrl = (url: string, imageType: string) => {
    const parsedUrl = new URL(url);
    const { pathname, searchParams } = parsedUrl;

    if (pathname === '/api/template/render.ashx') {
      return imageType === 'productionArtwork'
        ? window.location.href = `${url}&Export=true&Type=pdf` :
        window.open(url, '_blank');
    }

    if (pathname === '/api/cme/render.ashx' || pathname === '/nn/api/namenumber/render.ashx') {
      const outputParam = searchParams.get('Output');
      if (outputParam) {
        const type = imageType === 'productionArtwork' ? ',Type:pdf,Download:true' : '';
        const updatedOutput = outputParam.replace(/\)$/, `${type})`);
        searchParams.set('Output', updatedOutput);
        return type ? window.location.href = parsedUrl.toString() : window.open(url, '_blank');
      }
    }
  };

  const handleDownload = () => {
    if (selectedImage !== DEFAULT_IMAGE) {
      if (props.imageData && props.imageData.length > 0) {
        //cphil: Fixed but that causes cadx documents to not download
        const fileInfo = props.imageData.find(
          item => item.userFileId === selectedImageDownloadName
            || item.documentId === selectedImageDownloadName
        );

        if (fileInfo) {
          const fileRole: string | null = fileInfo.fileRole;
          const extension = selectedImageDownloadName ? getFileExtension(selectedImageDownloadName) : '';
          if ("documentId" in fileInfo)
            downloadCxCoreOriginalFile(fileInfo);
          else
            downloadProductionFile(selectedImageDownload || selectedImage, fileRole, props.imageType, extension);
        } else {
          const { imageData } = props;
          const imageInfo = imageData[selectdImageIndex] as { url?: string };
          if (isLNTransfer && imageInfo?.url && isValidUrl(imageInfo.url)) {
            getRedirectUrl(imageInfo.url, props.imageType);
          }
        }
      }
    }
  }

  const downloadButton = (
    <>
      <div className={styles.btnDownload}>
        <button
          type="button"
          onClick={handleDownload}
          className={(selectedImage !== DEFAULT_IMAGE) ? '' : props.disabledButtonClass}
        >
          <DownloadIcon />
        </button>
      </div>
      {(props.imageType !== "originalArtwork") &&
        <div className={styles.btnDownload}>
          <button
            type="button"
            onClick={() => { setOpenDeleteFileModal(true) }}
            className={(selectedImage !== DEFAULT_IMAGE) ? '' : props.disabledButtonClass}
          >
            <DeleteIcon />
          </button>
        </div>
      }
    </>
  )

  return (
    <>
      {selectedImageDownloadName && <CustomModal
        isOpen={openDeleteFileModal}
        onClose={() => setOpenDeleteFileModal(false)}
        message="Are you sure you want to delete this image?"
        onConfirm={() => (selectedImage !== DEFAULT_IMAGE) ? deleteProductionFile(documentId, selectedImageDownloadName) : void 0}
        processing={modalProcessing}
        confirmButtonText="Confirm"
        showCancelButton={false}
        isCustomerView={!props.isAdmin}
      />}
      <div className={[styles.imageSelectorWrapper,
      (isLoading && !props.imageData && selectedImage !== DEFAULT_IMAGE) ? styles.skeletonImg : styles.loaded].join(' ')}>
        {!hasError && (Array.isArray(props.imageData) && props.imageData.length > 0 ? (
          <Carousel
            slides={slides}
            selectedIndex={selectdImageIndex}
            onSlideChange={handleSlideChange}
            isAdmin={props.isAdmin}
            downloadButton={downloadButton}
          />) :
          (
            <img src={DEFAULT_IMAGE}
              alt={props.alt}
              className={[combinedClassName, isLoading ? styles.skeletonImg : styles.loaded].join(' ')}
              onLoad={handleImageLoaded}
              onError={handleImageError}
              style={{ display: isLoading ? 'none' : 'block', height: "512px" }}
            />
          ))}
      </div>
    </>
  );
};

export default ImageSelector;