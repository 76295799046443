import { ArtStates } from '../../types/art-status';
import { formatRedeable } from '../../utils/general';

// Styles
import styles from './noResults.module.css';

const SearchIcon = () => (
  <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
  </svg>
);

const EmptyIcon = () => (
  <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
  </svg>
);

interface NoResultsProps {
  isSearch: boolean;
  searchText: string;
  artStatusFilter?: ArtStates;
}

const NoResults: React.FC<NoResultsProps> = ({ isSearch, searchText, artStatusFilter }) => {
  const message = isSearch ? (
    <>
      <SearchIcon />
      <h1 className={styles.title}><b>No Results Found</b></h1>
      <h2 className={styles.subTitle}>We couldn't find any matches for <b>{searchText}</b></h2>
      <span className={styles.message}>Please try searching with another term</span>
    </>
  ) : (
    <>
      <EmptyIcon />
      <h1 className={styles.title}><b>No Designs Available</b></h1>
      <h2 className={styles.subTitle}>
        There are currently no designs
        {artStatusFilter && artStatusFilter !== ArtStates.ALL && (
          <> in the <b>{formatRedeable(ArtStates[artStatusFilter])}</b> status</>
        )}
      </h2>
    </>
  );

  return <div className={styles.noResults}>{message}</div>;
};

export default NoResults;
