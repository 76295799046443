//same as CmeSize, code extracted from
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L369
//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/data/post-process.js#L1155

//code goes as follows for Standard

// fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//   "headers": {
//     "accept": "application/json, text/javascript, */*; q=0.01",
//     "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//     "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//   },
//   "body": "QueryJSON=%5B%7B%22type%22%3A%22SEWDISK%22%2C%22template%22%3A%7B%22%24%7BMaterial%7D%22%3A%5B%22%24%7BStitchType%7D%7C%24%7BStitchTypeDescription%7D%7C%24%7BItemNumber%7D%7C%24%7BCustomType%7D%22%5D%7D%2C%22filter%22%3A%5B%22and%22%2C%5B%22%3D%22%2C%22%24%7BWizardID%7D%22%2C%22CustomMadeEasy%22%5D%2C%5B%22%3D%22%2C%22%24%7BProductName%7D%22%2C%22Standard%22%5D%5D%7D%5D",
//   "method": "POST"
// }).then(resp => resp.json())
// .then(jsonResp => {
// var data=jsonResp[0]
//   var results = {};
//   for (var key in data) {
//       results[key] = [];
//       for (var i = 0; i < data[key].length; i++) {
//           var bits = data[key][i].split("|");
//           results[key].push({
//               "StitchType": bits[0],
//               "StitchTypeDescription": bits[1],
//               "StitchTypeName": bits[1] + " Stitch",
//               //"ItemNumber": sku ? sku : bits[2],
//               //"ImageURL": wizard.StitchTypeImages[bits[0]],
//               "CustomType": bits[3] ? bits[3] : ""
//           });
//       }
//   }
//     console.log(results);

// })

import sewDiskResultByProductName from './CmeSewDiskResultByProductName.json'

export const CmeSewDisk:(wizardData:any)=>{name:string, detail:string} = (wizardData:any) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cme/sewdisk.js#L161

  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1120
  var sewDisk = wizardData.disk == "yes"


  var details = ["Sew File: " + (sewDisk ? "Yes" : "No")];

  if (sewDisk) {
    const sewDiskResult:{[key:string]:{StitchType:string,StitchTypeDescription:string,StitchTypeName:string,CustomType:string}[]} = sewDiskResultByProductName[wizardData.CMETYPES as keyof typeof sewDiskResultByProductName]
    if(!sewDiskResult) throw new Error(`Missing CmeSewDiskResultByProductName.json for ${wizardData.CMETYPES}`)
    const foregroundDisks = sewDiskResult[wizardData.material1 as keyof typeof sewDiskResult]
    const backgroundDisks = sewDiskResult[wizardData.material2 as keyof typeof sewDiskResult]
    const background2Disks = sewDiskResult[wizardData.material3 as keyof typeof sewDiskResult]
    const foregroundStitchTypeDesc = foregroundDisks.find(x => x.StitchType == wizardData.stitch)?.StitchTypeDescription
    const backgroundStitchTypeDesc = backgroundDisks?.find(x => x.StitchType == wizardData.stitch2)?.StitchTypeDescription
    const background2StitchTypeDesc = background2Disks?.find(x => x.StitchType == wizardData.stitch3)?.StitchTypeDescription

    details.push("Foreground Stitch Type: " + foregroundStitchTypeDesc);
    if (!!backgroundStitchTypeDesc) details.push("2nd Color Background Stitch Type: " + backgroundStitchTypeDesc);
    if (!!background2StitchTypeDesc) details.push("3rd Color Background Stitch Type: " + background2StitchTypeDesc);

    details.push("File Format: " + wizardData.format);
    details.push("Applique Stop: " + wizardData.applique);
    details.push("Quilted Garment: " + (wizardData.Quilted ? "Yes" : "No"));
    details.push("Name Drop: " + (wizardData.namedrop == "yes" ? "Yes" : "No"));

    if (wizardData.namedrop == "yes") {
        details.push("Name Drop Text: " + wizardData.namedroptext);
        details.push("Name Drop Position: " + wizardData.namedropposition);
        details.push("Name Drop Font: " + wizardData.namedroptype);
    }
  }

return {'name': "Sew File options", 'detail': details.join("<br />")}
}
