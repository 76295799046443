interface FormattedDateProps {
  dateString: string;
  format?: 'default' | 'MM/DD/YYYY';
}

const formatDateString = ({ dateString, format = 'default' }: FormattedDateProps): string => {
  const date = new Date(dateString);

  if (format === 'MM/DD/YYYY') {
    // Format: MM/DD/YYYY
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  // Format Mon DD YYYY HH:mm AM/PM
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  return `${monthNames[date.getMonth()]} ${date.getDate()} ${date.getFullYear()} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
};

export default formatDateString;


// How to use it
// const defaultFormattedDate = formatDateString({ dateString: originalDate });
// const customFormattedDate = formatDateString({ dateString: originalDate, format: 'MM/DD/YYYY' });
