import React, { MouseEventHandler, useState } from 'react';
import { getBackendApiUrl } from "../../../config";
import Spinner from './spinner';

// Styles
import styles from './weiNotesModal.module.css';
import './status.module.css';
import { DesignDoc } from '../../../types/api-types';
import { addAuthorizationHeader } from '../../../api/network';

// Defining the prop types
interface ProductionSizeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onWEINotesUpdateSuccess: () => void;
  document?: DesignDoc;
}

const WEINotesModal: React.FC<ProductionSizeModalProps> = ({ isOpen, onClose, onWEINotesUpdateSuccess, document }) => {
  const [WEINotes, setWEINotes] = useState(document?.wizardData?.WeiDesignNotes ?? "")
  const [processing, setProcessing] = useState(false)

  const handleChangeWEINotes: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setProcessing(false);

    const headers = addAuthorizationHeader({
      'Content-Type': 'application/json'
    });

    const designProductionSizePayload: { id: string, notes: string } = { id: document?.id as string, notes:WEINotes }

    setProcessing(true);
    fetch(`${getBackendApiUrl()}/DesignWEINotes`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(designProductionSizePayload)
    }).then(async (processResp) => {
      if (!processResp.ok) throw new Error("Error changing WEI Notes " + await processResp.text())
      const respAsDoc: DesignDoc = await processResp.json()
      setWEINotes(respAsDoc.wizardData?.WeiDesignNotes)
      setProcessing(false);
      onWEINotesUpdateSuccess();
    }).catch(err => {
      alert("Failed to set design WEI notes: " + (err as Error).message)
      setProcessing(false);
    })
  };

  function CloseIcon() {
    return (
      <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span className="sr-only">Close menu</span>
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    );
  }

  return isOpen ? (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          <div className={[styles.header, styles.addNoteHeader].join(' ')}>
            <span>Set WEI Notes</span>
            <div className={styles.closeIcon} onClick={() => { setWEINotes(document?.wizardData?.WeiDesignNotes ?? ""); onClose(); }}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.addModalContent}>
            <textarea
              className={styles.commentArea}
              cols={85} rows={10} onChange={(ev) => { setWEINotes(ev.currentTarget.value) }} value={WEINotes}></textarea>
          </div>
          <div className={`${styles.footerButtons} p-6 border-t border-solid border-blueGray-200 rounded-b`}>
            <button className={[styles.button, styles.cancelBtn].join(' ')} onClick={() => { setWEINotes(document?.wizardData?.WeiDesignNotes ?? ""); onClose(); }} disabled={processing}>Close</button>
            <button className={styles.acceptBtn} onClick={handleChangeWEINotes} disabled={processing}>
              {!processing ? "Change Notes" : <Spinner spinning={processing} color="#d1d5db" size={{ width: "22px", height: "22px" }} />}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default WEINotesModal;