  //same as CmeSize, code extracted from
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L10
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/data/post-process.js#L11
  
  //code goes as follows

  // fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
  //   "headers": {
  //     "accept": "application/json, text/javascript, */*; q=0.01",
  //     "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
  //     "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
  //   },
  //   "body": "QueryJSON=%5B%7B%22type%22%3A%22FONT%22%2C%22template%22%3A%5B%22%24%7BFont%7D%7C%24%7BFontDescription%7D%7C%24%7BFontPath%7D%7C%24%7BGreek%7D%22%5D%2C%22filter%22%3A%5B%22%3D%22%2C%22%24%7BWizardID%7D%22%2C%22CustomMadeEasy%22%5D%7D%5D",
  //   "method": "POST"
  // }).then(resp => resp.json())
  // .then(jsonResp => {
  // var data=jsonResp[0]
  //     var map = {};
  //     for (var i = 0; i < data.length; i++) {
  //         var bits = data[i].split("|");
  //         map[bits[0]] = bits[1];
  //     }
  
  //     console.log(map);
  
  // })
import fontMap from './CmeTextFontFontMap.json'

export const CmeTextFont:(wizardData:any)=>{name:string, detail:string} = (wizardData:any) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/cme/text.js#L362
  var details = [];

  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1061
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1062
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1056
  details.push("Text: " + wizardData.toptext);

  if (!!wizardData.bottomtext && wizardData.bottomtext != "") {             
      details.push("2nd Line Text: " + wizardData.bottomtext);
  }

  details.push("Font: " + fontMap[wizardData.font as keyof typeof fontMap]);

  var o = { 
      name: "Text and Font",
      detail: details.join("<br />")
  }   

  return o;
}
