import { useLayoutEffect, useRef, useState } from 'react';
import { getValue, renderValue } from '../../../components/cardDetails/valueRender';
import mapDataToCards, { mediaMap } from '../../../utils/artDetailsUtils';
import { getQueryParamValue } from '../../../utils/general';
import useDivHeights from '../../../utils/useDivHeights';

// Styles
import styles from './info.module.css';

interface Attachment {
  name: string;
  size: string;
  downloadLink: string;
}

interface InfoProps {
  about?: string;
  attachments?: Attachment[];
  isLNTransfer: boolean;
  isEasyView: boolean;
  [key: string]: any;
}

export default function Info({ attachments, about, isLNTransfer, isEasyView, ...restProps }: InfoProps) {
  const artIdParam = getQueryParamValue("artId");

  const mediaText = (mediaMap[restProps.media as keyof typeof mediaMap] ?? "").trim()
  restProps = Object.assign({ mediaText }, restProps)

  const artNotes: string = restProps['artNotes'];
  let mappedCards = mapDataToCards(isLNTransfer, isEasyView);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);
  const artNotesRef = useRef<HTMLDivElement>(null);
  const [artNotesHeight, setArtNotesHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (artNotesRef.current) {
      const spanHeight = artNotesRef.current.scrollHeight;
      setArtNotesHeight(spanHeight);
    }
  }, [artNotes])

  //Only keep the mapped card if it's going to show data
  mappedCards = mappedCards.filter((cardConfig) => {
    return cardConfig.properties.some((property) => {
      const value = getValue(property, restProps, artIdParam)
      const displayValue = renderValue(value)
      return displayValue !== null
    })
  })

  const { refs, heights } = useDivHeights(mappedCards?.length);
  const maxHeight = Math.max(...heights);

  function checkMappedCards() {
    const totalColumns = mappedCards?.length + (artNotes ? 1 : 0);

    if (totalColumns === 4) {
      return styles.fourColumns;
    } else if (totalColumns === 3) {
      return styles.threeColumns;
    } else if (totalColumns === 2) {
      return styles.twoColumns;
    } else {
      return styles.oneColumn;
    }
  }

  return (
    <>
      <div className={styles.infoWrapper}>
        {mappedCards.map((cardConfig: any, index: number) => (
          <div key={index} className={[styles.card, checkMappedCards()].join(' ')}>
            <h3 className={styles.title}>{cardConfig.title}</h3>
            <div className={styles.cardBody}
              ref={el => (refs.current[index] = el!)}>
              {cardConfig.properties.map((property: any) => {
                const value = getValue(property, restProps, artIdParam);
                const displayValue = renderValue(value);

                if (displayValue === null) return null;

                return (
                  <div className={styles.details} key={property.key}>
                    <span className={styles.name}>{property.label}:</span>
                    <span className={styles.value}>{displayValue}</span>
                  </div>
                );
              })}
            </div>
          </div>
        ))}

        {/* Art Notes Column */}
        {artNotes && (
          <div className={[styles.card, checkMappedCards()].join(' ')}>
            <h3 className={styles.title}>ART NOTES</h3>
            <div
              style={{ maxHeight: !isExpanded ? maxHeight : "" }}
              className={`${styles.artNotesContainer} ${isExpanded ? styles.artNotesExpanded : ''}`}>
              <div className={styles.details} ref={artNotesRef}>
                <span

                  className={styles.value}>{artNotes}
                </span>
              </div>
            </div>
            {(artNotesHeight > maxHeight) &&
              <div onClick={toggleExpand} className={styles.artNotesToggleButton}>
                <span>{isExpanded ? '...view less' : '...view more'}</span>
              </div>
            }
          </div>
        )}
      </div>
    </>
  );
}