// Styles
import styles from './skeletonAnimation.module.css';

const SkeletonLoader = () => {
  return (
    <div className={styles.skeletonContainer}>
      <div className={styles.cardLoader}>
        <div className={styles.cardImage}></div>
        <div className={styles.cardContent}>
          <h2></h2>
          <p></p>
          <div className={styles.button}></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
