import React, { ChangeEventHandler, MouseEventHandler, useState, useEffect } from 'react';
import { getBackendApiUrl } from "../../../config";
import Spinner from './spinner';
import { DesignDoc } from '../../../types/api-types';
import CommentBox from '../../../components/commentBox/commentBox';
import { addAuthorizationHeader } from '../../../api/network';
import { InternalArtStates } from '../../../types/art-status';

// Styles
import styles from './productionSizeModal.module.css';
import './status.module.css';

// Defining the prop types
interface ProductionSizeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSizeChangeSuccess: (comment: string) => void;
  document?: DesignDoc;
  isCustomerView: boolean;
}

function isValidNumber(value: number): boolean {
  return !Number.isNaN(value) && Number.isFinite(value);
}

const ProductionSizeModal: React.FC<ProductionSizeModalProps> = ({ isOpen, onClose, onSizeChangeSuccess, document, isCustomerView }) => {
  const isApproved = document?.statusID == InternalArtStates.APPROVED;
  const isBanner = document?.wizardID == "Banners";
  const hasFontHeight = document?.wizardID == "CustomCutPreAlignedPTWLt" ||
    document?.wizardID == "CustomCutPreAlignedPTWNo" ||
    document?.wizardID == "SimStitchLetters" ||
    document?.wizardID == "SimStitchNumbers" ||
    document?.wizardID == "CustomCutNumbers" ||
    document?.wizardID == "CustomCutLetters";

  const initialProductionSize = isBanner ? { width: Number.parseFloat(document?.wizardData.bannerWidth), height: Number.parseFloat(document?.wizardData.bannerHeight) } :
    hasFontHeight ? { height: Number.parseFloat(document.wizardData.fontHeight) } :
      { width: Number.parseFloat(document?.wizardData.artWidth), height: Number.parseFloat(document?.wizardData.artHeight) };

  const [currentProductionSize, /*setCurrentProductionSize*/] = useState(initialProductionSize);
  const initialHeight = typeof currentProductionSize?.height == "undefined" ? "" : "" + currentProductionSize?.height;
  const initialWidth = typeof currentProductionSize?.width == "undefined" ? "" : "" + currentProductionSize?.width;
  const [height, setHeight] = useState(initialHeight);
  const [width, setWidth] = useState(initialWidth);
  const [processing, setProcessing] = useState(false);
  const heightAsFloat = Number.parseFloat(initialHeight);
  const widthAsFloat = Number.parseFloat(initialWidth);
  const isBlankHeight = height == "";
  const isBlankWidth = width == "";
  const isCorrectHeight = !Number.isNaN(heightAsFloat) && Number.isFinite(heightAsFloat) && heightAsFloat >= 0.01;
  const isCorrectWidth = !Number.isNaN(widthAsFloat) && Number.isFinite(widthAsFloat) && widthAsFloat >= 0.01;
  const isValidInput = ((isCorrectHeight && isCorrectWidth) || (isCorrectHeight && isBlankWidth) || (isCorrectWidth && isBlankHeight)) && typeof document?.id != undefined;
  const [comment, setComment] = useState<string>('');
  const [preserveAspectRatio, setPreserveAspectRatio] = useState(true); // Set to true by default

  // Initialize aspect ratio only once on the initial load
  const aspectRatio = widthAsFloat / heightAsFloat;

  const updateSizeBasedOnAspectRatio = () => {
    if (preserveAspectRatio) {
      const newWidthAsFloat = Number.parseFloat(height);
      const newWidth = (newWidthAsFloat * aspectRatio).toFixed(2);
      setWidth(newWidth);
    }
  };

  useEffect(() => {
    if (preserveAspectRatio) {
      updateSizeBasedOnAspectRatio();
    }
  }, [preserveAspectRatio]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onClose();
  };

  const handleChangeSize: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    setProcessing(false);

    const floatWidth = parseFloat(width);
    const floatHeight = parseFloat(height);


    try {

      if (!(isValidNumber(floatWidth) && isValidNumber(floatHeight))) {
        throw new Error("Invalid width or height");
      }

      const headers = addAuthorizationHeader({
        'Content-Type': 'application/json'
      });

      const body = {
        id: document?.id as string,
        productionSize:
        {
          width: floatWidth,
          height: floatHeight

        }
      }

      setProcessing(true);
      const result = await fetch(`${getBackendApiUrl()}/DesignProductionSize`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(body)
      })

      if (!result.ok) {
        throw new Error("Error changing design production size " + await result.text());
      }


      //const respAsDoc: DesignDoc = await result.json();

      // use the response from the server to overwrite the current production size
      // Cphillips: this code appears to have no effect.
      //setCurrentProductionSize(respAsDoc.wizardData?.productionSize);

      //CPhillips: this code is bugged, it sets input values to empty string ""
      //Cphillips: I assume it was trying to check, different values in case productionSize is not included.
      //CPhillips: it also seems unnecessary since we just set the source of truth and the server said Ok.
      // const currentHeight = typeof respAsDoc.wizardData?.productionSize?.height == "undefined" ? "" : "" + respAsDoc.wizardData?.productionSize?.height;
      // const currentWidth = typeof respAsDoc.wizardData?.productionSize?.width == "undefined" ? "" : "" + respAsDoc.wizardData?.productionSize?.width;
      // setHeight(currentHeight);
      // setWidth(currentWidth);

      onSizeChangeSuccess(comment);

    } catch (err) {
      alert("Failed to change production size: " + (err as Error).message);

    } finally {
      setProcessing(false);
    }
  };

  const handleHeightChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newHeight = event.target.value;
    const parsedHeight = parseFloat(newHeight);

    if (parsedHeight >= 0 || newHeight === "") {
      setHeight(newHeight);
      if (preserveAspectRatio && isCorrectHeight) {
        const newWidth = (parsedHeight * aspectRatio).toFixed(2);
        setWidth(newWidth);
      }
    } else {
      setHeight("0.01");
    }
  };

  const handleWidthChange: ChangeEventHandler<HTMLInputElement> = (event) => {

    const newWidth = event.target.value;
    const parsedWidth = parseFloat(newWidth);

    if (parsedWidth >= 0 || newWidth === "") {
      setWidth(newWidth);
      if (preserveAspectRatio && isCorrectWidth) {
        const newHeight = (parsedWidth / aspectRatio).toFixed(2);
        setHeight(newHeight);
      }
    } else {
      setWidth("0.01");
    }

  };

  const handleCommentChange = (value: string) => {
    setComment(value);
  };

  const handlePreserveAspectRatioChange = () => {
    setPreserveAspectRatio(!preserveAspectRatio);
  };

  function CloseIcon() {
    return (
      <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
        <span className="sr-only">Close menu</span>
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    );
  }

  return isOpen ? (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-3xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          <div className={styles.header}>
            <span>Modify Size</span>
            <div className={styles.closeIcon} onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.modalContent}>

            <form onSubmit={handleSubmit}>
              <label htmlFor="height">Height:</label>
              <input type='number' value={height} onChange={handleHeightChange} min='0.01' step='0.01' disabled={processing} />
              <label htmlFor="width">Width:</label>
              <input type='number' value={width} onChange={handleWidthChange} min='0.01' step='0.01' disabled={processing || hasFontHeight} />
            </form>
            <div className={styles.ratioWrapper}>
              <div className={styles.currentAr}>
                <label>Current Aspect Ratio:</label>
                <span>{ !hasFontHeight ? aspectRatio.toFixed(2) : "Font"}</span>
              </div>
              <label className={styles.otCheckbox}>
                <input
                  type="checkbox"
                  checked={preserveAspectRatio}
                  onChange={handlePreserveAspectRatioChange}
                  disabled={processing || hasFontHeight}
                />
                <span className={styles.checkmark}></span>
                Fix Aspect Ratio
              </label>
            </div>

            {/* Comment Section */}
            {!isApproved &&
              <CommentBox
                onCommentChange={handleCommentChange}
                isCustomerView={isCustomerView}
                showTitle={true} />
            }
          </div>
          <div className={`${styles.footerButtons} p-6 border-t border-solid border-blueGray-200 rounded-b`}>
            <button className={[styles.button, styles.cancelBtn].join(' ')} onClick={() => { setHeight(initialHeight); setWidth(initialWidth); onClose(); }} disabled={processing}>Close</button>
            <button className={styles.acceptBtn} onClick={handleChangeSize} disabled={processing || !isValidInput}>
              {!processing ? "Change Size" : <Spinner spinning={processing} color="#d1d5db" size={{ width: "22px", height: "22px" }} />}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ProductionSizeModal;
