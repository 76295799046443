import { useEffect, useRef, useState } from 'react'
import { useMedia } from '../../utils/match-media'
import useOutsideClick from '../../utils/use-outside-click'
import { updateDashboard } from '../../models/app-actions'
import appModel from '../../models/app-model'
import { useSnapshot } from 'valtio'
import { isMobile } from '../../utils/isMobile'

// Styles
import styles from './search-box.module.css'

interface SearchBoxProps {
  onReduceFilterSizeChange: (reduceFilterSize: boolean) => void;
}

export default function SearchBox({ onReduceFilterSizeChange }: SearchBoxProps) {
  const appSnap = useSnapshot(appModel);
  const [searchValue, setSearchValue] = useState<string>(appSnap.searchText ?? '');
  const [focusSearch, setFocusSearch] = useState<boolean>(false);
  const matchMedia = useMedia("(max-width: 965px)");
  const maxStatusWidth = useMedia("(max-width: 1065px)");
  const mobile = isMobile();
  const searchBarRef = useRef(null);
  const archived = appSnap.artStatusFilter === "ARCHIVED";
  const [_, setReduceFilterSize] = useState<boolean>(false);

  useEffect(() => {
    const newReduceFilterSize = maxStatusWidth && focusSearch;
    setReduceFilterSize(newReduceFilterSize);
    onReduceFilterSizeChange(newReduceFilterSize);
  }, [maxStatusWidth, focusSearch]);

  useOutsideClick(searchBarRef, () => {
    setFocusSearch(false);
  });

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value
    setSearchValue(searchText)
    updateDashboard({ searchText: searchText, archived })
  };

  const handleSearchButtonClick = () => {
    updateDashboard({ searchText: searchValue, archived });
  };

  return (
    <div className="self-center">
      <div className="mt-0 flex" ref={searchBarRef}>
        <div className="flex flex-grow items-stretch focus-within:z-10">
          <input
            style={{ color: (matchMedia && !focusSearch && !mobile) ? "transparent" : "#FFFFFF" }}
            type="search"
            name="search"
            id="search"
            className={[styles.searchBar, (focusSearch || mobile) ? styles.focusedSearch : styles.hideValue].join(' ')}
            placeholder={(focusSearch || mobile) ? 'Filename...' : ''}
            value={searchValue}
            onChange={handleSearchInputChange}
            onFocus={() => setFocusSearch(true)}
          />
          <button
            type="button"
            className={`${!focusSearch && styles.btnSearch}`}
            onClick={() => {
              if (!matchMedia)
                handleSearchButtonClick();
              else if (matchMedia && focusSearch)
                handleSearchButtonClick();
              else
                setFocusSearch(true);
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${styles.searchIcon} w-6 h-6`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}
