// Styles
import styles from './init-fail.module.css';

interface InitFailPageProps {
    subtitle?: string;
    message?: string;
    errorMsg?: string;
}

const InitFailPage: React.FC<InitFailPageProps> = (props) => {
    const error = props?.errorMsg;
    const subtitle = props?.subtitle ?? "An unexpected error occurred";
    const message = props?.message ?? "Please refresh and if you keep seeing this issue try contacting support.";

    return (
        <div className={styles.wrapper}>
            <div style={{ height: "38px", margin: "0.75rem" }} />
            <div className={styles.errorBox}>
                <h1 className={styles.title}><b>Uh-Oh!</b></h1>
                <h2 className={styles.subTitle}>{subtitle}</h2>
                <span className={styles.message}>{message}</span>
                {error &&
                    <span className={styles.errorDescription}><strong>Error description:</strong>
                        {error}
                    </span>
                }
                <button
                    className={styles.orangeBtn}
                    onClick={() => window.location.reload()}>
                    Reload Page
                </button>
            </div>
        </div>
    );
};

export default InitFailPage;