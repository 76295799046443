import { getBackendApiUrl, getCoreAPIUrl } from "../config";
import { ArtStates } from "../types/art-status";
import { ArtworkType, ProductionArtworkType } from '../types/api-types'
import { DEFAULT_IMAGE } from "../config";
import { DesignDoc } from "../types/api-types";

export const artTypes = ["productionArtwork", "productionArtworkPreview"];

export function formatRedeable(inputString?: string) {
  if (!inputString || inputString.length == 0)
    return undefined;
  const words = inputString.toLowerCase().split('_');
  const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const result = formattedWords.join(' ');

  return result;
}
export function truncateString(str: string, num: number) {
  if (str?.length > num) {
    return str.slice(0, num);
  } else {
    return str;
  }
}

export function getColor(artState: ArtStates) {

  switch (artState) {
    case ArtStates.REQUIRES_ACTION:
      return 'requireActionColor'
    case ArtStates.READY_TO_ORDER:
      return 'readyToOrderColor'
    case ArtStates.IN_PROGRESS:
      return 'inProgressColor'
    default:
      break
  }
}



// export function getReducedStatusName(statusId: string | number, statuses: IBCStatus[]) {
//   const statusName = statuses?.find(status => status.code == statusId)?.code || "";
//   return reduceStatus(statusName);
// }

export const reduceStatus = (status?: string) => {
  switch (status?.toUpperCase()) {
    case 'OPEN':
      return 'IN_PROGRESS';
    case 'PEND_PROOF':
      return 'REQUIRES_ACTION';
    case 'PEND_APPRO':
      return 'REQUIRES_ACTION';
    case 'APPROVED':
      return 'READY_TO_ORDER';
    case 'PEND_C':
      return 'REQUIRES_ACTION';
    case 'PEND_S_ART':
      return 'REQUIRES_ACTION';
    case 'PEND_S_CSR':
      return 'REQUIRES_ACTION';
    case 'PEND_S_OUT':
      return 'REQUIRES_ACTION';
    default:
      break;
  }
}

export function getQueryParamValue(name: string) {
  try {
    const searchParams = new URLSearchParams(window.location.search);

    for (const [key, value] of searchParams) {
      if (key.toLowerCase() === name.toLowerCase()) {
        return value;
      }
    }
    return null;
  } catch (error) {
    // Handle invalid URL or other errors
    console.error(`Error parsing URL: ${error}`);
    return null;
  }
}

export const convertDesignDocToImageObject = (
  designDoc: DesignDoc
): {  productionArtwork?: ProductionArtworkType, productionArtworkPreview?: ArtworkType, originalArtwork?: ArtworkType }  => {
  
  return {
    productionArtwork: designDoc.productionArtwork,
    productionArtworkPreview: designDoc.productionArtworkPreview,
    originalArtwork: designDoc.originalArtwork
  };
};

export const isURL = (str: string): boolean => {
  const pattern = new RegExp('^(https?:\\/\\/)' + // protocol
    '[\\w-]+(\\.[\\w-]+)+.*$', 'i'); // domain and everything after
  return pattern.test(str);
}

export const getImageObject = (
  artworks: { productionArtwork?: ProductionArtworkType, productionArtworkPreview?: ArtworkType, originalArtwork?: ArtworkType },
  defaultImage: typeof DEFAULT_IMAGE): typeof DEFAULT_IMAGE | ArtworkType => {
  const emptyListAsUndefined = <T,>(l?: Array<T>): Array<T> | undefined => l?.length ? l : undefined
  const sortedList = (l?: ArtworkType): ArtworkType | undefined => {
    const retVal = l ? [...l] : undefined
    const sortFunction = (a: ArtworkType[0], b: ArtworkType[0]) => {
      if ("url" in a && "url" in b) return 0
      if ("url" in a) return 1
      if ("url" in b) return -1
      return 0
    }
    retVal?.sort(sortFunction)
    return retVal
  }

  const fixList = (l?: ArtworkType): ArtworkType | undefined => sortedList(emptyListAsUndefined(l))
  return fixList(artworks.productionArtworkPreview) || fixList(artworks.originalArtwork) || fixList(artworks.productionArtwork) || defaultImage
};

export function generateSimpleArtworkURL(image: string) {
  const server = getBackendApiUrl();
  return decodeURIComponent(`${server}/thumbnail/${image}`);
}

export function generateImageSrcFromArtwork(artWork: { userFileConfig: string, userFileId: string, date: string, url?: string, documentId?: string, sessionId?: string } | undefined, defaultURL: string) {
  const userFileConfig = artWork?.userFileConfig;
  const userFileId = artWork?.userFileId;
  const documentId = artWork?.documentId;
  const sessionId = artWork?.sessionId ;
  
  if (documentId) {
    const modelData = encodeURIComponent(JSON.stringify({
      config: userFileConfig,
      Ident: {
        SessionId: sessionId,
        SuperUser: false
      },
      model: {
          ProcessST:true,
          Doc: {
              Id: documentId
          },
          Format: "png",
          Height: 512,
          Width: 512,
      }
    }));
    return `${getCoreAPIUrl()}/Core2/Doc/Thumbnail?${modelData}`;

  } else if (userFileConfig && userFileId) {
    const modelData = encodeURIComponent(JSON.stringify({
      config: userFileConfig,
      model: {
        Id: userFileId,
        width: 768,
        height: 768,
      },
    }));
    return `${getCoreAPIUrl()}/core2/userfiles/preview?${modelData}`;
  } else if (artWork?.url) {
    return artWork.url;
  }

  if (defaultURL) {
    return defaultURL;
  }

  throw new Error('No valid URL found and no default URL provided.');
}

export function generateDownloadOriginalArtworkURL(artWork: { userFileConfig: string, userFileId: string, date: string }, defaultURL: string) {
  const userFileConfig = artWork?.userFileConfig;
  const userFileId = artWork?.userFileId;

  if (userFileConfig && userFileId) {
    const modelData = encodeURIComponent(JSON.stringify({
      config: userFileConfig,
      model: {
        Id: userFileId
      },
    }));
    return `${getCoreAPIUrl()}/core2/userfiles/download?${modelData}`;
  }

  if (defaultURL) {
    return defaultURL;
  }

  throw new Error('No valid URL found and no default URL provided.');
}

export function generateProductionArtworkPreviewURL(obj: any, defaultURL: string) {
  const server = getBackendApiUrl();
  // Check if the object has the 'originalArtWork' field and if it's an object with a 'url' property.
  const parsedArtwork = obj['productionArtworkPreview'] !== undefined ? obj['productionArtworkPreview'] : undefined;
  if (parsedArtwork && typeof parsedArtwork === 'object' && parsedArtwork.url) {
    return decodeURIComponent(`${server}/thumbnail/${parsedArtwork.url}`);
  }
  // If none of the above conditions are met, return the default URL if provided.
  if (defaultURL) {
    return defaultURL;
  }

  throw new Error('No valid URL found and no default URL provided.');
}

export function generateArtworkURL(obj: any, defaultURL: string) {
  // Check if the object has the 'originalArtWork' field and if it's an object with a 'url' property.
  const parsedOriginalArtwork = obj['originalArtwork'] !== undefined ? obj['originalArtwork'] : undefined;
  if (parsedOriginalArtwork && typeof parsedOriginalArtwork === 'object' && parsedOriginalArtwork.url) {
    return decodeURIComponent(parsedOriginalArtwork.url);
  }

  const uploadId = obj['uploadId'];
  // Check if the object has the 'uploadId' field.
  if (uploadId) {
    // Construct the URL template using the 'uploadId'.
    return `https://www.stahls.ca/stahls/13/artworkdownload?artId=${obj.uploadId}&type=preview`;
  }

  // If none of the above conditions are met, return the default URL if provided.
  if (defaultURL) {
    return defaultURL;
  }

  // If no default URL is provided, you can return a placeholder URL or throw an error.
  // For example:
  // return 'https://www.example.com/default';
  // Or you can throw an error:
  throw new Error('No valid URL found and no default URL provided.');
}

function objectToQueryString(obj: any) {
  return Object.keys(obj).map(key => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
  }).join('&');
}

export function getUrlParams(expectedParams: string[] = []): string {
  const paramsDic = getUrlParamsDictionary(expectedParams);
  return objectToQueryString(paramsDic);
}

export function getUrlParamsDictionary(expectedParams: string[] = []): { [key: string]: string } {
  const parameters: { [key: string]: string } = {};
  try {
    const searchParams = new URLSearchParams(window.location.search);

    // Convert all expected parameters to lower case for case-insensitive comparison
    const lowerCaseExpectedParams = expectedParams.map(param => param.toLowerCase());

    if (lowerCaseExpectedParams.length > 0) {
      searchParams.forEach((value, key) => {
        const lowerCaseKey = key.toLowerCase();
        if (lowerCaseExpectedParams.includes(lowerCaseKey)) {
          parameters[lowerCaseKey] = value;
        }
      });
    } else {
      searchParams.forEach((value, key) => {
        parameters[key.toLowerCase()] = value;
      });
    }
    return parameters;
  } catch (error) {
    console.error("Error getting parent page URL parameters:", error);
    return parameters;
  }
}


export interface UrlParams {
  artid: number;
  SourceType: string;
  DocType: string;
  DocNo: string;
  DocLineNo: string;
}

export const getBusinessCentralURLParams = (): UrlParams => {
  const expectedParams = ['artid', 'sourcetype', 'doctype', 'docno', 'doclineno'];
  const parsedParams = getUrlParamsDictionary(expectedParams);
  return {
    artid: Number(parsedParams?.artid),
    SourceType: parsedParams?.sourcetype,
    DocType: parsedParams?.doctype,
    DocNo: parsedParams?.docno,
    DocLineNo: parsedParams?.doclineno
  } as UrlParams;
}