import { CmeLayout } from "./LNTransferDetailsSteps/CmeLayout"
import { CmeMaterialColor } from "./LNTransferDetailsSteps/CmeMaterialColor"
import { CmeProof } from "./LNTransferDetailsSteps/CmeProof"
import { CmeQuantity } from "./LNTransferDetailsSteps/CmeQuantity"
import { CmeSewDisk } from "./LNTransferDetailsSteps/CmeSewDisk"
import { CmeSize } from "./LNTransferDetailsSteps/CmeSize"
import { CmeSplit } from "./LNTransferDetailsSteps/CmeSplit"
import { CmeTails } from "./LNTransferDetailsSteps/CmeTails"
import { CmeTextFont } from "./LNTransferDetailsSteps/CmeTextFont"
import { EffectStep } from "./LNTransferDetailsSteps/Effect"
import { GraphicStep } from "./LNTransferDetailsSteps/Graphic"
import { LayoutStep } from "./LNTransferDetailsSteps/Layout"
import { MaterialColorStep } from "./LNTransferDetailsSteps/MaterialColor"
import { getPlayerProductData } from "./LNTransferDetailsSteps/PlayerProduct"
import { PlayerQuantityStep } from "./LNTransferDetailsSteps/PlayerQuantity"
import { PlayerTextFontSizeStep } from "./LNTransferDetailsSteps/PlayerTextFontSize"
import { ProductStep } from "./LNTransferDetailsSteps/Product"
import { TextFontSizeStep } from "./LNTransferDetailsSteps/TextFontSize"
import MaterialColorMap from './LNTransferDetailsSteps/CmeMaterialColorColorMap.json'
import { SewDiskStep } from "./LNTransferDetailsSteps/SewDisk"
import { QuantityStep } from "./LNTransferDetailsSteps/Quantity"
import FontMapByProduct from './LNTransferDetailsSteps/GenericFontMapByProduct.json'
import MaterialMap from './LNTransferDetailsSteps/CmeMaterialColorMaterialMap.json'

//getting precut properties by sku: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/precut-wizard-config.js#L329
//code goes like this
// async function main() {
//   const result = {}
//   const query = encodeURI(`[{"type":"DIECUT","template":["\${Letter}|\${Number}|\${Greek}|\${Material}|\${Size}|\${Font}|\${Foreground}|\${Background}|\${ItemNumber}"]}]`)
//   const resp = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//     "headers": {
//       "accept": "application/json, text/javascript, */*; q=0.01",
//       "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//       "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//     },
//     "body": "QueryJSON=" + query,
//     "method": "POST"
//   })
//   const jsonResp = await resp.json()
//   const data = jsonResp[0]
//   for(var listItem of data) {
//   var bits = listItem.split("|");

//   var item = {};
//   item.Letter = bits[0];
//   item.Number = bits[1];
//   item.Greek = bits[2];
//   item.Material = bits[3];
//   item.Size = bits[4];
//   item.Font = bits[5];
//   item.Foreground = bits[6];
//   item.Background = bits[7];
//   item.ItemNumber = bits[8];
//   result[item.ItemNumber] = item;
//   }
//   console.log(result)
// }
// main().catch(err => console.error(err))

//for other wizards like auto stitch it's similar: https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/autostitch-wizard-config.js#L367
// async function main() {
//   const result = {}
//   const query = encodeURI(`[{"type":"WIZARDS","template":["\${Letter}|\${Number}|\${Greek}|\${Material}|\${Size}|\${Font}|\${Foreground}|\${Background}|\${ItemNumber}|\${WizardID}"]}]`)
//   const resp = await fetch("https://art-api.sca.poweredbycadworx.com/stahlsca/stahls/OOE_WizardData/Query.asp", {
//     "headers": {
//       "accept": "application/json, text/javascript, */*; q=0.01",
//       "accept-language": "es,es-ES;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
//       "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
//     },
//     "body": "QueryJSON=" + query,
//     "method": "POST"
//   })
//   const jsonResp = await resp.json()
//   const data = jsonResp[0]
//   for(var listItem of data) {
//   var bits = listItem.split("|");

//   var item = {};
//   item.Letter = bits[0];
//   item.Number = bits[1];
//   item.Greek = bits[2];
//   item.Material = bits[3];
//   item.Size = bits[4];
//   item.Font = bits[5];
//   item.Foreground = bits[6];
//   item.Background = bits[7];
//   item.ItemNumber = bits[8]?.trim();
//   var wizardID = bits[9];
//   if(!!bits[8]) {
//     result[wizardID] = result[wizardID] ?? {}
//     result[wizardID][item.ItemNumber] = item;
//   }
//   }
//   console.log(result)
// }
// main().catch(err => console.error(err))


import PropertiesBySkuByWizardId from './LNTransferDetailsSteps/PropertiesBySkuByWizardId.json'
import { StyleStep } from "./LNTransferDetailsSteps/Style"

function getMaterialColorMapReversed():{[key:string]:string} {
  const reversed:{[key:string]:string} = {}
  for(let key in MaterialColorMap) {
    const value:string = MaterialColorMap[key as keyof typeof MaterialColorMap]
    //make numeric values prevail
    if(!reversed[value] || Number.isNaN(reversed[value])) reversed[value] = key
  }

  return reversed
}

let MaterialColorMapReversed:{[key:string]:string} = getMaterialColorMapReversed()

function getMaterialMapReversed():{[key:string]:string} {
  const reversed:{[key:string]:string} = {}
  for(let key in MaterialMap) {
    const value:string = MaterialMap[key as keyof typeof MaterialMap]
    reversed[value.trim()] = key
  }

  return reversed
}

let MaterialMapReversed:{[key:string]:string} = getMaterialMapReversed()

export const LNTransferDetailsGenerator: {[key:string]:{GetDetails:(wizardData:any,editParams:any)=>{name:string, detail:string}[]}} = {
  CustomMadeEasyCadCut: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1439
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeQuantity(wizardData)
      ].filter(x => !!x) as {name:string, detail:string}[]
    },
  },
  CustomMadeEasyStandard: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1516
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeSewDisk(wizardData),
        CmeQuantity(wizardData)
      ].filter(x => !!x) as {name:string, detail:string}[]
    },
  },
  CustomMadeEasyKissCut: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1465
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeSewDisk(wizardData),
        CmeQuantity(wizardData)
      ].filter(x => !!x) as {name:string, detail:string}[]
    },
  },
  CustomMadeEasySimStitch: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1491
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeQuantity(wizardData)
      ].filter(x => !!x) as {name:string, detail:string}[]
    },
  },
  CustomMadeEasySublimatedTwill: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1573
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeSewDisk(wizardData),
        CmeQuantity(wizardData)
      ].filter(x => !!x) as {name:string, detail:string}[]
    },
  },
  CustomMadeEasySublimatedSimStitch: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1542
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeQuantity(wizardData)
      ].filter(x => !!x) as {name:string, detail:string}[]
    },
  },
  PlayerPerfect: {
    GetDetails(wizardData:any,editParams:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/playerperfect-wizard-config.js#L8
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      const wizardID = "PlayerPerfect"
      const playerProductData = getPlayerProductData(wizardData, editParams,wizardID)
      return [
        ProductStep(wizardData, editParams, playerProductData),
        EffectStep(wizardData,editParams, playerProductData, wizardID),
        LayoutStep(wizardData,editParams,playerProductData, wizardID),
        PlayerTextFontSizeStep(wizardData,editParams,playerProductData, wizardID),
        MaterialColorStep(wizardData,editParams,playerProductData, wizardID),
        PlayerQuantityStep(wizardData,editParams,playerProductData,wizardID)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  TeamPerfect: {
    GetDetails(wizardData:any,editParams:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/playerperfect-wizard-config.js#L8
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      const wizardID = "TeamPerfect"
      const playerProductData = getPlayerProductData(wizardData, editParams,wizardID)
      return [
        LayoutStep(wizardData,editParams,playerProductData, wizardID),
        PlayerTextFontSizeStep(wizardData,editParams,playerProductData, wizardID),
        GraphicStep(wizardData,editParams,playerProductData),
        MaterialColorStep(wizardData,editParams,playerProductData, wizardID),
        PlayerQuantityStep(wizardData,editParams,playerProductData,wizardID)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  PreSpacedLetters: {
    GetDetails(wizardData:any,editParams:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/playerperfect-wizard-config.js#L8
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      const wizardID = "PreSpacedLetters"
      const playerProductData = getPlayerProductData(wizardData, editParams,wizardID)
      return [
        ProductStep(wizardData, editParams, playerProductData),
        EffectStep(wizardData,editParams, playerProductData, wizardID),
        LayoutStep(wizardData,editParams,playerProductData, wizardID),
        PlayerTextFontSizeStep(wizardData,editParams,playerProductData, wizardID),
        MaterialColorStep(wizardData,editParams,playerProductData, wizardID),
        PlayerQuantityStep(wizardData,editParams,playerProductData,wizardID)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  PreSpacedNumbers: {
    GetDetails(wizardData:any,editParams:any) {
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      const wizardID = "PreSpacedNumbers"
      const playerProductData = getPlayerProductData(wizardData, editParams,wizardID)
      return [
        ProductStep(wizardData, editParams, playerProductData),
        EffectStep(wizardData,editParams, playerProductData, wizardID),
        LayoutStep(wizardData,editParams,playerProductData, wizardID),
        PlayerTextFontSizeStep(wizardData,editParams,playerProductData, wizardID),
        MaterialColorStep(wizardData,editParams,playerProductData, wizardID),
        PlayerQuantityStep(wizardData,editParams,playerProductData,wizardID)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  SimStitchText: {
    GetDetails(wizardData:any,editParams:any) {
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      const wizardID = "SimStitchText"
      const playerProductData = getPlayerProductData(wizardData, editParams,wizardID)
      return [
        LayoutStep(wizardData,editParams,playerProductData, wizardID),
        PlayerTextFontSizeStep(wizardData,editParams,playerProductData, wizardID),
        MaterialColorStep(wizardData,editParams,playerProductData, wizardID),
        PlayerQuantityStep(wizardData,editParams,playerProductData,wizardID)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  SimStitchPSNumbers: {
    GetDetails(wizardData:any,editParams:any) {
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      const wizardID = "SimStitchPSNumbers"
      const playerProductData = getPlayerProductData(wizardData, editParams,wizardID)
      return [
        LayoutStep(wizardData,editParams,playerProductData, wizardID),
        PlayerTextFontSizeStep(wizardData,editParams,playerProductData, wizardID),
        MaterialColorStep(wizardData,editParams,playerProductData, wizardID),
        PlayerQuantityStep(wizardData,editParams,playerProductData,wizardID)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  VerticalText: {
    GetDetails(wizardData:any,editParams:any) {
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      const wizardID = "VerticalText"
      const playerProductData = getPlayerProductData(wizardData, editParams,wizardID)
      return [
        LayoutStep(wizardData,editParams,playerProductData, wizardID),
        PlayerTextFontSizeStep(wizardData,editParams,playerProductData, wizardID),
        MaterialColorStep(wizardData,editParams,playerProductData, wizardID),
        PlayerQuantityStep(wizardData,editParams,playerProductData,wizardID)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  ZippWeedText: {
    GetDetails(wizardData:any,editParams:any) {
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      const wizardID = "ZippWeedText"
      const playerProductData = getPlayerProductData(wizardData, editParams,wizardID)
      return [
        LayoutStep(wizardData,editParams,playerProductData, wizardID),
        PlayerTextFontSizeStep(wizardData,editParams,playerProductData, wizardID),
        MaterialColorStep(wizardData,editParams,playerProductData, wizardID),
        PlayerQuantityStep(wizardData,editParams,playerProductData,wizardID)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  "3DGreek": {
    GetDetails(wizardData:any,editParams:any) {
      const wizardID = "3DGreek"
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      const data = {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/3dgreek-wizard-config.js#L193
        //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/wizards.js#L1724
        FontSize: 4,
        FontCode: wizardData[0].hFont == 'MGreek' ? 'MG' : 'ML',
        Layer: 'foreground',
        ForegroundMaterialCode: 'PTW',
        ForegroundMaterialColor: MaterialColorMapReversed[wizardData[0].hAllColor],
        SewDiskSelected: wizardData[0].hDisk == 'yes' ? 1 : 0,
        SewDiskType: wizardData[0].hDiskType,
        StitchType: wizardData[0].hStitch,
        DiskFormat: wizardData[0].hDiskFormat,
        Qty: (editParams.szChars as string).split(';').filter(x => !!x).map(x => Number.parseInt(x)).reduce((sum, value) => sum+value),
        QtyUnit: "Piece"
      }

      if(data.Qty > 1) data.QtyUnit += "s"
      return [
        TextFontSizeStep(wizardData,data,wizardID),
        MaterialColorStep(wizardData,data,data,wizardID),
        SewDiskStep(wizardData, data, wizardID),
        QuantityStep(data)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  PreCut: {
    GetDetails(wizardData:any,editParams:any) {
      const wizardID = "PreCut"
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      let fontSize = "0"
      let fontCode = ""
      let hasBackground = false
      let hasForeground = false
      let ForegroundMaterialCode:string|undefined = undefined
      let BackgroundMaterialCode:string|undefined = undefined
      let ForegroundMaterialColor:string|undefined = undefined
      let BackgroundMaterialColor:string|undefined = undefined
      let StyleValue:string|undefined = undefined
      for(let dataItem of wizardData) {
        switch (dataItem.szLtrOrNum) {
          case "0":
            StyleValue = "Letters"
            break;
          case "1":
            StyleValue = "Numbers"
            break;
          default:
            break;
        }
        const PropertiesBySku = PropertiesBySkuByWizardId[wizardID as keyof typeof PropertiesBySkuByWizardId] as {[sku:string]:{"Letter": string, "Number": string, "Greek": string, "Material": string, "Size": string, "Font": string, "Foreground": string, "Background": string, "ItemNumber": string }}
        const properties = PropertiesBySku[dataItem.szSKU as keyof typeof PropertiesBySku] 
        fontSize = properties.Size
        fontCode = properties.Font
        hasBackground = hasBackground || properties.Background == '1'
        hasForeground = hasForeground || properties.Foreground == '1'
        if(properties.Background == '1') {
          BackgroundMaterialCode = properties.Material
          BackgroundMaterialColor = dataItem.szBGColor
        }
        if(properties.Foreground == '1') {
          ForegroundMaterialCode = properties.Material
          ForegroundMaterialColor = dataItem.szFGColor
        }
      }
      const data = {
        FontSize: Number.parseFloat(fontSize),
        FontCode: fontCode,
        Layer: hasForeground && hasBackground ? 'foreground_and_background' : hasForeground ? 'foreground' : 'background',
        StyleValue,
        ForegroundMaterialCode,
        BackgroundMaterialCode,
        ForegroundMaterialColor,
        BackgroundMaterialColor,
        Qty: (editParams.szQTYToEdit as string).split(',').filter(x => !!x).map(x => Number.parseInt(x)).reduce((sum, value) => sum+value) * (hasForeground && hasBackground ? 2 : 1),
        QtyUnit: (editParams.szUOFMToEdit as string)
      }

      if(data.Qty > 1) data.QtyUnit += "s"
      return [
        StyleStep(data),
        TextFontSizeStep(wizardData,data,wizardID),
        MaterialColorStep(wizardData,data,data,wizardID),
        QuantityStep(data)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  AutoStitch: {
    GetDetails(wizardData:any,editParams:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/autostitch-wizard-config.js#L167
      //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/wizards.js#L1946
      //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/autostitch-wizard-config.js#L194
      const wizardID = "AutoStitch"
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      let fontSize = "0"
      let fontCode = ""
      let hasBackground = false
      let hasForeground = false
      let ForegroundMaterialCode:string|undefined = undefined
      let BackgroundMaterialCode:string|undefined = undefined
      let ForegroundMaterialColor:string|undefined = undefined
      let BackgroundMaterialColor:string|undefined = undefined
      let StyleValue:string|undefined = undefined

      const PropertiesBySku = PropertiesBySkuByWizardId[wizardID as keyof typeof PropertiesBySkuByWizardId] as {[sku:string]:{"Letter": string, "Number": string, "Greek": string, "Material": string, "Size": string, "Font": string, "Foreground": string, "Background": string, "ItemNumber": string }}
      const properties = PropertiesBySku[editParams.SKU as keyof typeof PropertiesBySku]
      fontSize = properties.Size
      fontCode = properties.Font

      for(let dataItem of wizardData) {
        const materialValues = (dataItem.hAllMaterial as string).split('/').map(x => x.trim())
        ForegroundMaterialCode = MaterialMapReversed[materialValues[0]]
        BackgroundMaterialCode = materialValues[1] ? MaterialMapReversed[materialValues[1]] : undefined
        const colorValues = (dataItem.hAllColor as string).split('/').map(x => x.trim())
        ForegroundMaterialColor = MaterialColorMapReversed[colorValues[0]]
        BackgroundMaterialColor = colorValues[1] ? MaterialColorMapReversed[colorValues[1]] : undefined
        switch (dataItem.hOrderType) {
          case "letters":
            StyleValue = "Letters"
            break;
          case "numbers":
            StyleValue = "Numbers"
            break;
          default:
            break;
        }
        switch (dataItem.hLetterColorSelect) {
          case "2-Color":
            hasForeground = true
            hasBackground = true
            break;
          case "1-Color":
            hasForeground = true
            break;
          case "2-Color2nd-Color Bkg Color":
            hasBackground = true
            break;
          default:
            break;
        }
      }
      const data = {
        FontSize: Number.parseFloat(fontSize),
        FontCode: fontCode,
        Layer: hasForeground && hasBackground ? 'foreground_and_background' : hasForeground ? 'foreground' : 'background',
        StyleValue,
        ForegroundMaterialCode,
        BackgroundMaterialCode,
        ForegroundMaterialColor,
        BackgroundMaterialColor,
        SewDiskSelected: wizardData[0].hDisk == 'yes' ? 1 : 0,
        SewDiskType: wizardData[0].hDiskType,
        StitchType: wizardData[0].hStitch,
        DiskFormat: wizardData[0].hDiskFormat,
        Qty: (editParams.szChars as string).split(';').filter(x => !!x).map(x => Number.parseInt(x)).reduce((sum, value) => sum+value) * (hasForeground && hasBackground ? 2 : 1),
        QtyUnit: (wizardData[0].u_of_m as string).split(';')[0]
      }

      if(data.Qty > 1) data.QtyUnit += "s"
      return [
        StyleStep(data),
        TextFontSizeStep(wizardData,data,wizardID),
        MaterialColorStep(wizardData,data,data,wizardID),
        SewDiskStep(wizardData, data, wizardID),
        QuantityStep(data)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  CustomCut: {
    GetDetails(wizardData:any,editParams:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/customcut-wizard-config.js#L200
      const wizardID = "CustomCut"
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      let fontSize = "0"
      let fontCode = ""
      let Layer = ""
      let qtymult = 1
      let ForegroundMaterialCode:string|undefined = undefined
      let BackgroundMaterialCode:string|undefined = undefined
      let BackgroundMaterial2Code:string|undefined = undefined
      let ForegroundMaterialColor:string|undefined = undefined
      let BackgroundMaterialColor:string|undefined = undefined
      let BackgroundMaterial2Color:string|undefined = undefined
      let StyleValue:string|undefined = undefined
      fontSize = editParams.szEditSize
      const FontMap = FontMapByProduct[wizardID]
      for (const key in FontMap) {
        if(FontMap[key as keyof typeof FontMap]==editParams.szEditStyle) fontCode=key
      }
      
      for(let dataItem of wizardData) {
        const materialValues = (dataItem.hAllMaterial as string).split('/').map(x => x.trim())
        ForegroundMaterialCode = MaterialMapReversed[materialValues[0]]
        BackgroundMaterialCode = materialValues[1] ? MaterialMapReversed[materialValues[1]] : undefined
        BackgroundMaterial2Code = materialValues[2] ? MaterialMapReversed[materialValues[2]] : undefined
        const colorValues = (dataItem.hAllColor as string).split('/').map(x => x.trim())
        ForegroundMaterialColor = MaterialColorMapReversed[colorValues[0]]
        BackgroundMaterialColor = colorValues[1] ? MaterialColorMapReversed[colorValues[1]] : undefined
        BackgroundMaterial2Color = colorValues[2] ? MaterialColorMapReversed[colorValues[2]] : undefined
        switch (dataItem.hOrderType) {
          case "letters":
            StyleValue = "Letters"
            break;
          case "numbers":
            StyleValue = "Numbers"
            break;
          default:
            break;
        }
        switch (dataItem.hLetterColorSelect) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/wizards.js#L2211
          case "3-Color":
            Layer = "3layer"
            break;
          case "2-Color":
            Layer = "2layer"
            break;
          case "1-Color":
            Layer = "foreground"
            break;
          case "Regular-3-inch-Bkg":
            Layer = "background1"
            BackgroundMaterialCode = ForegroundMaterialCode
            BackgroundMaterialColor = ForegroundMaterialColor
            ForegroundMaterialCode = undefined
            ForegroundMaterialColor = undefined
            break;
          case "3-ColorBkg":
            Layer = "background2"
            BackgroundMaterial2Code = ForegroundMaterialCode
            BackgroundMaterial2Color = ForegroundMaterialColor
            ForegroundMaterialCode = undefined
            ForegroundMaterialColor = undefined
            break;
          default:
            break;
        }
      }
      const data = {
        FontSize: Number.parseFloat(fontSize),
        FontCode: fontCode,
        Layer,
        StyleValue,
        ForegroundMaterialCode,
        BackgroundMaterialCode,
        BackgroundMaterial2Code,
        ForegroundMaterialColor,
        BackgroundMaterialColor,
        BackgroundMaterial2Color,
        SewDiskSelected: wizardData[0].hDisk == 'yes' ? 1 : 0,
        SewDiskType: wizardData[0].hDiskType,
        StitchType: wizardData[0].hStitch,
        DiskFormat: wizardData[0].hDiskFormat,
        Qty: (editParams.szChars as string).split(';').filter(x => !!x).map(x => Number.parseInt(x)).reduce((sum, value) => sum+value) * qtymult,
        QtyUnit: (wizardData[0].u_of_m as string).split(';')[0]
      }

      if(data.Qty > 1) data.QtyUnit += "s"
      return [
        StyleStep(data),
        TextFontSizeStep(wizardData,data,wizardID),
        MaterialColorStep(wizardData,data,data,wizardID),//TODO: should be ccmaterialcolorstep bc 3 layers
        SewDiskStep(wizardData, data, wizardID),
        QuantityStep(data)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  Embroidered: {
    GetDetails(wizardData:any,_editParams:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/embroidered-wizard-config.js#L223
      const wizardID = "Embroidered"
      let fontSize = "2"
      let fontCode = ""
      let hasBackground = false
      let hasForeground = true
      let ForegroundMaterialCode:string|undefined = "Embroidered"
      let BackgroundMaterialCode:string|undefined = undefined
      let ForegroundMaterialColor:string|undefined = undefined
      let BackgroundMaterialColor:string|undefined = undefined
      let StyleValue:string|undefined = undefined
      const skus = (wizardData.sku as string).split(";")
      const genericSku = skus[0].substring(0, skus[0].length - 1)
      const requestedChar = skus[0].substring(skus[0].length - 1)
      StyleValue = !Number.isNaN(Number.parseInt(requestedChar)) ? "Numbers" : "Letters"
      switch(genericSku.substring(0,2)) {
        case "RE":
          ForegroundMaterialColor="003"
          break;
        case "WH":
          ForegroundMaterialColor="001"
          break;
        case "GO":
          ForegroundMaterialColor="005"
          break;
        case "BL":
          ForegroundMaterialColor="002"
          break;
      }
      hasBackground = genericSku.endsWith("TC") //two colors
      if(hasBackground) {
        BackgroundMaterialCode = "Embroidered"
        BackgroundMaterialColor = "001" //always white
      }
      fontCode = genericSku[2] == "B" ? "VA" : "ES" //B or S
      const data = {
        FontSize: Number.parseFloat(fontSize),
        FontCode: fontCode,
        Layer: hasForeground && hasBackground ? 'foreground_and_background' : hasForeground ? 'foreground' : 'background',
        StyleValue,
        ForegroundMaterialCode,
        BackgroundMaterialCode,
        ForegroundMaterialColor,
        BackgroundMaterialColor,
        Qty: (wizardData.qty as string).split(';').map(x => parseInt(x)).reduce((sum, v) => sum+v),
        QtyUnit: (wizardData.u_of_m as string).split(';')[0].trim()
      }

      if(data.Qty > 1) data.QtyUnit += "s"
      return [
        StyleStep(data),
        TextFontSizeStep(wizardData,data,wizardID),
        MaterialColorStep(wizardData,data,data,wizardID),
        QuantityStep(data)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  SimStitch: {
    GetDetails(wizardData:any,editParams:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/simstitch-wizard-config.js#L145
      const wizardID = "SimStitch"
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      let fontSize = "0"
      let fontCode = ""
      let hasBackground = false
      let hasForeground = false
      let ForegroundMaterialCode:string|undefined = undefined
      let BackgroundMaterialCode:string|undefined = undefined
      let ForegroundMaterialColor:string|undefined = undefined
      let BackgroundMaterialColor:string|undefined = undefined
      let StyleValue:string|undefined = undefined

      //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/wizards.js#L1909
      const [wizardDataItem] = wizardData
      const [fsize, fname, _qunit, lorn, _lornd, materialandforeground, backgroundandqty] = (wizardDataItem.data as string).split(',')
      const [material,foreground] = materialandforeground.split('|')
      const [bacground,_qtys] = backgroundandqty.split('|')
      if(foreground != '////') hasForeground = true
      if(bacground != '////') hasBackground = true
      if(hasForeground) {
        ForegroundMaterialCode = material
        const [_fgsku,_fgColorName,fgColorCode,_fgColorName2] = foreground.split('/')
        ForegroundMaterialColor = fgColorCode
      }
      if(hasBackground) {
        BackgroundMaterialCode = material
        const [_bgsku,_bgColorName,bgColorCode,_bgColorName2] = bacground.split('/')
        BackgroundMaterialColor = bgColorCode
      }
      StyleValue = lorn == "L" ? "Letters" : "Numbers"
      const FontMap = FontMapByProduct[wizardID]
      for (const key in FontMap) {
        if(FontMap[key as keyof typeof FontMap]==fname) fontCode=key
      }
      fontSize = fsize
      const data = {
        FontSize: Number.parseFloat(fontSize),
        FontCode: fontCode,
        Layer: hasForeground && hasBackground ? 'foreground_and_background' : hasForeground ? 'foreground' : 'background',
        StyleValue,
        ForegroundMaterialCode,
        BackgroundMaterialCode,
        ForegroundMaterialColor,
        BackgroundMaterialColor,
        Qty: (editParams.szChars as string).split(',').filter(x => x !="").map(x => parseInt(x)).reduce((sum, v) => sum+v) * (hasForeground && hasBackground ? 2 : 1),
        QtyUnit: (editParams.UOFM as string)
      }

      if(data.Qty > 1) data.QtyUnit += "s"
      return [
        StyleStep(data),
        TextFontSizeStep(wizardData,data,wizardID),
        MaterialColorStep(wizardData,data,data,wizardID),
        QuantityStep(data)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  PreCutStar: {
    GetDetails(wizardData:any,editParams:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/simstitch-wizard-config.js#L145
      const wizardID = "PreCutStar"
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      let fontSize = "0"
      let fontCode = "ST"
      let hasBackground = false
      let hasForeground = false
      let ForegroundMaterialCode:string|undefined = undefined
      let BackgroundMaterialCode:string|undefined = undefined
      let ForegroundMaterialColor:string|undefined = undefined
      let BackgroundMaterialColor:string|undefined = undefined
      let StyleValue:string|undefined = undefined
      for(let dataItem of wizardData) {
        switch (dataItem.szLtrOrNum) {
          case "0":
            StyleValue = "Letters"
            break;
          case "1":
            StyleValue = "Numbers"
            break;
          default:
            break;
        }
        const PropertiesBySku = PropertiesBySkuByWizardId["PreCut" as keyof typeof PropertiesBySkuByWizardId] as {[sku:string]:{"Letter": string, "Number": string, "Greek": string, "Material": string, "Size": string, "Font": string, "Foreground": string, "Background": string, "ItemNumber": string }}
        const properties = PropertiesBySku[dataItem.szSKU as keyof typeof PropertiesBySku] 
        fontSize = properties.Size
        fontCode = properties.Font
        hasBackground = hasBackground || properties.Background == '1'
        hasForeground = hasForeground || properties.Foreground == '1'
        if(properties.Background == '1') {
          BackgroundMaterialCode = properties.Material
          BackgroundMaterialColor = dataItem.szBGColor
        }
        if(properties.Foreground == '1') {
          ForegroundMaterialCode = properties.Material
          ForegroundMaterialColor = dataItem.szFGColor
        }
      }

      const data = {
        FontSize: Number.parseFloat(fontSize),
        FontCode: fontCode,
        Layer: hasForeground && hasBackground ? 'foreground_and_background' : hasForeground ? 'foreground' : 'background',
        StyleValue,
        ForegroundMaterialCode,
        BackgroundMaterialCode,
        ForegroundMaterialColor,
        BackgroundMaterialColor,
        Qty: Number.parseInt(editParams.szQTYToEdit),
        QtyUnit: (editParams.szUOFMToEdit as string)
      }

      if(data.Qty > 1) data.QtyUnit += "s"
      return [
        TextFontSizeStep(wizardData,data,"PreCut"),
        MaterialColorStep(wizardData,data,data,wizardID),
        QuantityStep(data)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
  HelmetNumber: {
    GetDetails(wizardData:any,editParams:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/simstitch-wizard-config.js#L145
      const wizardID = "HelmetNumber"
      if(!editParams) return [{name:"Loading", detail: "Fetching data to display details..."}]
      let fontSize = "1.75"
      let fontCode = ""
      let ForegroundMaterialCode:string|undefined = undefined
      let BackgroundMaterialCode:string|undefined = undefined
      let ForegroundMaterialColor:string|undefined = undefined
      let BackgroundMaterialColor:string|undefined = undefined
      let StyleValue:string|undefined = undefined
      let StyleType:string|undefined = undefined
      let Layer:string|undefined = undefined

      //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/helmetnumber-wizard-config.js#L302
      var fontStyle = (editParams["fontStyle"] || '').toString().trim();
      const color = (editParams["color"] || '').toString().trim();
      var colors = color ? color.split("/") : [];
      if (colors.length > 1) {
        StyleType = "foreground_and_background";
        Layer = "foreground_and_background";
        ForegroundMaterialCode = "Helmet Decals";
        ForegroundMaterialColor = (colors[0] || '').toString().trim();
        BackgroundMaterialCode = "Helmet Decals";
        BackgroundMaterialColor = (colors[1] || '').toString().trim();
      } else {
        StyleType = "foreground";
        Layer = "foreground";
        ForegroundMaterialCode = "Helmet Decals";
        ForegroundMaterialColor = (colors[0] || '').toString().trim();
      }

      const FontMap = FontMapByProduct[wizardID]
      for (const key in FontMap) {
        if(FontMap[key as keyof typeof FontMap]==fontStyle) fontCode=key
      }

      const data = {
        FontSize: Number.parseFloat(fontSize),
        FontCode: fontCode,
        Layer,
        StyleValue,
        StyleType,
        ForegroundMaterialCode,
        BackgroundMaterialCode,
        ForegroundMaterialColor,
        BackgroundMaterialColor,
        Qty: editParams.quantity,
        QtyUnit: "Sheet"
      }

      if(data.Qty > 1) data.QtyUnit += "s"
      return [
        LayoutStep(wizardData,editParams,data, wizardID),
        TextFontSizeStep(wizardData,data,wizardID),
        MaterialColorStep(wizardData,data,data,wizardID),
        QuantityStep(data)
      ].filter(x => !!x) as {name:string, detail:string}[]
    }
  },
}
