export const productMapping: Record<string, string> = {
  "3DEmbrPatchesHA": "3D Embroidered Patches Heat Applied",
  "3DEmbrPatchesPS": "3D Embroidered Patches Pressure Sensitive/Sticker",
  "3DGreek": "3D Mega Greek",
  "AutoStitch": "Auto-Stitch",
  "BacklitGraphics": "Backlit Graphics",
  "Banners": "Banners",
  "BeveledEmblemsHA": "Beveled Emblem Heat Applied",
  "BeveledEmblemsPS": "Beveled Emblem Pressure Sensitive/Sticker",
  "CanvasPrints": "Canvas Prints",
  "ChenillePatchesHA": "Chenille Patches Heat Applied",
  "ChenillePatchesPS": "Chenille Patches Pressure Sensitive",
  "CustomCadCut": "CAD-CUT® Designs",
  "CustomCutLetters": "Standard Letters",
  "CustomCutNumbers": "Standard Numbers",
  "CustomCutPreAlignedPTWLt": "Perma-Twill® Letters",
  "CustomCutPreAlignedPTWNo": "Perma-Twill® Numbers",
  "CustomCutSignVinyl": "Custom Cut Sign Vinyl",
  "CustomKissCut": "Kiss Cut® Designs",
  "CustomMadeEasyCadCut": "AWAW CAD-CUT",
  "CustomMadeEasyKissCut": "AWAW Kiss Cut",
  "CustomMadeEasySimStitch": "AWAW SimStitch",
  "CustomMadeEasyStandard": "AWAW Standard",
  "CustomMadeEasySublimatedSimStitch": "AWAW Sublimated SimStitch",
  "CustomMadeEasySublimatedTwill": "AWAW Sublimated Twill",
  "CustomPreAlignedPTW": "Pre-Aligned Perma-Twill® Designs",
  "CustomSimStitch": "SimStitch® Designs",
  "DTFTransfer": "UltraColor® MAX",
  "DecoXEmblemsHA": "Textured Emblem Heat Applied",
  "DecoXEmblemsPS": "Textured Emblem Pressure Sensitive/Sticker",
  "DigitalTransfersLogos": "Digital Transfers/Logos",
  "DomeXEmblemsHA": "Domed Emblem Heat Applied",
  "DomeXEmblemsPS": "Domed Emblem Pressure Sensitive/Sticker",
  "DryEraseGraphics": "Dry Erase Graphics",
  "EasyView": "Easy View",
  "EmbrPatchesHA": "Embroidered Patches Heat Applied",
  "EmbrPatchesHL": "Embroidered Patches Hook & Loop",
  "EmbrPatchesPS": "Embroidered Patches Pressure Sensitive/Sticker",
  "Embroidered": "Embroidered Letters & Numbers",
  "FauxLeatherPatchesHA": "Faux Leather Patches Heat Applied",
  "FauxLeatherPatchesPS": "Faux Leather Patches Pressure Sensitive/Sticker",
  "FauxSuedePatchesHA": "Faux Suede Patches Heat Applied",
  "FauxSuedePatchesPS": "Faux Suede Patches Pressure Sensitive",
  "FlatDomeXEmblemsHA": "Flat Emblem Heat Applied",
  "FlatDomeXEmblemsHL": "Flat Emblem Hook & Loop",
  "FlatDomeXEmblemsPS": "Flat Emblem Pressure Sensitive/Sticker",
  "FlexStyleHolographicHA": "Holographic Emblem Heat Applied",
  "FlexStyleHolographicPS": "Holographic Emblem Pressure Sensitive/Sticker",
  "FloorGraphics": "Floor Graphics",
  "HelmetNumber": "Helmet Number Stickers",
  "HelmetStickers": "Helmet Stickers",
  "LeatherPatchesHA": "Leather Patches Heat Applied",
  "LeatherPatchesPS": "Leather Patches Pressure Sensitive/Sticker",
  "PVPlusPatchHA": "PVC Patch Heat Applied",
  "PVPlusPatchPS": "PVC Patch Pressure Sensitive/Sticker",
  "PlayerPerfect": "Player Perfect",
  "Posters": "Posters",
  "PreCut": "Pre-Cut",
  "PreCutStar": "Pre-Cut Stars",
  "PreSpacedLetters": "Pre-Spaced Text",
  "PreSpacedNumbers": "Pre-Spaced Numbers",
  "PrintStitchPatchesHA": "Print Stitch Patches Heat Applied",
  "PrintStitchPatchesHL": "Print Stitch Patches Hook & Loop",
  "PrintStitchPatchesPS": "Print Stitch Patches Pressure Sensitive",
  "ScreenPrintedTransfer": "Screen Printed Transfer Numbers",
  "SimStitch": "SimStitch",
  "SimStitchLetters": "SimStitch® Letters",
  "SimStitchNumbers": "SimStitch® Numbers",
  "SimStitchPSNumbers": "Pre-Spaced SimStitch Numbers",
  "SimStitchText": "Pre-Spaced SimStitch Text",
  "StaticClings": "Static Clings",
  "StickersDecals": "Stickers/Decals",
  "TeamPerfect": "Team Perfect",
  "VerticalText": "Vertical Text",
  "WallGraphics": "Wall Graphics",
  "WovenPatchHA": "Woven Patch Heat Applied",
  "WovenPatchPS": "Woven Patch Pressure Sensitive/Sticker",
  "WovenTag": "Woven Tags NO Border/NO Backing",
  "ZippWeedText": "ZIPP Weed Text",
  "FlexbroideryHA": "Flexbroidery Heat Applied",
  "FlexbroideryPS": "Flexbroidery Pressure Sensitive"
};