export const ProductStep:(wizardData:any,editParams:any,playerProductData:any)=>{name:string, detail:string} = (_wizardData:any,_editParams:any,playerProductData:any) => {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/steps/product.js#L71
  //https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/playerperfect-wizard-config.js#L334
  //all occurrences are identical for all products
  const ProductOptions = [
        {
            "image": "/images/effects/numbers-no-effect.png",
            "text": "No Effect",
            "value": "none",
            "description": ""
        },
        {
            "image": "/images/effects/numbers-effects.png",
            "text": "Effects",
            "value": "effects",
            "description": ""
        }
  ]
  var selectedProduct = null;

  for (var i = 0; i < ProductOptions.length; i++) {
      if (ProductOptions[i].value == playerProductData.ProductType)
          selectedProduct = ProductOptions[i]
  }

  return { "name": "Product", "detail": selectedProduct?.text ?? 'No Effect' };
}